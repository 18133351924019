import { GROUP_MEMBERS, STARTUPS, TEAM_MEMBER } from '@/apis/endpoints'
import {
  ActivateOrDeactivateMember,
  GroupMembers,
  InviteMember,
  ModifyFounder,
  TeamMembers,
} from '@/apis/teamApis'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useGetTeamMembers = (startupId: number) => {
  return useQuery(
    [TEAM_MEMBER, startupId],
    () => TeamMembers({ startup: startupId }),
    { enabled: !!startupId }
  )
}

export const useInviteMember = () => {
  return useMutation(InviteMember)
}

export const useModifyFounder = () => {
  const queryClient = useQueryClient()
  return useMutation(ModifyFounder, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(TEAM_MEMBER)
      queryClient.invalidateQueries(STARTUPS)
      queryClient.invalidateQueries([STARTUPS, variables.startup])
    },
  })
}

export const useActivateOrDeactivateMember = () => {
  const queryClient = useQueryClient()
  return useMutation(ActivateOrDeactivateMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(TEAM_MEMBER)
      queryClient.invalidateQueries(STARTUPS)
    },
  })
}
export const useGetGroupMembers = (id: number) => {
  return useQuery([GROUP_MEMBERS, id], () => GroupMembers({ id }))
}
