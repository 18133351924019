import classNames from 'classnames'
import React, { forwardRef, Ref, useImperativeHandle } from 'react'

import { FaX } from 'react-icons/fa6'
import Typography from './Typography'
import AnimateUnmounting from './AnimateUnmounting'

export interface RefType {
  /**
   * @property
   * @type {function}
   * @returns {void}
   */
  handleToggle: () => void
}
interface Props {
  children: JSX.Element
  ariaLabel?: string
  title?: string
  subtitle?: string
  enableWarning?: boolean
  isForm?: boolean

  /** A function that runs after modal closes, like resetting states */
  cleanUp?(): void
}

/**
 * Custom Modal component where modal refs are passed,
 * functions are now bubbled down instead of up using useImperativeHandle
 * example of such functions here is
 * handleToggle function
 *
 */
const RightModal = forwardRef(function CustomModal(
  props: Props,
  ref: Ref<RefType>
) {
  const {
    children,

    ariaLabel = 'modal',
    enableWarning = true,
    title,
    subtitle,
    cleanUp = () => {},
    isForm = false,
  } = props
  const [open, setOpen] = React.useState<boolean>(false)
  const backDrop = React.useRef<HTMLDivElement | null>(null)
  const handleClose = () => {
    cleanUp()
    setOpen(false)
  }
  const handleToggle = (): void => {
    setOpen(!open)
  }
  useImperativeHandle(ref, () => {
    return {
      handleToggle,
    }
  })

  React.useMemo(() => {
    /**
     * Invoke Function onClick outside of element
     */
    const closeModal = (e: MouseEvent) => {
      if (
        !enableWarning &&
        backDrop.current &&
        open &&
        backDrop?.current === e.target &&
        !isForm
      ) {
        setOpen(false)
      }
    }
    // Bind
    document.addEventListener('mousedown', closeModal)
    return () => {
      // dispose
      document.removeEventListener('mousedown', closeModal)
    }
  }, [open])

  React.useEffect(() => {
    const abortHandler = (e: any) => {
      e.preventDefault()
      return (e.returnValue =
        'You are about to close the page, are you sure you are done')
    }
    if (!enableWarning) return
    if (open) {
      window.addEventListener('beforeunload', abortHandler)
    } else window.removeEventListener('beforeunload', abortHandler)

    return () => {
      window.removeEventListener('beforeunload', abortHandler)
    }
  }, [open, enableWarning])

  return (
    <AnimateUnmounting
      open={open}
      startAnimationClass='animate-rightSlideIn'
      endAnimationClass='animate-rightSlideOut'
      containerClass='fixed w-[100%] h-[100%] top-0 right-0'
    >
      <div
        className='w-[100%] h-[100%]  flex justify-center items-center before:absolute before:content-[" "]  before:top-0 before:left-0 before:bg-gray-900 before:opacity-80 before:h-[100%] before:w-[100%]'
        aria-label={ariaLabel}
        style={{ zIndex: 999999! }}
        ref={backDrop}
      >
        <div
          className={classNames(
            'relative  rounded-md bg-[white] p-4 overflow-y-auto w-[95%] h-[70%]  sm:w-[94%] sm:h-[95%] lg:right-0 lg:top-0 lg:absolute lg:w-[640px] lg:h-[100%] lg:rounded-none lg:rounded-l-lg',
            { '!p-0 ': title || subtitle }
          )}
          style={{ zIndex: 999999! }}
          onClick={(e) => e.stopPropagation()}
        >
          {(title || subtitle) && (
            <div className='flex flex-col gap-3 w-full mb-5 '>
              <div className='flex flex-col gap-2 w-full p-4'>
                <Typography heading='xs'>{title}</Typography>
                <Typography
                  label='sm'
                  className='!font-normal !text-black-white-shades-4'
                >
                  {subtitle}
                </Typography>
                {/* <div className='w-full h-[2px] bg-black-white-shades-1' /> */}
              </div>
              <hr className='w-full  bg-black-white-shades-1' />
            </div>
          )}
          {isForm && (
            <FaX
              onClick={handleClose}
              className={classNames(
                'absolute top-[3%] right-[5%] cursor-pointer',
                { '!top-[35px]': title || subtitle }
              )}
            />
          )}
          <div className='p-4  w-full'>{children}</div>
        </div>
      </div>
    </AnimateUnmounting>
  )
})

export default RightModal
