import clsx from 'clsx'
import { ReactNode } from 'react'
import LazySpinner from './spinners/LazySpinner'
import { ScaleLoader } from 'react-spinners'
import iconComponents from '@/assets/icons/iconComponents'

type Props = {
  loadingComp?: ReactNode
  isLoading?: boolean
  children: ReactNode
  emptyComponent?: ReactNode
  isEmpty?: boolean
  className?: string
}

const ApiState = (props: Props) => {
  const {
    className,
    isLoading,
    loadingComp,
    isEmpty,
    emptyComponent,
    children,
  } = props
  return (
    <div className={clsx('w-full flex-1 h-full', className)}>
      {isLoading
        ? loadingComp || (
            <LazySpinner show>
              <div className='w-full min-h-[50vh] h-full  bg-white rounded-lg flex justify-center items-center'>
                <ScaleLoader color='var(--mainprimary)' />
              </div>
            </LazySpinner>
          )
        : isEmpty
        ? emptyComponent || <iconComponents.util.EmptyMessageIcon />
        : children}
    </div>
  )
}

export default ApiState
