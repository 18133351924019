import useGetCssValue from '@/hooks/useGetCssValue'
import useMediaQuery from '@/hooks/useMediaQuery'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { clamp } from '../tabs/helpers'
import { toastEmitter } from './emitter'
import { exportStyleFromClassName, generateId } from './helpers'
import useMountPortal from './hooks/useMountPortal'
import Toast from './Toast'
import { ToastContainerProps, ToastItem, ToastPosition } from './toast.types'

const ToastClassesPositions: Record<ToastPosition, string> = {
  'top-left': 'left-0 top-0 justify-start',
  'top-center': 'top-0 left-[calc(50%_-_238px)]',
  'top-right': 'top-0 right-0 justify-end',
  'center-center': 'top-[calc(50%_-_26px)] left-[calc(50%_-_238px)]',
  'center-left': 'top-[calc(50%_-_26px)] left-0 justify-start',
  'center-right': 'top-[calc(50%_-_26px)] right-0 justify-end',
  'bottom-left': 'bottom-0 left-0 justify-start',
  'bottom-center': 'bottom-0 left-[calc(50%_-_238px)]',
  'bottom-right': 'bottom-0 right-0 justify-end',
  top: 'left-0 top-0 justify-start',
  left: 'left-0 top-0 justify-start',
  center: 'top-[calc(50%_-_26px)] left-[calc(50%_-_238px)]',
  bottom: 'bottom-0 left-0 justify-start',
  right: 'top-0 right-0 justify-end',
}
const BASE_CLASSES = 'flex flex-col  h-full'

const ToastContainer = (props: ToastContainerProps) => {
  const {
    limit = 10,
    autoClose = true,
    timeout = 5000,
    position = 'top-center',
  } = props
  const { isLoading, portalId } = useMountPortal()
  const isMobile = useMediaQuery('(max-width: 640px)')
  const [toasts, setToasts] = useState<(ToastItem & { id: string })[]>([])
  const [dialogRef, dialogWidth] = useGetCssValue('width', '476px')
  useEffect(() => {
    const handleIncrement = (payload: ToastItem) => {
      setToasts((prev) => [
        ...prev,
        {
          ...payload,
          id: generateId(),
        },
      ])
    }
    const handleDecrement = (payload: string) => {
      setToasts((prev) => prev.filter((el) => el.id !== payload))
    }
    toastEmitter.on('increment', handleIncrement)
    toastEmitter.on('decrement', handleDecrement)
    return () => {
      toastEmitter.off('increment', handleIncrement)
      toastEmitter.off('decrement', handleDecrement)
    }
  }, [])

  useEffect(() => {
    toastEmitter.emit('globalProps', {
      limit,
      autoClose,
      timeout,
    })
  }, [limit, autoClose, timeout])
  const bodyWidth = window
    .getComputedStyle(document.body)
    .getPropertyValue('width')

  return !isLoading ? (
    ReactDOM.createPortal(
      <div
        style={{
          ...exportStyleFromClassName(ToastClassesPositions[position]),
          left: `${clamp(
            parseInt(bodyWidth) / 2 - parseInt(dialogWidth) / 2,
            0,
            parseInt(bodyWidth) / 2
          )}px`,
          zIndex: 99999999!,
        }}
        className={clsx(
          'fixed flex w-full z-[10000]',
          ToastClassesPositions[position]
        )}
      >
        <div
          style={{
            maxWidth: isMobile ? `calc(100vw - 16px)` : `476px`,
            opacity: toasts.length ? '1' : '0',
            transform: toasts.length ? 'translateY(10px)' : 'translateY(-50vh)',
            transition:
              'transform 0.8s ease-in-out, opacity 1600ms ease-in-out',
          }}
          className={clsx(BASE_CLASSES)}
        >
          {toasts.map((toast) => (
            <Toast
              ref={dialogRef}
              GlobalProps={{
                limit,
                autoClose,
                timeout,
              }}
              key={toast.id}
              {...toast}
            />
          ))}
        </div>
      </div>,
      document.getElementById(portalId)!
    )
  ) : (
    <></>
  )
}

export default ToastContainer
