import Progress from '@/common/Input/Progress'
import Title from '@/common/Title'
import Typography from '@/common/Typography'
import useStepper from '@/hooks/useStepper'
import PersonalInformation from './components/PersonalInformation'
import { useState } from 'react'
import StartupInformation from './components/StartupInformation'
import useQueryParams from '@/hooks/useQueryParams'
import { useNavigate } from 'react-router'
import { Pages } from '@/routes/route.constant'
import { AuthUser } from '@/apis/authApis'

const SignUp = () => {
  const totalSteps = 2
  const { page = '1' } = useQueryParams()
  const navigate = useNavigate()
  const { step: currentPage, goToNextStep: gotoStartupInfo } = useStepper(
    Number(page)
  )
  const [storedState, setStoredState] = useState<Partial<AuthUser>>({})
  const PageComponents = {
    1: (
      <PersonalInformation
        onNext={(resp) => {
          gotoStartupInfo()
          navigate('.?page=2')
          setStoredState(resp)
        }}
      />
    ),
    2: <StartupInformation data={storedState} />,
  }
  return (
    <article className='w-full flex-1 flex flex-col gap-16 md:px-[92px] md:pt-6 pb-10 overflow-auto '>
      <header className='w-full flex items-center justify-center'>
        <Title>Sign Up</Title>
        <hgroup className='flex flex-col justify-center items-center'>
          <Typography as='h1' heading='xl' className='text-center'>
            Sign Up
          </Typography>
          {/* <Typography
            as='p'
            paragraph='lg'
            className='text-center text-sifuse-shades-500'
          >
            Description text
          </Typography> */}
        </hgroup>
      </header>
      <section className='flex flex-col items-center gap-2 self-stretch w-full'>
        <Typography className='flex w-full justify-end items-center gap-1 text-sifuse-shades-700'>
          <strong className='text-sifuse-shades-700 leading-none text-[24px] font-bold'>
            {' '}
            {currentPage}
          </strong>{' '}
          <span className='text-base leading-6'>/</span>{' '}
          <span className='text-base leading-6'>{totalSteps}</span>
        </Typography>
        <Progress
          max={totalSteps}
          value={currentPage}
          showPercent={false}
          thumbColor='var(--mainprimary)'
          trackColor='var(--shades400)'
        />
      </section>
      {PageComponents[currentPage as 1]}
      <footer className='w-full flex self-stretch items-end justify-center gap-1 flex-1'>
        <Typography
          button='md'
          color='var(--shades500)'
          className='text-sifuse-shades-500'
        >
          Already have an account ?
        </Typography>
        <button
          type='button'
          onClick={() => navigate(`/auth/${Pages.SignIn}`)}
          className='hover:underline font-semibold text-base leading-6 text-sifuse-main-secondary'
        >
          Sign in
        </button>
      </footer>
    </article>
  )
}

export default SignUp
