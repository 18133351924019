import classNames from 'classnames'
import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import styles from './Button.module.css'
import LazySpinner from '../spinners/LazySpinner'
import CircularProgress from '../spinners/CircularProgress'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  startIcon?: ReactNode
  endIcon?: ReactNode
  className?: string
  children?: ReactNode
  color?:
    | 'primary'
    | 'grey'
    | 'lightGrey'
    | 'white'
    | 'green'
    | 'lightgreen'
    | 'lightblue'
    | 'red'
    | 'lightred'
    | 'yellow'
    | 'plain'
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  loading?: boolean
  delay?: number
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const StyleMaps = {
  color: {
    primary: styles.primary,
    yellow: styles.yellow,
    grey: styles.grey,
    lightGrey: styles.lightGrey,
    white: styles.white,
    green: styles.green,
    lightgreen: styles.lightgreen,
    lightblue: styles.lightblue,
    red: styles.red,
    lightred: styles.lightred,
    plain: styles.plain,
  },
  size: {
    xl: 'h-[54px] text-lg leading-[26px]',
    lg: 'h-12 text-base leading-[24px]',
    md: 'h-10 text-sm leading-[20px]',
    sm: 'h-9 text-sm leading-[20px]',
    xs: 'h-9 text-xs leading-[20px]',
  },
  iconOnly: {
    true: {
      xl: 'p-3.5',
      lg: 'p-3 ',
      md: 'p-2.5',
      sm: 'p-2 gap-2',
      xs: 'p-1 gap-2',
    },
    false: {
      xl: 'py-3.5 px-6 gap-2',
      lg: 'py-3 px-5 gap-2',
      md: 'py-2.5 px-5 gap-2',
      sm: 'py-2 px-3 gap-2',
      xs: 'py-2 px-3 gap-2',
    },
  },
}
function Button(props: Props) {
  const {
    children,
    className,
    onClick,
    startIcon,
    endIcon,
    color = 'primary',
    size,
    type = 'button',
    loading,
    delay = 400,
    ...rest
  } = props
  const iconOnly = !(startIcon || endIcon)
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        color && StyleMaps.color[color],
        size && StyleMaps.size[size],
        StyleMaps.iconOnly[String(iconOnly) as 'true'][size || 'md'],

        className,
        'min-w-fit flex capitalize !cursor-pointer items-center justify-between active:scale-95 gap-2 w-fit-content rounded-lg disabled:opacity-30 disabled:cursor-not-allowed'
      )}
      {...rest}
    >
      {loading ? (
        <LazySpinner show={loading} delay={delay}>
          <CircularProgress
            size='18px'
            color={
              ['red', 'green'].includes(color)
                ? 'var(--shades50)'
                : 'var(--shades900)'
            }
          />
        </LazySpinner>
      ) : (
        <>
          {startIcon && <span>{startIcon}</span>}
          {children && <span className='text-center flex-1'>{children}</span>}
          {endIcon && <span>{endIcon}</span>}
        </>
      )}
    </button>
  )
}

export default Button
