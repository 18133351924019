import classNames from 'classnames'
import Typography from './Typography'

interface Prop {
  leftLabel?: string | JSX.Element
  rightLabel?: string | JSX.Element
  /**  Value in percent */
  percentageProgress?: number
  containerClass?: string
}
const ProgressBar = ({
  leftLabel,
  rightLabel,
  percentageProgress = 0,
  containerClass,
}: Prop) => {
  return (
    <div
      className={classNames('flex flex-col gap-1 w-[200px]', containerClass)}
    >
      <div className='flex items-center justify-between w-full'>
        {leftLabel ? (
          typeof leftLabel === 'string' ? (
            <Typography>{leftLabel}</Typography>
          ) : (
            leftLabel
          )
        ) : null}
        {rightLabel ? (
          typeof rightLabel === 'string' ? (
            <Typography>{rightLabel}</Typography>
          ) : (
            rightLabel
          )
        ) : null}
      </div>
      <div
        className={classNames('relative w-full rounded-lg bg-gray-500 h-[4px]')}
      >
        <span
          className='absolute rounded-lg  top-0 left-0 bg-primary h-[4px]'
          style={{
            width: `${
              percentageProgress > 100
                ? 100
                : percentageProgress < 0
                ? 0
                : percentageProgress
            }%`,
          }}
        />
      </div>
    </div>
  )
}

export default ProgressBar
