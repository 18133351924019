import { useAuthContext } from '@/contexts/AuthContextProvider'
import { Pages } from '@/routes/route.constant'
import { useNavigate } from 'react-router'

export const useMessageRecipient = () => {
  const navigate = useNavigate()
  return (recipient: number, type: 'user' | 'group' = 'user') => {
    navigate(`/app/${Pages.Message}?recipient=${recipient}&type=${type}`)
  }
}

export const useDetermineSender = () => {
  const { getAuthState } = useAuthContext()
  const userData = getAuthState()

  return (userId: number) => {
    return Number(userId) === userData?.id
  }
}
