import { ReactComponent as PdfIcon } from '@/assets/icons/media/pdf.svg'
import { ReactComponent as WordIcon } from '@/assets/icons/media/docx.svg'
import { ReactComponent as ExcelIcon } from '@/assets/icons/media/excel.svg'
import { ReactComponent as RedBinIcon } from '@/assets/icons/utils/redBin.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { formatFileSize } from '@/helpers/numberFormatter'
import ProgressBar from '@/common/ProgressBar'
import { useCreateDocument } from '@/store/dataRoomStore'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { CREATE_DOCUMENT, GET_FILES, GET_FOLDERS } from '@/apis/endpoints'
import { ToastNotify } from '@/common/toastManager'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router'
import { RefObject, useRef, useState } from 'react'
import { Canceler } from 'axios'

interface Prop {
  file: File
  handleRemoveFile: (file: File) => void

  isInsideFolder?: boolean
}
function UploadingFile({
  file,
  handleRemoveFile,

  isInsideFolder = false,
}: Prop) {
  const queryClient = useQueryClient()
  const { state } = useLocation()
  const [progress, setprogress] = useState({
    percentage: 0,
    loaded: 0,
    total: file.size,
  })
  const [isUploading, setisUploading] = useState(false)
  const cancelerRef = useRef<any>(null)

  let fileIcon: JSX.Element = <></>
  switch (file.type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      fileIcon = <WordIcon />
      break
    case 'application/msword':
      fileIcon = <WordIcon />
      break
    case 'application/pdf':
      fileIcon = <PdfIcon />
      break

    default:
      fileIcon = <ExcelIcon />
      break
  }

  //create doc
  const {
    isLoading: isCreateDocLoading,
    mutateAsync: CreateDoc,
    isError: isCreateDocError,
  } = useCreateDocument()

  const handleCreateDoc = async () => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('is_folder', 'false')
    formData.append('startup', state.startupId)
    formData.append('name', file.name)
    if (isInsideFolder) formData.append('parent', state?.folderId)

    const { response, error } = await withAsync(() => {
      setisUploading(true)
      return CreateDoc({
        data: formData,
        config: {
          onUploadProgress: (progressEvent: any) => {
            console.log(progressEvent)

            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setprogress({
              percentage: progress,
              loaded: progressEvent.loaded,
              total: progressEvent.total,
            })
          },
          abort: (canceler: Canceler) => {
            cancelerRef.current = { cancel: canceler }
          },
        },
      })
    })

    if (error) {
      if ((error as any)?.message === 'User Cancelled Request') return
      return errorHandler(error)
    }
    if (response) {
      queryClient.invalidateQueries([GET_FILES])
      queryClient.invalidateQueries([CREATE_DOCUMENT])
      handleRemoveFile(file)
      ToastNotify('success', {
        message: 'Success',
      })
    }
  }
  const handleCancelUpload = () => {
    cancelerRef?.current?.cancel?.('User Cancelled Request')
    setisUploading(false)
    setprogress({ percentage: 0, loaded: 0, total: file.size })
  }

  const [newFileName, extention] = file.name.split('.')

  return (
    <main>
      <div className='flex w-full flex-col gap-2 justify-between align-center bg-white rounded-md border h-auto p-2 sm:flex-row sm:h-[69px] sm:gap-0'>
        {/* items on the left */}
        <div className='flex gap-2 items-start'>
          {fileIcon}
          <div className='flex flex-col gap-1'>
            <span className='flex items-center'>
              <Typography label='md'>{newFileName}</Typography>
              <Typography label='md' className='!text-black-white-shades-3'>
                .{extention}
              </Typography>
            </span>
            <ProgressBar percentageProgress={progress.percentage} />
            <Typography label='sm' className='!text-black-white-shades-4'>
              {formatFileSize(progress.loaded)}/{formatFileSize(progress.total)}
            </Typography>
          </div>
        </div>

        {/* items on the right */}
        <div className='flex gap-2 items-center'>
          {isUploading ? (
            <Button
              size='xs'
              color='white'
              className=''
              onClick={handleCancelUpload}
            >
              Cancel Upload
            </Button>
          ) : (
            <div className='flex gap-2 items-center'>
              <Button
                size='xs'
                color='white'
                className='px-4'
                onClick={() => handleCreateDoc()}
                loading={isCreateDocLoading}
              >
                Upload
              </Button>
              <Button
                size='xs'
                color='lightred'
                className='!px-2'
                startIcon={<RedBinIcon />}
                onClick={() => handleRemoveFile(file)}
              ></Button>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export default UploadingFile
