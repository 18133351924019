import Title from '@/common/Title'
import Typography from '@/common/Typography'
import PersonalInformation from './components/PersonalInformation'
import useQueryParams from '@/hooks/useQueryParams'
import { useNavigate } from 'react-router'
import { Pages } from '@/routes/route.constant'
import { useAuthActionsContext } from '@/contexts/AuthContextProvider'
import { flushSync } from 'react-dom'

const JoinTeam = () => {
  const navigate = useNavigate()
  const {
    email,
    token,
    startup,
    first_name,
    last_name,
    phone_number,
    status = 'old',
  } = useQueryParams<{
    email: string
    token: string
    startup: string
    first_name?: string
    last_name?: string
    phone_number?: string
    status?: 'old'
  }>()
  const { setAuthState } = useAuthActionsContext()

  return (
    <article className='w-full flex-1 flex flex-col gap-16 md:px-[92px] md:pt-6 pb-10 overflow-auto '>
      <header className='w-full flex items-center justify-center'>
        <Title>Join Business</Title>
        <hgroup className='flex flex-col justify-center items-center'>
          <Typography as='h1' heading='xl' className='text-center'>
            Join Business
          </Typography>
          <Typography
            as='p'
            paragraph='lg'
            className='text-center text-sifuse-shades-500'
          >
            By signing up, you are joining{' '}
            <strong className='text-sifuse-shades-800 capitalize'>
              {startup}
            </strong>{' '}
            Business
          </Typography>
        </hgroup>
      </header>

      <PersonalInformation
        onNext={(resp) => {
          flushSync(() => setAuthState(resp))
          navigate('/success', { state: { from: Pages.SignUp } })
        }}
        isStartUp
        data={{ email, token, first_name, last_name, status, phone_number }}
      />
    </article>
  )
}

export default JoinTeam
