/* eslint-disable react-hooks/exhaustive-deps */
import { RouterProvider } from 'react-router-dom'

import { ToastContainer } from '@/common/toastManager/'

import RouteConfig from './routes'

function App() {
  return (
    <div>
      <RouterProvider router={RouteConfig} />
      <ToastContainer />
    </div>
  )
}

export default App
