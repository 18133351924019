import { useState, useEffect } from 'react'

import countriesList from 'countrycitystatejson'
import iconComponents from '@/assets/icons/iconComponents'
import { capitalize } from '@/helpers/strings'
import { ReactComponent as BlackFlagIcon } from '@/assets/icons/flags/blackflag.svg'
export const Continents = [
  {
    name: 'Africa',
    shortName: 'Af',
    flag: <BlackFlagIcon className='h-4 w-[22px] max-w-[23px]' />,
    emoji: 'emoji',
  },
  {
    name: 'South America',
    shortName: 'SA',
    flag: <BlackFlagIcon className='h-4 w-[22px] max-w-[23px]' />,
    emoji: 'emoji',
  },
  {
    name: 'North America',
    shortName: 'NA',
    flag: <BlackFlagIcon className='h-4 w-[22px] max-w-[23px]' />,
    emoji: 'emoji',
  },
  {
    name: 'Europe',
    shortName: 'EU',
    flag: <BlackFlagIcon className='h-4 w-[22px] max-w-[23px]' />,
    emoji: 'emoji',
  },
  {
    name: 'Asia',
    shortName: 'AS',
    flag: <BlackFlagIcon className='h-4 w-[22px] max-w-[23px]' />,
    emoji: 'emoji',
  },
  {
    name: 'Antarctica',
    shortName: 'AT',
    flag: <BlackFlagIcon className='h-4 w-[22px] max-w-[23px]' />,
    emoji: 'emoji',
  },
]
export const useCountries = () => {
  const [countriesStateList, setCountriesStateList] = useState([])

  useEffect(() => {
    const handleGetCountries = () => {
      const countries = countriesList?.getCountries() || []
      return countries.map((country) => {
        const Component =
          iconComponents.flags[`${capitalize(country.shortName)}FlagIcon`] ||
          function () {
            return <span>{country.emoji}</span>
          }
        return {
          ...country,
          flag: <Component className='h-4 w-[22px] max-w-[23px]' />,
        }
      })
    }

    setCountriesStateList(handleGetCountries())
  }, [])

  return [countriesStateList]
}

export const useCountryStates = (country) => {
  const [statesList, setStatesList] = useState([])
  const handleGetStates = (country) => {
    if (!country) return []
    const states = countriesList?.getStatesByShort(country) || []
    return states
  }

  useEffect(() => {
    setStatesList(handleGetStates(country))
  }, [country])

  return [statesList]
}
