import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { ReactComponent as Followers } from '@/assets/network/programming-arrows.svg'
import { ReactComponent as People } from '@/assets/network/people.svg'

import { imagesUrl } from '@/assets/images/imageUrls'
import { InvestorsStructure } from '../SingleUser'

import { INVESTOR, USER } from '@/constants/querryKeys'
import {
  useChangeConnectionStatus,
  useCreateConnection,
  useDeleteConnection,
  useJoinUserGroup,
} from '@/store/networkStore'
import { useMessageRecipient } from '@/pages/message/hooks'

import { useParams } from 'react-router'

function SingleInvestorDesc({
  id,
  email,
  first_name,
  last_name,
  phone_number,
  user_type,
  avatar,
  about,
  last_login,
  date_joined,
  email_verified,
  num_connections,
  connection,
}: InvestorsStructure) {
  const messageUser = useMessageRecipient()

  const { id: pgId } = useParams()
  const startupId = Number(pgId)
  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [USER, id],
    })

  // create connection
  const { mutate: createConnection, isLoading: createConnectionLoading } =
    useCreateConnection({
      invalidateKey: [USER, id],
    })
  //accept Connection
  const { mutateAsync: acceptConnection, isLoading: acceptConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [USER, id],
    })

  //join user froup
  const { mutateAsync: joinUserGroup, isLoading: joinUserGroupLoading } =
    useJoinUserGroup()

  let btn: React.ReactElement = <></>
  switch (connection?.status) {
    case 'Pending':
      btn = (
        <>
          {connection.is_sender ? (
            <div className='flex gap-1 items-center'>
              {' '}
              <Button
                size='xs'
                color='green'
                className='px-3'
                onClick={() =>
                  acceptConnection({ id: connection.id, status: 'Accepted' })
                }
                loading={acceptConnectionLoading}
              >
                Accept
              </Button>{' '}
              <Button
                size='xs'
                color='lightred'
                className='px-3'
                onClick={() => deleteConnection({ id: connection.id })}
                loading={deleteConnectionLoading}
              >
                Decline
              </Button>{' '}
            </div>
          ) : (
            <Button
              size='sm'
              color='lightred'
              className='px-4'
              onClick={() => deleteConnection({ id: connection?.id })}
              loading={deleteConnectionLoading}
            >
              Delete
            </Button>
          )}
        </>
      )
      break
    case 'Declined':
      btn = (
        <Button
          color='primary'
          className='rounded  border'
          onClick={() => createConnection({ id })}
          size='sm'
          loading={createConnectionLoading}
        >
          Connect
        </Button>
      )
      break
    case 'Accepted':
      btn = (
        <Button
          color='lightgreen'
          className='rounded  border'
          onClick={() => deleteConnection({ id: connection.id })}
          size='sm'
          loading={deleteConnectionLoading}
        >
          Unconnect
        </Button>
      )
      break

    default:
      btn = (
        <Button
          color='primary'
          className='rounded  border'
          onClick={() => createConnection({ id })}
          size='sm'
          loading={createConnectionLoading}
        >
          Connect
        </Button>
      )
      break
  }

  return (
    <>
      <div className='bg-[white] p-4  rounded-lg flex flex-col gap-2 items-start w-full sm:flex-row sm:justify-between sm:items-center'>
        <div className='flex gap-2 flex-col justify-start sm:flex-row'>
          <img
            src={avatar || imagesUrl.blanckProfileImg}
            alt='start_up_logo'
            className='h-[129px] w-[129px] object-center object-cover rounded-full'
          />
          <div className='flex flex-col gap-1 px-2 sm:px-3'>
            <Typography heading='2xs'>{`${first_name || ''} ${
              last_name || ''
            }`}</Typography>
            <small className='font-light'>{about}</small>

            <div className='flex gap-2 mt-4 sm:gap-4'>
              {user_type === 'Startup' || user_type === 'Investor' ? (
                btn
              ) : (
                <Button
                  color='primary'
                  className='rounded  border'
                  onClick={() => joinUserGroup({ groupId: 0 })}
                  size='sm'
                  loading={joinUserGroupLoading}
                  startIcon={
                    <svg
                      width='13'
                      height='12'
                      viewBox='0 0 13 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.89961 5.21748C5.53613 5.21748 6.14658 4.96462 6.59667 4.51454C7.04675 4.06445 7.29961 3.454 7.29961 2.81748C7.29961 2.18096 7.04675 1.57051 6.59667 1.12042C6.14658 0.670337 5.53613 0.41748 4.89961 0.41748C4.26309 0.41748 3.65264 0.670337 3.20255 1.12042C2.75247 1.57051 2.49961 2.18096 2.49961 2.81748C2.49961 3.454 2.75247 4.06445 3.20255 4.51454C3.65264 4.96462 4.26309 5.21748 4.89961 5.21748ZM4.89961 6.81748C6.17265 6.81748 7.39355 7.32319 8.29372 8.22337C9.1939 9.12354 9.69961 10.3444 9.69961 11.6175H0.0996094C0.0996094 10.3444 0.605322 9.12354 1.5055 8.22337C2.40567 7.32319 3.62657 6.81748 4.89961 6.81748ZM11.2996 3.61748C11.2996 3.40531 11.2153 3.20182 11.0653 3.0518C10.9153 2.90177 10.7118 2.81748 10.4996 2.81748C10.2874 2.81748 10.084 2.90177 9.93392 3.0518C9.78389 3.20182 9.69961 3.40531 9.69961 3.61748V4.41748H8.89961C8.68744 4.41748 8.48395 4.50177 8.33392 4.6518C8.18389 4.80182 8.09961 5.00531 8.09961 5.21748C8.09961 5.42965 8.18389 5.63314 8.33392 5.78317C8.48395 5.9332 8.68744 6.01748 8.89961 6.01748H9.69961V6.81748C9.69961 7.02965 9.78389 7.23314 9.93392 7.38317C10.084 7.5332 10.2874 7.61748 10.4996 7.61748C10.7118 7.61748 10.9153 7.5332 11.0653 7.38317C11.2153 7.23314 11.2996 7.02965 11.2996 6.81748V6.01748H12.0996C12.3118 6.01748 12.5153 5.9332 12.6653 5.78317C12.8153 5.63314 12.8996 5.42965 12.8996 5.21748C12.8996 5.00531 12.8153 4.80182 12.6653 4.6518C12.5153 4.50177 12.3118 4.41748 12.0996 4.41748H11.2996V3.61748Z'
                        fill='#272343'
                      />
                    </svg>
                  }
                >
                  Request to Join
                </Button>
              )}
              <Button
                className='rounded px-4 border'
                color='white'
                startIcon={<FaEnvelope />}
                size='sm'
                onClick={() => messageUser(id)}
              >
                Message
              </Button>
            </div>
          </div>
        </div>

        <div className='flex flex-row gap-2 sm:flex-col'>
          <span className='bg-[#f3f3f8] flex gap-2 items-center p-2 rounded-md'>
            <Followers />
            <span className='flex gap-1 font-normal'>
              <strong>{num_connections}</strong>Connected
            </span>
          </span>
        </div>
      </div>
    </>
  )
}

export default SingleInvestorDesc
