import Typography from '@/common/Typography'

import { useParams } from 'react-router'

import { useGetStartup } from '@/store/startupStore'
import ApiState from '@/common/ApiState'

import { FC } from 'react'

const assementMappings = {
  business_model: {
    1: 'B2B',
    2: 'B2C',
    3: 'B2B2C',
    4: 'B2G',
    5: 'Others',
  },
  gender_lens: {
    1: 'Female Led',
    2: 'Male Led',
    3: 'No gender preference',
  },
}

const iconMap = {
  gender_lens: (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.1668 6.50065C13.1668 3.65398 10.8468 1.33398 8.00016 1.33398C5.1535 1.33398 2.8335 3.65398 2.8335 6.50065C2.8335 6.77398 3.06016 7.00065 3.3335 7.00065C3.60683 7.00065 3.8335 6.77398 3.8335 6.50065C3.8335 4.20065 5.70016 2.33398 8.00016 2.33398C10.3002 2.33398 12.1668 4.20065 12.1668 6.50065C12.1668 8.80065 10.3002 10.6673 8.00016 10.6673C7.72683 10.6673 7.50016 10.894 7.50016 11.1673V15.1673C7.50016 15.4407 7.72683 15.6673 8.00016 15.6673C8.2735 15.6673 8.50016 15.4407 8.50016 15.1673V11.6407C11.1135 11.3873 13.1668 9.18065 13.1668 6.50065Z'
        fill='#000014'
      />
      <path
        d='M10 13.666H6C5.72667 13.666 5.5 13.4393 5.5 13.166C5.5 12.8927 5.72667 12.666 6 12.666H10C10.2733 12.666 10.5 12.8927 10.5 13.166C10.5 13.4393 10.2733 13.666 10 13.666Z'
        fill='#000014'
      />
    </svg>
  ),
  business_model: (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.9468 5.80008C12.5735 7.42674 12.5735 10.0667 10.9468 11.6934C9.32016 13.3201 6.68016 13.3201 5.0535 11.6934C3.42683 10.0667 3.42683 7.42674 5.0535 5.80008C6.68016 4.17341 9.32016 4.17341 10.9468 5.80008Z'
        fill='#000014'
      />
      <path
        d='M5.50018 15.4272C5.44018 15.4272 5.37352 15.4139 5.31352 15.3939C3.81352 14.7939 2.60018 13.7339 1.78685 12.3339C1.00018 10.9672 0.68685 9.42056 0.893517 7.84723C0.92685 7.57389 1.18685 7.38056 1.45352 7.41389C1.72685 7.44723 1.92018 7.70056 1.88685 7.97389C1.71352 9.32723 1.98018 10.6606 2.65352 11.8339C3.34685 13.0339 4.39352 13.9472 5.68018 14.4606C5.93352 14.5672 6.06018 14.8539 5.96018 15.1139C5.88685 15.3072 5.69352 15.4272 5.50018 15.4272Z'
        fill='#292D32'
      />
      <path
        d='M3.90023 3.98755C3.75357 3.98755 3.6069 3.92089 3.5069 3.79422C3.33357 3.58089 3.37357 3.26755 3.59357 3.09422C4.86023 2.10089 6.3869 1.57422 8.00023 1.57422C9.57357 1.57422 11.0736 2.08089 12.3336 3.04089C12.5536 3.20755 12.5936 3.52089 12.4269 3.74089C12.2602 3.96089 11.9469 4.00089 11.7269 3.83422C10.6402 3.00755 9.35357 2.57422 8.00023 2.57422C6.61357 2.57422 5.30023 3.02755 4.2069 3.88089C4.11357 3.95422 4.0069 3.98755 3.90023 3.98755Z'
        fill='#292D32'
      />
      <path
        d='M10.4998 15.4264C10.2998 15.4264 10.1131 15.3064 10.0331 15.1131C9.93314 14.8598 10.0531 14.5664 10.3131 14.4598C11.5998 13.9398 12.6465 13.0331 13.3398 11.8331C14.0198 10.6598 14.2865 9.32642 14.1065 7.97975C14.0731 7.70642 14.2665 7.45309 14.5398 7.41975C14.8065 7.38642 15.0665 7.57975 15.0998 7.85309C15.2998 9.41975 14.9931 10.9731 14.2065 12.3398C13.3998 13.7398 12.1798 14.7931 10.6798 15.3998C10.6265 15.4131 10.5665 15.4264 10.4998 15.4264Z'
        fill='#292D32'
      />
    </svg>
  ),
  industry: (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6333 12.0339C10.5133 12.0339 9.47999 11.5073 8.80666 10.5806C8.64666 10.3606 8.69333 10.0473 8.91333 9.8806C9.13333 9.71393 9.44666 9.76727 9.61333 9.98727C10.1 10.6473 10.8333 11.0273 11.6333 11.0273C13.0267 11.0273 14.1667 9.89393 14.1667 8.49393C14.1667 7.09393 13.0333 5.9606 11.6333 5.9606C10.6867 5.9606 9.79333 6.46727 9.30666 7.27393L7.53999 10.2139C6.87333 11.3273 5.65333 12.0139 4.35999 12.0139C2.41333 12.0139 0.82666 10.4273 0.82666 8.4806C0.82666 6.53393 2.41333 4.94727 4.35999 4.94727C5.47999 4.94727 6.51333 5.47393 7.18666 6.4006C7.34666 6.6206 7.29999 6.93393 7.07999 7.1006C6.85333 7.26727 6.54666 7.21393 6.37999 6.99393C5.89999 6.34727 5.16666 5.96727 4.36666 5.96727C2.97333 5.96727 1.83333 7.1006 1.83333 8.5006C1.83333 9.9006 2.96666 11.0339 4.36666 11.0339C5.31333 11.0339 6.20666 10.5273 6.69333 9.7206L8.45999 6.7806C9.12666 5.66727 10.3467 4.9806 11.64 4.9806C13.5867 4.9806 15.1733 6.56727 15.1733 8.51393C15.1733 10.4606 13.58 12.0339 11.6333 12.0339Z'
        fill='#000014'
      />
      <path
        d='M4.33333 9.83268C5.06971 9.83268 5.66667 9.23573 5.66667 8.49935C5.66667 7.76297 5.06971 7.16602 4.33333 7.16602C3.59695 7.16602 3 7.76297 3 8.49935C3 9.23573 3.59695 9.83268 4.33333 9.83268Z'
        fill='#292D32'
      />
      <path
        d='M11.6668 9.83268C12.4032 9.83268 13.0002 9.23573 13.0002 8.49935C13.0002 7.76297 12.4032 7.16602 11.6668 7.16602C10.9304 7.16602 10.3335 7.76297 10.3335 8.49935C10.3335 9.23573 10.9304 9.83268 11.6668 9.83268Z'
        fill='#292D32'
      />
    </svg>
  ),
  country: (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0131 8.72065L11.1998 7.90732C11.0064 7.74065 10.8931 7.49398 10.8864 7.22065C10.8731 6.92065 10.9931 6.62065 11.2131 6.40065L12.0131 5.60065C12.7064 4.90732 12.9664 4.24065 12.7464 3.71398C12.5331 3.19398 11.8731 2.90732 10.8998 2.90732H3.93311V2.33398C3.93311 2.06065 3.70644 1.83398 3.43311 1.83398C3.15977 1.83398 2.93311 2.06065 2.93311 2.33398V14.6673C2.93311 14.9407 3.15977 15.1673 3.43311 15.1673C3.70644 15.1673 3.93311 14.9407 3.93311 14.6673V11.414H10.8998C11.8598 11.414 12.5064 11.1207 12.7264 10.594C12.9464 10.0673 12.6931 9.40732 12.0131 8.72065Z'
        fill='#000014'
      />
    </svg>
  ),
  investment_stage: (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3335 2.83398H4.66683C2.66683 2.83398 1.3335 3.83398 1.3335 6.16732V10.834C1.3335 13.1673 2.66683 14.1673 4.66683 14.1673H11.3335C13.3335 14.1673 14.6668 13.1673 14.6668 10.834V6.16732C14.6668 3.83398 13.3335 2.83398 11.3335 2.83398ZM2.50016 6.16732C2.50016 4.97398 3.4735 4.00065 4.66683 4.00065H5.66683C5.94016 4.00065 6.16683 4.22732 6.16683 4.50065C6.16683 4.77398 5.94016 5.00065 5.66683 5.00065H4.66683C4.02683 5.00065 3.50016 5.52732 3.50016 6.16732V7.16732C3.50016 7.44065 3.2735 7.66732 3.00016 7.66732C2.72683 7.66732 2.50016 7.44065 2.50016 7.16732V6.16732ZM5.66683 13.0007H4.66683C3.4735 13.0007 2.50016 12.0273 2.50016 10.834V9.83398C2.50016 9.56065 2.72683 9.33398 3.00016 9.33398C3.2735 9.33398 3.50016 9.56065 3.50016 9.83398V10.834C3.50016 11.474 4.02683 12.0007 4.66683 12.0007H5.66683C5.94016 12.0007 6.16683 12.2273 6.16683 12.5007C6.16683 12.774 5.94016 13.0007 5.66683 13.0007ZM8.00016 10.5007C6.8935 10.5007 6.00016 9.60732 6.00016 8.50065C6.00016 7.39398 6.8935 6.50065 8.00016 6.50065C9.10683 6.50065 10.0002 7.39398 10.0002 8.50065C10.0002 9.60732 9.10683 10.5007 8.00016 10.5007ZM13.5002 10.834C13.5002 12.0273 12.5268 13.0007 11.3335 13.0007H10.3335C10.0602 13.0007 9.8335 12.774 9.8335 12.5007C9.8335 12.2273 10.0602 12.0007 10.3335 12.0007H11.3335C11.9735 12.0007 12.5002 11.474 12.5002 10.834V9.83398C12.5002 9.56065 12.7268 9.33398 13.0002 9.33398C13.2735 9.33398 13.5002 9.56065 13.5002 9.83398V10.834ZM13.5002 7.16732C13.5002 7.44065 13.2735 7.66732 13.0002 7.66732C12.7268 7.66732 12.5002 7.44065 12.5002 7.16732V6.16732C12.5002 5.52732 11.9735 5.00065 11.3335 5.00065H10.3335C10.0602 5.00065 9.8335 4.77398 9.8335 4.50065C9.8335 4.22732 10.0602 4.00065 10.3335 4.00065H11.3335C12.5268 4.00065 13.5002 4.97398 13.5002 6.16732V7.16732Z'
        fill='#000014'
      />
    </svg>
  ),
}
const Assessments = () => {
  const { id: pgId } = useParams()
  const startupId = Number(pgId)
  const { data: startupInfo, isLoading } = useGetStartup(startupId || 0)

  const formatAssessmentData = () => {
    const finalValue = [] as Record<string, any>[]
    if (!startupInfo?.assessment) return finalValue
    Object.entries(startupInfo.assessment).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        if (!val?.length) return
        const isArrayObj = val.some((_val) => typeof _val === 'object')
        if (isArrayObj) {
          const value = val.map(
            (obj) => assementMappings[key as keyof {}][obj.id]
          )
          finalValue.push({ [key]: value })
          return
        }
        finalValue.push({ [key]: val })
      }
    })

    return finalValue
  }
  const assessments = formatAssessmentData()

  return (
    <section className='w-full flex flex-col items-start gap-6 p-6 rounded-lg border border-sifuse-shades-200 bg-sifuse-shades-50'>
      <header className='flex items-center w-full justify-between flex-wrap gap-4'>
        <Typography as='h3' heading='xs' className='flex-1 whitespace-nowrap'>
          Assessment Details
        </Typography>
      </header>
      <main className='w-full'>
        <ApiState
          isLoading={isLoading}
          isEmpty={!startupInfo?.assessment}
          emptyComponent={<EmptyComponent />}
          className='flex flex-col gap-4 w-full min-h-[300px] justify-between'
        >
          <ul className='flex flex-col gap-4 w-full'>
            {assessments.map((assessment, idx) => {
              return (
                <li key={idx}>
                  <StartAssessmentCard data={assessment as any} />
                </li>
              )
            })}
          </ul>
          <section></section>
        </ApiState>
      </main>
    </section>
  )
}

const StartAssessmentCard: FC<{ data: Record<string, string[]> }> = (props) => {
  const { data: assessment } = props
  const title = Object.keys(assessment)[0]
  const values = Object.values(assessment)[0]

  return (
    <span className='flex flex-col gap-2 w-full md:flex-row md:justify-between md:items-center'>
      <span className='flex items-center gap-1'>
        {iconMap[title as keyof {}]}
        <Typography as='h6' label='sm' className='capitalize text-center'>
          {title?.replaceAll('_', ' ')}
        </Typography>
      </span>
      <span className='flex gap-1 max-w-[80%]'>
        {values.map((val, i, arr) => (
          <Typography key={val} label='sm'>
            {val} {i !== arr.length - 1 ? ',' : null}
          </Typography>
        ))}
      </span>
    </span>
  )
}

const EmptyComponent = () => {
  return (
    <section className='flex flex-col justify-center items-center content-center gap-4 flex-1 w-full min-h-[294px]'>
      <hgroup className='flex flex-col justify-center items-center gap-0.5 flex-1 px-[30px]'>
        <Typography
          as='h4'
          color='var(--shades800)'
          className='text-center text-lg font-medium leading-5'
        >
          No assessment details{' '}
        </Typography>
        <Typography
          as='p'
          paragraph='md'
          color='var(--shades700)'
          className='text-center'
        >
          Add your assessment details so investors can match you more easily.
        </Typography>
      </hgroup>
    </section>
  )
}

export default Assessments
