export const useSanitizeQueryParams = (queryString: string): string => {
  const [url, params] = queryString.split('?')
  const queryParams = params.split('&')
  const validParams: Record<string, string> = {}
  queryParams.forEach((query) => {
    const [key, value] = query.split('=')
    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      value !== 'undefined' &&
      value !== 'null'
    )
      validParams[key] = value
  })

  const cleanString = Object.entries(validParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  return `${url}?${cleanString}`
}
