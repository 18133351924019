import api from './api'
import { ServerRes } from './api.types'
import { COUNTRIES, INDUSTRIES, PRODUCT_TYPES } from './endpoints'

export const getIndustries = async () => {
  return (await api.get<ServerRes<string[]>>(INDUSTRIES)).data.data
}

export const getCountries = async () => {
  return (await api.get<ServerRes<string[]>>(COUNTRIES)).data.data
}

export const getProductTypes = async () => {
  return (await api.get<ServerRes<string[]>>(PRODUCT_TYPES)).data.data
}
