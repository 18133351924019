import React from 'react'
import Button from '@/common/button/Button'
import { ReactComponent as Connect } from '@/assets/network/programming-arrows.svg'
import { ReactComponent as Follow } from '@/assets/network/link.svg'
import { ReactComponent as Followers } from '@/assets/network/people.svg'
import {
  FaArrowAltCircleRight,
  FaCheck,
  FaEnvelope,
  FaExternalLinkAlt,
  FaLink,
  FaPlus,
  FaRedo,
} from 'react-icons/fa'
import { StartUpStructure } from '../Startups'
import { imagesUrl } from '@/assets/images/imageUrls'

const tagColor = ['bg-blue-300', 'bg-orange-300', 'bg-green-300']
function SingleStartupDesc({
  id,
  country,
  name,
  members,
  logo,
  industry,
  product_type,
  is_registered,
  about_us,
  website,
  mission,
  vision,
  bio_video,
  created,
  updated,
}: Partial<StartUpStructure>) {
  return (
    <>
      <div className='bg-[white] p-4 border rounded-lg flex flex-col gap-2 items-start w-full sm:flex-row sm:justify-between sm:items-center'>
        <div className='flex gap-2 flex-col justify-start sm:flex-row'>
          <img
            src={logo || imagesUrl.blanckCompImg}
            alt='start_up_logo'
            className='h-[129px] w-[129px] object-center object-cover rounded-[8px]'
          />
          <div className='flex flex-col gap-1 px-2 sm:px-3'>
            <div className='flex flex-col items:start justify-center'>
              <h3>{name}</h3>
              <div className='flex gap-2'>
                <small
                  className={`bg-blue-300 font-bold rounded p-1 uppercase text-[0.6rem]`}
                >
                  {industry}
                </small>
              </div>
            </div>
            <div className='flex flex-col gap-3 mt-4'>
              <div className='flex flex-col gap-2'>
                <small className='font-light'>Company website</small>

                <a
                  href={website}
                  target='_blanck'
                  className='flex gap-3 text-blue-500'
                >
                  {website}
                  <FaExternalLinkAlt />
                </a>
              </div>
              <div className='flex gap-2 mt-0 sm:gap-4'>
                {/* <Button
                  className='rounded px-4 bg-primary'
                  size='sm'
                  startIcon={<Connect />}
                >
                  Connect
                </Button> */}
                {/* {isFollowing ? (
                  <Button
                    className=' rounded px-4 border '
                    size='sm'
                    color='white'
                    startIcon={<Follow />}
                  >
                    Following
                  </Button>
                ) : (
                  <Button
                    className=' rounded px-4 border'
                    size='sm'
                    color='white'
                    startIcon={<Follow />}
                  >
                    Follow
                  </Button>
                )} */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className='flex flex-row gap-2 sm:flex-col'>
          <Button
            className='rounded px-4 cursor-text'
            startIcon={<Followers />}
            color='lightGrey'
          >
            {' '}
            <span className='flex gap-1'>
              <strong>{followers}</strong>Followers
            </span>
          </Button>

          <Button
            className='rounded px-4 cursor-text bg-[red]'
            color='lightGrey'
            startIcon={<Connect />}
          >
            <span className='flex gap-1'>
              <strong>{connections}</strong>Connected
            </span>
          </Button>
        </div> */}
      </div>
    </>
  )
}

export default SingleStartupDesc
