import TextInput from '@/common/TextInput'
import Typography from '@/common/Typography'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'
import { ReactComponent as CheckedIcon } from '@/assets/icons/utils/whitechecked.svg'
import React, { useMemo, useRef } from 'react'
import Button from '@/common/button/Button'
import Folder from '../components/FolderComponent'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import DropdownButton from '@/common/DropdownButton'

import { ReactComponent as FolderIcon } from '@/assets/icons/utils/blackFolder.svg'
import File from '../components/File'
import LoadingState from '@/pages/theNetwork/components/LoadingState'
import FileEmptyComponent from '../components/FoldersAndFilesEmptyDisplay'
import { RefType } from '@/common/Modal'
import RightModal from '@/common/RightModal'
import { folderColors } from '../utils/consts'
import FileUploadBtn from '@/common/FileUploadBtn'
import UploadingFile from '../components/UploadingFile'
import {
  useCreateDocument,
  useGetFiles,
  useGetFolders,
  useSearchDocuments,
} from '@/store/dataRoomStore'
import { GetFilesQueryDTO, GetFoldersResDTO } from '@/apis/dataRoomApis'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { useLocation } from 'react-router'
import { ToastNotify } from '@/common/toastManager'
import { useQueryClient } from 'react-query'
import { GET_FILES, GET_FOLDERS } from '@/apis/endpoints'
import clsx from 'clsx'
import { FaX } from 'react-icons/fa6'
import { ReturnedData } from './Folder'
import { ReactComponent as EmptyDisplay } from '@/assets/network/Component 3.svg'

type colorName =
  | 'purple'
  | 'blue'
  | 'orange'
  | 'green'
  | 'primary'
  | 'secondary'

type ColorProp = {
  colorName: colorName
  selectedColor: string
  handleSetColor: (colorName: colorName) => void
}

type Sort_Order = 'asc' | 'desc'

function Files() {
  const { state } = useLocation()
  const queryClient = useQueryClient()

  const modalRef = React.useRef<RefType>(null)
  const firstLoadedFolderData = useRef<ReturnedData>({})
  const firstLoadedFileData = useRef<ReturnedData>({})
  // const isFirstLoad = useRef<boolean>(false)

  const [copiedFile, setcopiedFile] = React.useState<number | null>(null)
  const [search, setsearch] = React.useState<string>('')
  const [dropdown, setdropdown] = React.useState<Sort_Order>('asc')

  const fileParentRef = useRef<HTMLDivElement | null>(null)
  const [filesToUpload, setfilesToUpload] = React.useState<File[]>([])
  const [folderState, setfolderState] = React.useState<{
    name: string
    color: colorName | ''
  }>({ name: '', color: 'purple' })
  const [searchEmptyState, setsearchEmptyState] = React.useState({
    isFolderEmpty: false,
    isFileEmpty: false,
  })

  //because search url is different from get file url, this function sets GET_FILES
  //query data to the return val of the search
  const handleSearchSuccess = (res: any) => {
    const results = res.pages.map((pg: any) => pg.results).flat()

    const isFolderEmpty =
      results.filter((result: any) => result.is_folder === true).length < 1
    const isFileEmpty =
      results.filter((result: any) => result.is_folder === false).length < 1

    setsearchEmptyState({
      isFolderEmpty,
      isFileEmpty,
    })
    //for folders
    queryClient.setQueryData([GET_FOLDERS, state.startupId], () => {
      return {
        ...res,
      }
    })
    //for files
    queryClient.setQueryData(
      [
        GET_FILES,
        { sort_order: dropdown, folder: undefined, startup: state.startupId },
      ],
      (oldQueryData) => {
        return {
          ...res,
        }
      }
    )
  }

  //this clears the search state and returns the state to its first value after load
  // since search is restricted to work only when there is search value
  const handleclearSearch = () => {
    setsearch('')
    setsearchEmptyState({
      isFolderEmpty: false,
      isFileEmpty: false,
    })
    //for folders
    queryClient.setQueryData(
      [GET_FOLDERS, state.startupId],
      (oldQueryData: any) => {
        return {
          ...firstLoadedFolderData.current,
        }
      }
    )
    //for files
    queryClient.setQueryData(
      [
        GET_FILES,
        { sort_order: dropdown, folder: undefined, startup: state.startupId },
      ],
      (oldQueryData) => {
        return {
          ...firstLoadedFileData.current,
        }
      }
    )
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //if no search, return the state to its initial value
    if (!e.target.value) return handleclearSearch()
    setsearch(e.target.value)
  }

  // const scrollToTop = () => {
  //   fileParentRef?.current?.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //   })
  // }

  //scroll to top of the file container after a sort is made
  // const onGetFilesSuccess = () => {
  //   // console.log(isFirstLoad)
  //   if (isFirstLoad) {
  //     scrollToTop()
  //   }
  // }

  //get folders
  const {
    isLoading: isFolderLoading,
    data: folders,
    isError: isFoldersError,
    fetchNextPage: foldersFetchNextPg,
    hasNextPage: isFolderHasNextPg,
    isFetchingNextPage: isFetchingFolderNextPg,
    isSuccess: getFolderSuccess,
  } = useGetFolders({ startup: state.startupId })

  //get files
  const {
    isLoading: isFilesLoading,
    data: files,
    isError: isFilesError,
    fetchNextPage: filesFetchNextPg,
    hasNextPage: isFilesHasNextPg,
    isFetchingNextPage: isFetchingFilesNextPg,
    isSuccess: getFileSuccess,
  } = useGetFiles({
    sort_order: dropdown,
    startup: state.startupId,
    parent: false,
  })

  //create doc
  const {
    isLoading: isCreateDocLoading,
    mutateAsync: CreateDoc,
    isError: isCreateDocError,
  } = useCreateDocument()

  //search documents
  const { isLoading: isSearchLoading } = useSearchDocuments({
    query: search,
    startup: state.startupId,
    cb: handleSearchSuccess,
  })

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  const handleClickDropdownItem = (item: Sort_Order) => {
    setdropdown(item)
  }
  const handleSetColor = (colorName: colorName) => {
    setfolderState((prev) => ({ ...prev, color: colorName }))
  }
  const handleFileSelect = (file: File) => {
    setfilesToUpload((prev) => [...prev, file])
  }
  const handleRemoveFile = (file: File) => {
    setfilesToUpload((prev) => prev.filter((f) => f.name !== file.name))
  }

  const handleCreateDoc = async () => {
    const { response, error } = await withAsync(() =>
      CreateDoc({
        data: {
          color: folderState.color,
          is_folder: true,
          startup: state.startupId,
          name: folderState.name,
        },
      })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([GET_FOLDERS])
      ToastNotify('success', {
        message: 'Success',
      })
      setfolderState({ name: '', color: 'purple' })
      toggleModal()
    }
  }

  //on first load folders
  useMemo(() => {
    if (getFolderSuccess) {
      firstLoadedFolderData.current = folders
    }
  }, [getFolderSuccess])

  //on first load folders
  useMemo(() => {
    if (getFileSuccess) {
      firstLoadedFileData.current = files
    }
  }, [getFileSuccess])

  //on first load
  // useMemo(() => {
  //   setisFirstLoad(true)
  // }, [])

  const isFoldersEmpty = !folders?.pages[0].results?.length
  const isFilesEmpty = !files?.pages[0].results?.length
  return (
    <main>
      <div className='relative'>
        <div
          className={clsx(
            'flex items-center gap-2 absolute top-[-58px]  sm:right-[9.5%] sm:top-[-20px] sm:w-[90%] sm:justify-between lg:right-0 lg:top-[-83px] lg:w-auto lg:justify-center',
            { 'lg:!top-[-98px]': isFolderLoading && isFilesLoading }
          )}
        >
          <div>
            {' '}
            <TextInput
              placeholder='Search'
              name='search'
              value={search}
              onChange={handleSearchChange}
              startIcon={<SearchIcon />}
              isLoading={isSearchLoading}
              endIcon={
                !!search && (
                  <FaX className='text-[0.7rem]' onClick={handleclearSearch} />
                )
              }
              className='w-full border border-gray-200 h-[48px] sm:w-[364px]'
            />
          </div>
          <Button color={'primary'} onClick={toggleModal}>
            Create New{' '}
          </Button>
        </div>

        {
          //if first load

          <LoadingState
            condition={
              isFolderLoading && isFilesLoading
                ? 'isLoading'
                : isFoldersEmpty && isFilesEmpty && !filesToUpload.length
                ? 'isEmpty'
                : isFoldersError || isFilesError
                ? 'isError'
                : ''
            }
            customEmptyDisplay={
              !!search ? (
                <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[60vh] rounded-md'>
                  <EmptyDisplay />
                  <Typography heading='2xs'>
                    No Folders or Files matching this query
                  </Typography>
                </div>
              ) : (
                <FileEmptyComponent
                  handleFileSelect={handleFileSelect}
                  toggleModal={toggleModal}
                />
              )
            }
          />
        }

        {isFoldersEmpty && isFilesEmpty && !filesToUpload.length ? null : (
          <div className='sm:pt-[50px] lg:pt-0'>
            <Typography heading='sm'>Folders</Typography>
            {searchEmptyState.isFolderEmpty && !!search ? (
              <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[30vh] rounded-md'>
                <EmptyDisplay />
                <Typography heading='2xs'>
                  No Folders matching this query
                </Typography>
              </div>
            ) : null}
            {!isFoldersEmpty && !searchEmptyState.isFolderEmpty && (
              <InfiniteScrollContainer
                isFetchingNextPage={isFetchingFolderNextPg}
                fetchNextPage={foldersFetchNextPg}
                hasNextPage={isFolderHasNextPg}
                containerClass='min-h-[60vh] sm:min-h-[40vh]'
              >
                <div className='mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3'>
                  {folders?.pages.map((pg) =>
                    pg.results.map((result: GetFoldersResDTO) =>
                      result.is_folder ? (
                        <Folder key={result.id} {...result} />
                      ) : null
                    )
                  )}
                </div>
              </InfiniteScrollContainer>
            )}

            <>
              <Typography heading='sm'>Files</Typography>

              <div
                className='w-full flex justify-between items-center mt-6'
                ref={fileParentRef}
              >
                <DropdownButton
                  title={'Sort'}
                  changeTitleOnSelect
                  items={[
                    {
                      name: 'Ascending',
                      value: 'asc',
                    },
                    {
                      name: 'Descending',
                      value: 'desc',
                    },
                  ]}
                  onClick={(item: any) => handleClickDropdownItem(item)}
                  btnClass='h-22px !bg-transparent !border-none'
                />
                <FileUploadBtn
                  btnElement={
                    <Button color='white' size='sm' startIcon={<FolderIcon />}>
                      Upload File
                    </Button>
                  }
                  onFileSelelcted={handleFileSelect}
                />
              </div>

              {searchEmptyState.isFileEmpty &&
              !!search &&
              !filesToUpload.length ? (
                <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[30vh] rounded-md'>
                  <EmptyDisplay />
                  <Typography heading='2xs'>
                    No Files matching this query
                  </Typography>
                </div>
              ) : null}
              {/* Files to upload */}
              <div className='mt-6 flex flex-col gap-3'>
                {filesToUpload.map((file) => (
                  <UploadingFile
                    key={file.name}
                    file={file}
                    handleRemoveFile={handleRemoveFile}
                  />
                ))}
              </div>
              {/* uploaded files */}
              {!isFilesEmpty && !searchEmptyState.isFileEmpty && (
                <InfiniteScrollContainer
                  isFetchingNextPage={isFetchingFilesNextPg}
                  fetchNextPage={filesFetchNextPg}
                  hasNextPage={isFilesHasNextPg}
                  containerClass='min-h-[60vh] sm:min-h-[40vh]'
                >
                  <div className='mt-6 flex flex-col gap-3'>
                    {files?.pages.map((pg) =>
                      pg.results.map((result: GetFoldersResDTO) =>
                        !result.is_folder ? (
                          <File
                            key={result.id}
                            {...result}
                            copiedFile={copiedFile}
                            setcopiedFile={setcopiedFile}
                          />
                        ) : null
                      )
                    )}
                  </div>
                </InfiniteScrollContainer>
              )}
            </>
          </div>
        )}
      </div>

      {/* create folder modal */}
      <RightModal
        ref={modalRef}
        enableWarning
        isForm
        title='New Folder'
        subtitle='Create a new folder to hold together related files for easy viewing.'
      >
        <div className='flex flex-col gap-28 w-full'>
          <div className='flex flex-col gap-4'>
            <div className='flex gap-2 flex-col'>
              <Typography label='sm'>Folder Name</Typography>

              <TextInput
                onChange={(e) =>
                  setfolderState((prev) => ({ ...prev, name: e.target.value }))
                }
                value={folderState.name}
                placeholder='Enter folder name'
                className='w-full bg-black-white-shades-1'
              />
            </div>
            <div className='flex gap-2 flex-col'>
              <Typography label='sm'>Choose a color</Typography>
              <div className='flex gap-2'>
                {Object.keys(folderColors).map((key) => (
                  <Color
                    key={key}
                    colorName={key as colorName}
                    selectedColor={folderState.color}
                    handleSetColor={handleSetColor}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              className='w-[149px]'
              loading={isCreateDocLoading}
              onClick={handleCreateDoc}
            >
              Create Folder
            </Button>
            <Button color='white' className='w-[149px]' onClick={toggleModal}>
              Cancel
            </Button>
          </div>
        </div>
      </RightModal>
    </main>
  )
}

export default Files

const Color = ({ colorName, selectedColor, handleSetColor }: ColorProp) => {
  const isSelected = selectedColor === colorName

  return (
    <div
      className='w-[47.81px] h-[47.81px] border flex items-center justify-center rounded cursor-pointer hover:opacity-80'
      style={{
        backgroundColor: folderColors[colorName],
      }}
      onClick={() => handleSetColor(colorName)}
    >
      {isSelected && <CheckedIcon />}
    </div>
  )
}
