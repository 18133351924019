import { AllHTMLAttributes, forwardRef } from 'react'

interface ITextArea extends AllHTMLAttributes<HTMLTextAreaElement> {
  error?: string
}

const Textarea = forwardRef<HTMLTextAreaElement, ITextArea>(
  (props: ITextArea, ref) => {
    const { error, className, style, ...rest } = props
    return (
      <div style={style} className='flex flex-col gap-1 flex-1'>
        <textarea
          ref={ref}
          {...rest}
          className={`focus:outline-none outline-none  flex-grow  font-normal text-sifuse-shades-800 px-4 py-3 text-sm leading-5  ${
            className ? className : ''
          }  rounded-lg  bg-sifuse-shades-75 border !border-sifuse-shades-200 " 
            ${error ? ' border-sifuse-main-red border-solid  ' : ''}`}
        />
        <small className='text-sifuse-main-red'>{error}</small>
      </div>
    )
  }
)

Textarea.displayName = 'TextArea'

export default Textarea
