import React, { useRef, useState } from 'react'
import ButtonTab from './components/ButtonTab'
import TextInput from '@/common/TextInput'
import { FaSearch, FaCamera, FaVideo, FaTimes, FaPlus } from 'react-icons/fa'
import Button from '@/common/button/Button'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'
import Post from './components/Post'
import ListView from './components/ListView'
import { useLocation, useNavigate } from 'react-router'
import SearchResults from './components/SearchResults'
import { FaUpRightAndDownLeftFromCenter, FaX } from 'react-icons/fa6'

import ModalContainer from './components/postModal/ModalContainer'
import {
  useCustomInfiniteQuery,
  useCustomQuery,
} from '@/hooks/react-query/query/useQuery'
import {
  POSTS,
  RECOMMENDED_INVESTORS,
  TIMELINE_SEARCH,
  TRENDING_STARTUPS,
} from '@/constants/querryKeys'
import CircularProgress from '@/common/spinners/CircularProgress'
import useIntersectionObserver from '@/hooks/useIntersectionObserver'
import LoadingState from './components/LoadingState'
import Typography from '@/common/Typography'
import { InfiniteScroll, Ref } from './components/utils/types'
import SinglePost from './components/SinglePost/SinglePost'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import PostSkeletonLoader from './components/PostSkeletonLoader'
import Title from '@/common/Title'
import {
  useGetPosts,
  useGetRecommendedInvestors,
  useGetTrendingStartups,
  useSearchTimeline,
} from '@/store/networkStore'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { WEB_SOCKET_POST } from '@/apis/endpoints'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { useQueryClient } from 'react-query'
import NewPostNotification from './components/NewPostNotification'

import useQueryParams from '@/hooks/useQueryParams'
import Modal, { RefType } from '@/common/Modal'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import { checkCategory } from './components/utils/checkCategory'
import { InvestorGroupType } from '@/apis/networkApis'

//https://loremflickr.com/320/240

interface ResultsStructure {
  content: string
  created: Date
  id: number
  updated: string
  media: []
  mentioned_startups: []
  mentioned_users: []
  num_comments: number
  num_likes: number
  like: boolean
  user: {
    avatar: string
    name: string
    about: string
    id: number
    user_type: string
    connection?: null | {
      id: number
      status: 'Pending' | 'Accepted' | 'Declined'
      is_sender: boolean
    }
  }
  investor_group: InvestorGroupType
}

interface TimelineSearchData {
  startups: {
    count: number
    startups: any[]
  }
  users: { count: number; users: any[] }
  posts: { count: number; posts: any[] }
}
const BreadCrumbMap = new Map()
// BreadCrumbMap.set('/app/networks/myNetwork', 'The Network')
BreadCrumbMap.set('/app/networks', 'The Timeline')
// BreadCrumbMap.set('excludeCount', 2)

function Timeline() {
  useBreadCrumbs(BreadCrumbMap)
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const modalRef1 = useRef<RefType>(null)
  const modalRef = useRef<RefType>(null)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const postTopRef = useRef<HTMLDivElement | null>(null)
  const isInTop = useRef<boolean>(false)
  const [timelineSearch, settimelineSearch] = React.useState<string>('')
  const [showScrollToTop, setshowScrollToTop] = React.useState<boolean>(false)

  const { getAccessToken } = useAuthContext()
  const params = useQueryParams()
  const token = getAccessToken()
  const [postId, setpostId] = React.useState<number | null>()

  isInTop.current = useIntersectionObserver(postTopRef as Ref)

  //check if link is copied
  React.useEffect(() => {
    if (params?.post) {
      setpostId(params?.post)
      modalRef1?.current?.handleToggle()
    }
  }, [params?.post])

  React.useEffect(() => {
    const client = new W3CWebSocket(WEB_SOCKET_POST + token + '/')

    client.onopen = () => {
      console.log('WebSocket Client Connected TO posts')
    }
    client.onmessage = (ev: any) => {
      let dataFromServer = {}

      try {
        dataFromServer = JSON.parse(ev.data)
        if (Object.keys(dataFromServer).length) {
          // const { action } = dataFromServer
          queryClient.invalidateQueries([POSTS])
          if (isInTop.current === false) {
            setshowScrollToTop(true)
          }
        }
      } catch (err) {
        console.log(ev.data, 'DATA FROM SERVER')
      }
    }
  }, [token, isInTop.current])

  const handleTabclick = (path: string) => {
    navigate(path)
  }

  const toggleModal1 = (id: number): void => {
    setpostId(id)
    modalRef1?.current?.handleToggle()
  }

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  //recomended investors
  const { data: recomendedInvestors, isLoading: recomendedInvestorsLoading } =
    useGetRecommendedInvestors()

  //trending startups to connect
  const { data: startUpsToConnect, isLoading: startUpsToConnectLoading } =
    useGetTrendingStartups()

  //search timeline
  const { data: timelineSearchData, isLoading: timelineSearchDataLoading } =
    useSearchTimeline({ search: timelineSearch })
  const timelineSearchDataMain =
    timelineSearchData as unknown as TimelineSearchData

  //get posts
  const {
    data: apiPosts,
    isLoading: postsLoading,
    isError: isPostError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPosts()

  const posts = (apiPosts as unknown as InfiniteScroll)?.pages
  const isPostEmpty = posts?.[0].data?.data?.results?.length < 1

  const scrollToTop = () => {
    parentRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    setshowScrollToTop(false)
  }
  console.log(posts)
  return (
    <div ref={parentRef}>
      <Title>The Timeline</Title>
      <NewPostNotification
        onClick={scrollToTop}
        show={showScrollToTop}
        setshow={setshowScrollToTop}
      />
      <div className='flex flex-col justify-between items-start sm:flex-row sm:items-center width-full'>
        <ButtonTab
          activeProperty={location.pathname}
          onClick={handleTabclick}
        />
        <div className='relative w-[80vw] sm:w-auto'>
          <TextInput
            placeholder='Search on the timeline'
            name='timelineSearch'
            value={timelineSearch}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              settimelineSearch(e.target.value)
            }
            startIcon={<SearchIcon />}
            endIcon={
              !!timelineSearch && (
                <FaX
                  className='text-[0.7rem]'
                  onClick={() => settimelineSearch('')}
                />
              )
            }
            className='w-full border border-gray-200 md:w-[200px] sm:h-[48px] lg:w-[364px]'
          />
          <SearchResults
            investors={timelineSearchDataMain?.users}
            startups={timelineSearchDataMain?.startups}
            posts={timelineSearchDataMain?.posts}
            isOpen={!!timelineSearch}
            toggleModal={toggleModal1}
          />
        </div>
      </div>

      <div className='flex flex-col justify-start gap-2  mt-3 sm:flex-row width-full'>
        {/* timeline */}

        <div className='flex-col flex gap-4 w-full md:w-[65%] lg:w-[70%]'>
          <div className='bg-[white] flex flex-col rounded-md gap-2 p-[24px] w-full border border-[#E6E6EB]'>
            <Typography heading='sm'> Timeline</Typography>

            <Button
              onClick={toggleModal}
              endIcon={<FaUpRightAndDownLeftFromCenter />}
              startIcon={<small> Start typing...</small>}
              color='lightGrey'
              className='w-full items-start justify-start flex text-gray-400 mb-2 border border-gray-200 hover:shadow-sm'
            ></Button>
          </div>
          <div className='h-fit-content min-h-[60vh] flex-col flex gap-2'>
            <div ref={postTopRef} />
            {
              //if first load

              <LoadingState
                skeletonLoader={<PostSkeletonLoader />}
                condition={
                  postsLoading
                    ? 'isLoading'
                    : isPostEmpty
                    ? 'isEmpty'
                    : isPostError
                    ? 'isError'
                    : ''
                }
                isEmptyDisplay={
                  <>
                    <Typography heading='3xs'>No post on timeline</Typography>
                    <Typography label='xs'>
                      You have not applied for a job yet
                    </Typography>
                  </>
                }
              />
            }
            <InfiniteScrollContainer
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            >
              <div className='flex-col flex gap-2'>
                {posts?.map((page) =>
                  page?.data.data?.results?.map((post: ResultsStructure) => (
                    <Post
                      key={post.id}
                      id={post.id}
                      src={checkCategory({
                        groupKey: 'logo',
                        userKey: 'avatar',
                        data: post,
                      })}
                      userId={Number(
                        checkCategory({
                          groupKey: 'id',
                          userKey: 'id',
                          data: post,
                        })
                      )}
                      name={checkCategory({
                        groupKey: 'name',
                        userKey: 'name',
                        data: post,
                      })}
                      title={checkCategory({
                        groupKey: 'category',
                        userKey: 'user_type',
                        data: post,
                      })}
                      content={post.content}
                      postImgs={post.media}
                      date={post.created}
                      like={post.like}
                      connection={post?.user?.connection}
                      showConnectBtn={false}
                      num_likes={post.num_likes}
                      num_comments={post.num_comments}
                    />
                  ))
                )}
              </div>
            </InfiniteScrollContainer>
          </div>
        </div>

        {/* Recommended Investor Connections */}
        <div className='flex flex-col gap-4 w-full md:w-[35%]  lg:w-[30%]'>
          <ListView
            title='Recommended Investor Connections'
            list={
              Array.isArray(recomendedInvestors) && recomendedInvestors.length
                ? recomendedInvestors
                : []
            }
            onClickItem={(id: any, name: any) =>
              navigate(`/app/networks/user/${name}`, {
                state: { id, from: 'investors' },
              })
            }
          />
          {/* Trending Startups to Connect */}
          <ListView
            title='Trending People to Connect'
            list={
              Array.isArray(startUpsToConnect) && startUpsToConnect.length
                ? startUpsToConnect
                : []
            }
            onClickItem={(id: any, name: any) =>
              navigate(`/app/networks/user/${name}`, {
                state: { id, from: 'startups' },
              })
            }
          />
        </div>
      </div>
      <Modal
        ref={modalRef1}
        modalClass='w-[90vw] sm:w-[800px] min-h-[80vh] pt-2'
      >
        <SinglePost id={postId} />
      </Modal>
      <Modal
        ref={modalRef}
        modalClass='w-[90vw] sm:w-[800px] min-h-[50vh]'
        showCloseBtn
        enableWarning
      >
        <ModalContainer toggleModal={toggleModal} />
      </Modal>
    </div>
  )
}

export default Timeline
