import { PaginationReq } from '@/apis/api.types'
import {
  CreateChat,
  CreateChatMedia,
  CreateMessage,
  DeleteChatMedia,
  GetChats,
  GetMessages,
  GetUnreadCount,
  MarkAllChatsAsRead,
  SharedDocument,
  SharedLink,
  SharedMedia,
} from '@/apis/chatApis'
import {
  CHATS,
  CHATS_MESSAGES,
  SHARED_DOCUMENT,
  SHARED_LINK,
  SHARED_MEDIA,
  UNREAD_COUNT,
} from '@/apis/endpoints'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export const useGetUnreadCount = () => {
  return useQuery([UNREAD_COUNT], GetUnreadCount)
}

export const useGetChats = (data: PaginationReq) => {
  return useInfiniteQuery(
    [CHATS, { search: data.search }],
    ({ pageParam = 1 }) => GetChats({ page: pageParam, query: data.search }),
    {
      getNextPageParam(lastPage, allPages) {
        if (!lastPage.next) return undefined
        const page = new URL(String(lastPage.next)).searchParams.get('page')
        return page
      },
      getPreviousPageParam: (firstPage, allPages) => {
        if (!firstPage.previous) return undefined
        const page = new URL(String(firstPage.previous)).searchParams.get(
          'page'
        )
        return page
      },
    }
  )
}

export const useCreateChat = () => {
  const qc = useQueryClient()
  return useMutation(CreateChat, {
    onSuccess() {
      qc.invalidateQueries([CHATS])
    },
    retry: false,
  })
}
export const useMarkAllChatAsRead = () => {
  const qc = useQueryClient()
  return useMutation(MarkAllChatsAsRead, {
    onSuccess() {
      qc.invalidateQueries([CHATS])
      qc.invalidateQueries([CHATS_MESSAGES])
      qc.invalidateQueries([UNREAD_COUNT])
    },
  })
}

export const useCreateChatMedia = () => {
  const qc = useQueryClient()
  return useMutation(CreateChatMedia, {
    onSuccess() {
      qc.invalidateQueries([CHATS_MESSAGES])
    },
  })
}

export const useDeleteChatMedia = () => {
  const qc = useQueryClient()
  return useMutation(DeleteChatMedia, {
    onSuccess() {
      qc.invalidateQueries([CHATS_MESSAGES])
    },
  })
}
export const useGetMessages = (chat: number, search = '') => {
  return useInfiniteQuery(
    [CHATS_MESSAGES, { chat, search }],
    ({ pageParam = 1 }) => GetMessages({ page: pageParam, search, chat }),
    {
      getNextPageParam(lastPage, allPages) {
        if (!lastPage.next) return undefined
        const page = new URL(String(lastPage.next)).searchParams.get('page')
        return page
      },
      getPreviousPageParam: (firstPage, allPages) => {
        if (!firstPage.previous) return undefined
        const page = new URL(String(firstPage.previous)).searchParams.get(
          'page'
        )
        return page
      },
      enabled: !!chat,
    }
  )
}

export const useCreateMessage = () => {
  const qc = useQueryClient()
  return useMutation(CreateMessage, {
    onSuccess() {
      qc.invalidateQueries([CHATS_MESSAGES])
      qc.invalidateQueries([CHATS])
    },
  })
}

export const useGetSharedDocument = (chat: number) => {
  return useQuery(SHARED_DOCUMENT, () => SharedDocument({ chat }), {
    enabled: !!chat,
  })
}
export const useGetSharedMedia = (chat: number) => {
  return useQuery(SHARED_MEDIA, () => SharedMedia({ chat }), {
    enabled: !!chat,
  })
}
export const useGetSharedLinks = (chat: number) => {
  return useQuery(SHARED_LINK, () => SharedLink({ chat }), {
    enabled: !!chat,
  })
}
