import React, { useRef, useState } from 'react'
import { FaCamera, FaVideo } from 'react-icons/fa'

import { v4 as uuidv4 } from 'uuid'

import Button from '@/common/button/Button'

import { ReactComponent as CloseBtn } from '@/assets/network/CloseBtn.svg'

import { useQueryClient } from 'react-query'
import { errorHandler, withAsync } from '@/helpers/withAsync'

import Typography from '@/common/Typography'
import { ToastNotify } from '@/common/toastManager'
import { useLocation } from 'react-router'
import {
  useDeleteUpdate,
  usePostUpdates,
  usePostUpdatesMedia,
} from '@/store/dataRoomStore'
import { FileToUpload } from '@/pages/theNetwork/components/utils/types'
import FileUpload from '@/pages/theNetwork/components/FileUpload'
import { GET_UPDATES } from '@/apis/endpoints'

interface Props {
  toggleModal?: () => void
  showTitle?: boolean
  startupId: number
}

function ModalContainer({ toggleModal, showTitle, startupId }: Props) {
  const editableRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()

  const [imgs, setimgs] = useState<JSX.Element[]>([])
  const [filesToUpload, setfilesToUpload] = useState<FileToUpload[]>([])

  const createImg = (file: File, src: string) => {
    const el = React.createElement
    const id = uuidv4()
    const deleteImgIcon = el(
      'span',
      {
        className: 'absolute top-2 right-2 cursor-pointer',

        onClick: () => {
          setimgs((prev) => prev.filter((prev) => prev.props.id !== id))
          setfilesToUpload((prev) => prev.filter((prev) => prev.id !== id))
        },
      },
      <CloseBtn />
    )

    const img = el('img', {
      className: 'w-full h-full rounded-md object-center object-cover',
      alt: 'img to upload',
      src,
    })
    const imgCont = el(
      'div',
      {
        className: 'relative rounded-md h-[100px]  w-[100%] sm:w-[212px] ',
        id,
      },
      deleteImgIcon,
      img
    )

    setimgs((prev) => [...prev, imgCont])
    setfilesToUpload((prev) => [...prev, { file, id }])
  }
  const createVideo = (file: File, src: string) => {
    const id = uuidv4()

    const vidCont = (
      <div
        id={id}
        className={`relative bg-gray-900 rounded-md h-[100px]  w-[100%] sm:w-[212px]`}
      >
        <span
          className='absolute top-1 right-1 cursor-pointer z-20'
          onClick={() => {
            setimgs((prev) => prev.filter((pre) => pre.props.id !== id))
            setfilesToUpload((prev) => prev.filter((prev) => prev.id !== id))
          }}
        >
          <CloseBtn />
        </span>

        <video
          controls
          disablePictureInPicture
          controlsList='nodownload nofullscreen noremoteplayback'
          muted
          className='m-auto w-full rounded-md h-full object-center object-cover'
          src={src}
          id='post-video'
        />
      </div>
    )

    setimgs((prev) => [...prev, vidCont])
    setfilesToUpload((prev) => [...prev, { file, id }])
  }

  const onFileSelelcted = (file: File, fileUrl: string) => {
    if (file.type === 'video/mp4') {
      createVideo(file, fileUrl)
    } else {
      createImg(file, fileUrl)
    }
  }
  const photoUpload = (
    <Button className='border' startIcon={<FaCamera />} size='xs' color='white'>
      Photo
    </Button>
  )
  const videoUpload = (
    <Button className='border' startIcon={<FaVideo />} size='xs' color='white'>
      Video
    </Button>
  )

  //create post

  const { mutateAsync: createPost, isLoading: createPostLoading } =
    usePostUpdates()

  // create post media
  const { mutateAsync: createPostMedia, isLoading: isCreateMediaLoading } =
    usePostUpdatesMedia()

  //delete post
  const { mutateAsync: deletePost } = useDeleteUpdate()

  const handdleCreatePost = async () => {
    const { error, response } = await withAsync(() => {
      return createPost({
        content: editableRef?.current?.innerHTML as string,
        startup: startupId,
      })
    })

    if (error) {
      return errorHandler(error)
    }
    if (response) {
      if (filesToUpload?.length) {
        // isUploading = true
        const files = filesToUpload.map((f) => {
          const formData = new FormData()
          formData.append('file', f.file)
          formData.append('update', String(response.data.data.id))
          return createPostMedia(formData)
        })
        const { error: uploadErr, response: uploadResponse } = await withAsync(
          () => {
            return Promise.all(files)
          }
        )
        if (uploadErr) {
          deletePost({ id: response.data.data.id })
          errorHandler(error)
          return
        }

        if (uploadResponse?.length) {
          console.log('success inside post')
          ToastNotify('success', {
            message: 'Success',
          })
          queryClient.invalidateQueries([GET_UPDATES])
          toggleModal && toggleModal()
        }
      } else {
        ToastNotify('success', {
          message: 'Success',
        })
        queryClient.invalidateQueries([GET_UPDATES])
        toggleModal && toggleModal()
      }
    }
  }

  return (
    <div className='flex flex-col align-start w-full p-[24px] gap-2 '>
      {showTitle && <Typography heading='sm'>Update</Typography>}

      <div className='flex w-full flex-col align-start gap-2 rounded-md border border-gray-200 p-2 h-[40vh] overflow-y-auto'>
        <div className='relative'>
          <div
            className='h-auto w-full focus:outline-transparent'
            ref={editableRef}
            contentEditable={'true'}
            id='inputArea'
          />
        </div>

        <div
          id='gridParent'
          className='grid grid-cols-2 gap-1 sm:grid-cols-2 lg:grid-cols-3'
        >
          {imgs.map((img, i) => (
            <React.Fragment key={uuidv4()}>{img}</React.Fragment>
          ))}
        </div>
      </div>

      <div className='w-full flex flex-col justify-between items-end lg:flex-row lg:items-center'>
        <div className='mt-2 flex flex-row gap-2 items-center flex-wrap'>
          <FileUpload
            btnElement={photoUpload}
            onFileSelelcted={onFileSelelcted}
          />
          <FileUpload
            btnElement={videoUpload}
            onFileSelelcted={onFileSelelcted}
            type='video'
          />
        </div>
        <Button
          className='w-[110px] px-5 font-bold'
          size='xs'
          onClick={handdleCreatePost}
          disabled={createPostLoading}
          loading={createPostLoading || isCreateMediaLoading}
        >
          {showTitle ? 'Post' : '   Share Your first update'}
        </Button>
      </div>
    </div>
  )
}

export default ModalContainer
