import clsx from 'clsx'
import { CSSProperties } from 'react'

const Dot = (props: {
  className?: string
  style?: CSSProperties
  onClick?: () => void
}) => {
  return (
    <span
      style={props.style}
      onClick={props.onClick}
      className={clsx('w-1 h-1 min-w-[4px] rounded-full', props.className)}
    ></span>
  )
}

export default Dot
