export const checkCategory = ({
  groupKey,
  userKey,
  data,
}: {
  groupKey: string
  userKey: string
  data: Record<string, any>
}): string => {
  return data?.investor_group
    ? (data?.investor_group?.[groupKey] as string)
    : (data?.user?.[userKey] as string)
}
