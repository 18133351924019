import { ReactComponent as FolderIcon } from '@/assets/icons/utils/whiteFolder.svg'
import { ReactComponent as FolderIcon1 } from '@/assets/icons/utils/blackFolder.svg'

import Typography from '@/common/Typography'

import Button from '@/common/button/Button'

import { GetFoldersResDTO } from '@/apis/dataRoomApis'

import { CREATE_DOCUMENT } from '@/apis/endpoints'

import { baseAPIURL } from '@/apis/api'
import useDownload from '@/hooks/useDownload'

import { folderColors } from '@/pages/dataRoom/utils/consts'
function FolderComponent({
  color = 'purple',
  name: folderName,
  id,
  file_count,
}: Partial<GetFoldersResDTO>) {
  const { exportHandler, isDownloading } = useDownload()

  const handleDownloadFolderDocs = () => {
    const url = baseAPIURL
    exportHandler(`${url}${CREATE_DOCUMENT}${folderName}/${id}/download/`, {
      file_name: folderName,
    })
  }

  return (
    <main>
      <div
        className={`w-full gap-3 flex-col rounded-[8px] p-[16px] flex justify-between sm:flex-row`}
        style={{
          background: `${folderColors[color]}`,
        }}
      >
        <div className={`flex justify-between w-full`}>
          <div className='flex gap-2 items-start justify-start'>
            <FolderIcon />
            <div className='flex gap-1 flex-col items-start justify-start'>
              <Typography heading='sm' className='!text-white -m-1 '>
                {folderName}
              </Typography>
              <Typography paragraph='md' className='!text-white'>
                {file_count || 0} File
                {file_count && +file_count > 1 ? 's' : null}
              </Typography>
            </div>
          </div>
          <div className='flex align-center gap-2'>
            <Button
              color='white'
              size='xs'
              className='w-auto hidden sm:flex'
              endIcon={<FolderIcon1 />}
              onClick={handleDownloadFolderDocs}
              loading={isDownloading}
              disabled={file_count ? +file_count < 1 : true}
            >
              Download all files
            </Button>
          </div>
        </div>
        <Button
          color='white'
          size='xs'
          className='flex w-[135px] sm:hidden'
          endIcon={<FolderIcon1 />}
          onClick={handleDownloadFolderDocs}
          loading={isDownloading}
          disabled={file_count ? +file_count < 1 : true}
        >
          Download all files
        </Button>
      </div>
    </main>
  )
}

export default FolderComponent
