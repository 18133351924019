import TextInput from '@/common/TextInput'
import Typography from '@/common/Typography'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'
import { ReactComponent as CheckedIcon } from '@/assets/icons/utils/whitechecked.svg'
import React, { useMemo, useRef } from 'react'
import Button from '@/common/button/Button'

import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'

import { ReactComponent as FolderIcon } from '@/assets/icons/utils/blackFolder.svg'
import File from '../components/File'
import LoadingState from '@/pages/theNetwork/components/LoadingState'

import { RefType } from '@/common/Modal'
import RightModal from '@/common/RightModal'
import { folderColors } from '../utils/consts'
import FileUploadBtn from '@/common/FileUploadBtn'
import UploadingFile from '../components/UploadingFile'
import FolderComponent from '../components/FolderComponent1'
import FileEmptyComponent from '../components/FilesEmptyDisplay'
import { useLocation, useParams } from 'react-router'
import { Pages } from '@/routes/route.constant'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import Title from '@/common/Title'
import {
  useGetDocument,
  useGetFiles,
  useSearchDocuments,
} from '@/store/dataRoomStore'
import { GetFoldersResDTO } from '@/apis/dataRoomApis'
import { PaginatedData } from '@/apis/api.types'
import { useQueryClient } from 'react-query'
import { GET_FILES } from '@/apis/endpoints'
import { FaX } from 'react-icons/fa6'

export interface ReturnedData {
  pages?: PaginatedData<GetFoldersResDTO>[]
}

function Folder() {
  const { startupName, folderName } = useParams()
  const { state } = useLocation()
  const queryClient = useQueryClient()

  const startupId = state.startupId
  const folderId: number = +state.folderId

  const BreadCrumbMap = new Map()
  BreadCrumbMap.set(`/app/${Pages.DataRoom}`, 'Data Room')
  BreadCrumbMap.set(`/app/${Pages.DataRoom}/${startupName}`, startupName)
  BreadCrumbMap.set(
    `/app/${Pages.DataRoom}/${startupName}/${folderName}`,
    folderName
  )

  useBreadCrumbs(BreadCrumbMap)

  const firstLoadedData = useRef<ReturnedData>({})
  const [search, setsearch] = React.useState<string>('')
  const [filesToUpload, setfilesToUpload] = React.useState<File[]>([])
  const [copiedFile, setcopiedFile] = React.useState<number | null>(null)
  const handleFileSelect = (file: File) => {
    setfilesToUpload((prev) => [...prev, file])
  }
  const handleRemoveFile = (file: File) => {
    setfilesToUpload((prev) => prev.filter((f) => f.name !== file.name))
  }

  //because search url is different from get file url, this function sets GET_FILES
  //query data to the return val of the search
  const handleSearchSuccess = (res: any) => {
    queryClient.setQueryData(
      [
        GET_FILES,
        {
          sort_order: undefined,
          folder: folderId,
          startup: startupId,
          parent: true,
        },
      ],
      (oldQueryData) => {
        return {
          ...res,
        }
      }
    )
  }

  //this clears the search state and returns the state to its first value after load
  // since search is restricted to work only when there is search value
  const handleclearSearch = () => {
    setsearch('')
    queryClient.setQueryData(
      [
        GET_FILES,
        {
          sort_order: undefined,
          folder: folderId,
          startup: startupId,
          parent: true,
        },
      ],
      (oldQueryData) => {
        return {
          ...firstLoadedData.current,
        }
      }
    )
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //if no search, return the state to its initial value
    if (!e.target.value) return handleclearSearch()
    setsearch(e.target.value)
  }

  //get folder
  const {
    isLoading: isFolderLoading,
    data: folder,
    isError: isFolderError,
  } = useGetDocument({ id: folderId })

  //get files
  const {
    isLoading: isFilesLoading,
    data: files,
    isError: isFilesError,
    fetchNextPage: filesFetchNextPg,
    hasNextPage: isFilesHasNextPg,
    isFetchingNextPage: isFetchingFilesNextPg,
    isSuccess: getFileSuccess,
    isFetched,
    isFetchedAfterMount,
  } = useGetFiles({ folder: folderId, startup: startupId, parent: true })

  //search documents
  const { isLoading: isSearchLoading } = useSearchDocuments({
    folder: folderId,
    query: search,
    startup: startupId,
    cb: handleSearchSuccess,
  })

  useMemo(() => {
    if (getFileSuccess) {
      firstLoadedData.current = files
    }
  }, [getFileSuccess])

  const isFilesEmpty = !files?.pages[0].results?.length

  return (
    <main>
      <Title>{`Data Room - ${startupName}`}</Title>
      <div className='flex flex-col gap-4 '>
        {!isFilesLoading && !isFilesError && (
          <FolderComponent
            name={folder?.name || folderName}
            id={folderId}
            color={folder?.color}
            file_count={folder?.file_count}
          />
        )}
        <div>
          <div className='w-full flex flex-col justify-center items-start gap-2 sm:gap-0 sm:justify-between sm:items-center sm:flex-row'>
            <Typography heading='sm'>Files</Typography>
            <div className='flex gap-2 items-center flex-row-reverse sm:flex-row'>
              <FileUploadBtn
                btnElement={
                  <div className='w-full'>
                    <Button
                      color='white'
                      size='lg'
                      className='hidden sm:flex'
                      startIcon={<FolderIcon />}
                    >
                      Upload File
                    </Button>
                    <Button color='white' size='lg' className='block sm:hidden'>
                      <FolderIcon />
                    </Button>
                  </div>
                }
                onFileSelelcted={handleFileSelect}
              />

              <TextInput
                placeholder='Search'
                name='search'
                value={search}
                onChange={handleSearchChange}
                // onSubmit={handleSearch}
                startIcon={<SearchIcon />}
                isLoading={isSearchLoading}
                endIcon={
                  !!search && (
                    <FaX
                      className='text-[0.7rem]'
                      onClick={handleclearSearch}
                    />
                  )
                }
                className='w-[320px] border border-gray-200 h-[48px] sm:w-[364px]'
              />
            </div>
          </div>

          {
            //if first load

            <LoadingState
              condition={
                isFilesLoading
                  ? 'isLoading'
                  : isFilesEmpty && !filesToUpload.length
                  ? 'isEmpty'
                  : isFilesError
                  ? 'isError'
                  : ''
              }
              customEmptyDisplay={<FileEmptyComponent />}
            />
          }
          {/* Files to upload */}
          <div className='mt-6 flex flex-col gap-3'>
            {filesToUpload.map((file) => (
              <UploadingFile
                key={file.name}
                file={file}
                handleRemoveFile={handleRemoveFile}
                isInsideFolder
              />
            ))}
          </div>
          {/* uploaded files */}
          {!isFilesEmpty && (
            <InfiniteScrollContainer
              isFetchingNextPage={false}
              fetchNextPage={() => {}}
              hasNextPage={false}
              containerClass='min-h-[60vh] sm:min-h-[40vh]'
            >
              <div className='mt-6 flex flex-col gap-3'>
                {files?.pages &&
                  files?.pages.map((pg) =>
                    pg.results.map((result: GetFoldersResDTO) => (
                      <File
                        key={result.id}
                        {...result}
                        copiedFile={copiedFile}
                        setcopiedFile={setcopiedFile}
                      />
                    ))
                  )}
              </div>
            </InfiniteScrollContainer>
          )}
        </div>
      </div>
    </main>
  )
}

export default Folder
