import BounceLoader from 'react-spinners/BounceLoader'
import { FC, ReactNode, Suspense } from 'react'
import AppErrorBoundary from '@/hoc/AppErrorBoundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthContextProvider from './contexts/AuthContextProvider'
import 'react-toastify/dist/ReactToastify.css'
const queryClient = new QueryClient()

const CombineProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AppErrorBoundary>
      <Suspense
        fallback={
          <div className='w-screen h-screen flex justify-center items-center bg-white'>
            <BounceLoader color='var(--mainprimary)' loading size={75} />
          </div>
        }
      >
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </AuthContextProvider>
      </Suspense>
    </AppErrorBoundary>
  )
}

export default CombineProviders
