import { GetFoldersResDTO } from '@/apis/dataRoomApis'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import useDownload from '@/hooks/useDownload'
import moment from 'moment'
import { ReactComponent as PdfIcon } from '@/assets/icons/media/pdf.svg'
import { ReactComponent as WordIcon } from '@/assets/icons/media/docx.svg'
import { ReactComponent as ExcelIcon } from '@/assets/icons/media/excel.svg'
import { ReactComponent as SmallFolderIcon } from '@/assets/icons/utils/smallFolder.svg'
import { baseAPIURL } from '@/apis/api'
import { CREATE_DOCUMENT } from '@/apis/endpoints'
interface Props {
  fileName: string | undefined
  file: GetFoldersResDTO
  id: number
}
function FileComponent({ fileName, file, id }: Props) {
  const { exportHandler, isDownloading } = useDownload()

  let icon = <></>
  switch (file?.file_type) {
    case 'docx':
      icon = <WordIcon />
      break
    case 'excel':
      icon = <ExcelIcon />
      break
    case 'pdf':
      icon = <PdfIcon />
      break

    default:
      break
  }

  const handleDownloadDoc = async () => {
    const url = baseAPIURL
    exportHandler(`${url}${CREATE_DOCUMENT}${fileName}/${id}/download/`, {
      file_name: fileName,
      file_type: file?.file_type,
    })
  }
  return (
    <div className='flex w-[97%] m-a flex-col gap-2 justify-between align-center bg-white rounded-md border h-auto p-2 sm:flex-row sm:h-[64px] sm:gap-0'>
      {/* items on the left */}
      <div className='flex gap-2 items-start'>
        {icon}
        <div className='flex flex-col gap-1'>
          <span className='flex items-center'>
            <Typography label='md'>{fileName}</Typography>
            <Typography label='md' className='!text-black-white-shades-3'>
              .{file?.file_type}
            </Typography>
          </span>
          <span className='flex items-center gap-2'>
            <Typography label='sm' className='!text-black-white-shades-4'>
              Last Modified
            </Typography>
            <div className='w-[3px] h-[3px] rounded-full bg-black-white-shades-4' />
            <Typography label='md' className='!text-black-white-shades-4'>
              {moment(file?.updated).format('Do MMMM YYYY')}
            </Typography>
          </span>
        </div>
      </div>

      {/* items on the right */}
      <div className='flex gap-2 items-center px-2'>
        <Button
          size='xs'
          color='white'
          endIcon={<SmallFolderIcon />}
          loading={isDownloading}
          onClick={handleDownloadDoc}
        >
          Download/{file?.size}
        </Button>
      </div>
    </div>
  )
}

export default FileComponent
