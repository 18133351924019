import useLogout from '@/apis/hooks/useLogout'
import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import Avatar from '@/common/avatar/Avatar'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { useComposeRefs } from '@/hooks/useComposeRefs'
import { useOutsideClick } from '@/hooks/useOutsideClick'
import clsx from 'clsx'
import { MouseEventHandler, forwardRef, useRef, useState } from 'react'

const UserInfo = forwardRef<HTMLDivElement, { onClose?: () => void }>(
  (props, ref) => {
    const { getAuthState } = useAuthContext()
    const userState = getAuthState()
    const logout = useLogout()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const outerRef = useRef<HTMLDivElement>(null)
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    anchorRef.current = anchorEl

    const clickHandler: MouseEventHandler = (e) => {
      e.preventDefault()
      if (anchorEl) {
        setAnchorEl(null)
      } else setAnchorEl(e.currentTarget as HTMLButtonElement)
    }
    useOutsideClick([anchorRef, outerRef], () => setAnchorEl(null))
    const combinedRef = useComposeRefs(outerRef, ref)
    const logoutHandler = async () => {
      setAnchorEl(null)
      logout()
    }

    return (
      <section
        ref={combinedRef}
        className='w-full flex h-12 px-4 gap-2 items-center cursor-pointer relative shrink-0'
      >
        <hgroup className='flex items-center gap-2'>
          <Avatar
            size='sm'
            notification
            notificationClassName=' right-0'
            src={userState?.avatar || '/defaultImage.svg'}
          />
          <div className='flex flex-col justify-center items-start'>
            <Typography
              as='h6'
              button='sm'
              className='whitespace-nowrap text-ellipsis overflow-hidden'
            >
              {userState?.first_name} {userState?.last_name}
            </Typography>
            <Typography
              as='p'
              paragraph='xs'
              color='var(--shades700)'
              className='capitalize'
            >
              {userState?.user_type}
            </Typography>
          </div>
        </hgroup>
        <button onClick={clickHandler} className='p-3'>
          <iconComponents.navigation.DropdownNavIcon />
        </button>
        {Boolean(anchorEl) && (
          <div
            className={clsx(
              'flex items-center  gap-2 w-[208px] bg-sifuse-shades-50 rounded-lg py-2 px-4  h-12 hover:!text-black absolute top-6 left-[104px]  md:top-12 md:right-0 md:left-auto xl:left-[48px] xl:right-0',
              Boolean(anchorEl) ? 'flex' : 'hidden'
            )}
            onClick={logoutHandler}
          >
            <iconComponents.navigation.LogoutIcon />
            <Typography as='button' button='sm' color='var(--shades700)'>
              Logout
            </Typography>
          </div>
        )}
      </section>
    )
  }
)

UserInfo.displayName = 'UserInfo'

export default UserInfo
