import React, { RefObject } from 'react'

const useIntersectionObserver = (
  ref: RefObject<Element> | null,
  threshold = 1
) => {
  const [isInterceting, setIsInterceting] = React.useState<boolean>(false)
  const options = {
    threshold: threshold,
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInterceting(entry.isIntersecting),
      options
    )
    ref?.current && observer.observe(ref?.current)
    return () => {
      observer.disconnect()
    }
  }, [])
  return isInterceting
}
export default useIntersectionObserver
