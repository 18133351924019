import { clamp } from '@/helpers/numberFormatter'
import clsx from 'clsx'
import React from 'react'

type IProgress = {
  max: number
  value: number
  trackColor?: string
  thumbColor?: string
  className?: string
  showPercent?: boolean
  textElement?: React.ReactNode
}
const Progress = (props: IProgress) => {
  const {
    max,
    value,
    trackColor = '#CCECEF',
    thumbColor = '#00A0AE',
    className = '',
    showPercent = true,
    textElement,
  } = props
  return (
    <div className='w-full'>
      <progress value={value} max={max} className='hidden'></progress>
      <div
        className={clsx('flex justify-between items-center gap-3', className)}
      >
        <div
          style={{
            backgroundColor: trackColor,
          }}
          className={clsx(`flex-1 p-0 h-2 rounded`)}
        >
          <div
            style={{
              width: clamp(Math.round((value / max) * 100), 0, 100) + '%',
              backgroundColor: thumbColor,
            }}
            className={clsx(`rounded h-full duration-300 transition-[width]`)}
          ></div>
        </div>
        {showPercent && (
          <div className='text-jaa-gray-700 sm font-semibold'>
            {textElement || Math.round((value / max) * 100) + '%'}
          </div>
        )}
      </div>
    </div>
  )
}

export default Progress
