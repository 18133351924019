import React from 'react'
import FollowingItem from './FollowingItem'
import { InvestorGroupType } from '@/apis/networkApis'

interface ResultsStructure {
  id: number
  user: {
    id: number
    name: string
    about: string
    avatar: string
    user_type: string
    num_connections: number
    num_followers: number
    connected: string
  }
  created: Date
  investor_group: InvestorGroupType
}

interface PostsStructure {
  results: ResultsStructure[]
  count: number
  next: string
  prev: string
}
interface ReturnedData {
  data: { data: PostsStructure }
}

function Following({ data }: ReturnedData) {
  const count = data.data.count || 0
  const result = data.data.results

  return (
    <div className='flex flex-col gap-3 items-start w-full'>
      {count !== 0 ? (
        <p className='font-light'>
          You are connected to {count} {count === 1 ? 'person' : 'people'}
        </p>
      ) : null}

      {result.map((item) => (
        <FollowingItem key={item.id} {...item} />
      ))}
    </div>
  )
}

export default Following
