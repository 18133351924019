import Chip from '@/common/Chip'
import Modal, { RefType } from '@/common/Modal'

import React, { LegacyRef, useEffect, useMemo, useRef, useState } from 'react'
import { FaCamera, FaPlus, FaSearch, FaTimes, FaVideo } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'
import { v4 as uuidv4 } from 'uuid'

import TextInput from '@/common/TextInput'
import Button from '@/common/button/Button'
import FileUpload from '../FileUpload'
import { ReactComponent as CloseBtn } from '@/assets/network/CloseBtn.svg'
import { useCustomMutation } from '@/hooks/react-query/mutate/useMutateFunc'
import { toast } from 'react-toastify'
import { useCustomQuery } from '@/hooks/react-query/query/useQuery'
import { POSTS, POST_MENTIONS } from '@/constants/querryKeys'
import CircularProgress from '@/common/spinners/CircularProgress'
import { imagesUrl } from '@/assets/images/imageUrls'
import { useQueryClient } from 'react-query'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import {
  useCreatePost,
  useCreatePostMedia,
  useDeletePost,
  useGetTimelineMentions,
} from '@/store/networkStore'
import { FileToUpload } from '../utils/types'
import Typography from '@/common/Typography'
import { ToastNotify } from '@/common/toastManager'

interface Props {
  toggleModal: () => void
}
interface SelectionClone {
  [key: string]: any // You can adjust this to more specific types if needed
}

type TagObj = {
  name: string
  id: number
  userType: string
  type?: string
  img?: string
}

const insertTextAtCaretPosition = (item: TagObj, prevSelection: Selection) => {
  const textToInsert = item.name
  // let sel = window.getSelection()

  const range = document.createRange()
  if (prevSelection) {
    let element = document.createElement('span') as HTMLSpanElement

    if (textToInsert) {
      element.setAttribute(
        'class',
        `text-primary ${textToInsert.replace(/[\s-]+/g, '')} atTags`
      )
      element.innerHTML = ` @${textToInsert}`
      element.setAttribute('contenteditable', 'false')
      element.setAttribute('data-user-id', String(item.id))
      element.setAttribute('data-user-type', item.type || '')
    }

    range.setStartAfter(prevSelection?.anchorNode as Node)

    range.insertNode(element)

    range.setStartAfter(element)
    range.collapse(true)
    const newSelection = window.getSelection()
    if (newSelection) {
      // newSelection.deleteFromDocument()
      newSelection.removeAllRanges()
      newSelection.addRange(range)
    }
  }
}
const insertAtEmptyTextField = (item: TagObj, editableDiv: HTMLDivElement) => {
  const textToInsert = item.name
  let sel = window.getSelection()

  const range = document.createRange()
  if (sel) {
    sel.removeAllRanges()
    sel.addRange(range)

    let element = document.createElement('span') as HTMLSpanElement
    const textNode = document.createTextNode('\u00A0')
    if (textToInsert) {
      element.setAttribute(
        'class',
        `text-primary ${textToInsert.replace(/[\s-]+/g, '')} atTags`
      )
      element.innerHTML = `@${textToInsert}`
      element.setAttribute('contenteditable', 'false')
      element.setAttribute('data-user-id', String(item.id))
      element.setAttribute('data-user-type', item.userType)
    }

    range.selectNodeContents(editableDiv)

    range.insertNode(textNode)
    range.insertNode(element)

    range.setStartAfter(textNode)
    range.collapse(true)

    sel.removeAllRanges()
    sel.addRange(range)
  }
}

const insertEmptyTagOnSpacebar = () => {
  let sel = window.getSelection() as SelectionClone
  const range = document.createRange()
  if (sel) {
    const textNode = document.createTextNode('\u00A0')

    range.setStartAfter(sel?.anchorNode as Node)

    range.insertNode(textNode)

    // Move the caret to the end of the newly inserted text

    range.setStartAfter(textNode)

    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
  }
}

interface AtListProps {
  onClose: () => void
  onClickItem: (item: TagObj) => void
}

const AtList = ({ onClose, onClickItem }: AtListProps) => {
  const [search, setsearch] = useState<string>('')

  //get post mentions
  const { data, isLoading, isError } = useGetTimelineMentions({ query: search })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '@') return
    setsearch(e.target.value)
  }

  return (
    <div className='absolute top-[100%] left-0 bg-[white] shadow rounded-md w-[220px] h-[30vh] overflow-y-auto z-10 border border-gray-400 p-2 pt-4'>
      <FaX
        className='absolute right-1 top-1 cursor-pointer'
        onClick={onClose}
      />
      <div className='flex flex-col align-start gap-3 mt-1'>
        <TextInput
          placeholder='Search'
          name='search'
          value={search}
          onChange={handleChange}
          startIcon={<FaSearch />}
          className='w-full'
          autoFocus
        />
        {isLoading && <CircularProgress color='black' />}
        {!isError &&
          !isLoading &&
          Array.isArray(data?.data?.data) &&
          data?.data?.data?.map((data: TagObj, i: number) => (
            <div
              key={data.id}
              className='flex items-center justify-start gap-1'
            >
              <img
                alt='uploaded_img'
                className='w-[25px] h-[25px] rounded-full object-center object-cover'
                src={data?.img || imagesUrl.blanckProfileImg}
              />

              <small
                key={i}
                className='cursor-pointer'
                onClick={() => onClickItem(data)}
              >
                {data?.name}
              </small>
            </div>
          ))}
      </div>
    </div>
  )
}

function ModalContainer({ toggleModal }: Props) {
  const prevSelection = useRef<SelectionClone>()

  const editableRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()
  const [openAtList, setopenAtList] = React.useState<boolean>(false)
  const [imgs, setimgs] = useState<JSX.Element[]>([])
  const [filesToUpload, setfilesToUpload] = useState<FileToUpload[]>([])
  const [tags, settags] = useState<Array<TagObj>>([])
  const [isSpacebarPressed, setisSpacebarPressed] = useState<boolean>(false)

  // Convert the Set to an array
  const tagsArray = Array.from(tags)

  const createImg = (file: File, src: string) => {
    const el = React.createElement
    const id = uuidv4()
    const deleteImgIcon = el(
      'span',
      {
        className: 'absolute top-2 right-2 cursor-pointer',

        onClick: () => {
          setimgs((prev) => prev.filter((prev) => prev.props.id !== id))
          setfilesToUpload((prev) => prev.filter((prev) => prev.id !== id))
        },
      },
      <CloseBtn />
    )

    const img = el('img', {
      className: 'w-full h-full rounded-md object-center object-cover',
      alt: 'img to upload',
      src,
    })
    const imgCont = el(
      'div',
      {
        className: 'relative rounded-md h-[100px]  w-[100%] sm:w-[212px] ',
        id,
      },
      deleteImgIcon,
      img
    )

    setimgs((prev) => [...prev, imgCont])
    setfilesToUpload((prev) => [...prev, { file, id }])
  }
  const createVideo = (file: File, src: string) => {
    const id = uuidv4()

    const vidCont = (
      <div
        id={id}
        className={`relative bg-gray-900 rounded-md h-[100px]  w-[100%] sm:w-[212px]`}
      >
        <span
          className='absolute top-1 right-1 cursor-pointer z-20'
          onClick={() => {
            setimgs((prev) => prev.filter((pre) => pre.props.id !== id))
            setfilesToUpload((prev) => prev.filter((prev) => prev.id !== id))
          }}
        >
          <CloseBtn />
        </span>

        <video
          controls
          disablePictureInPicture
          controlsList='nodownload nofullscreen noremoteplayback'
          muted
          className='m-auto w-full rounded-md h-full object-center object-cover'
          src={src}
          id='post-video'
        />
      </div>
    )

    setimgs((prev) => [...prev, vidCont])
    setfilesToUpload((prev) => [...prev, { file, id }])
  }

  const handleKeyDown = (e: any) => {
    const key = e.key
    //check if its the first text, and user wants to start with @
    const text = editableRef?.current?.innerText

    if (!text && key === '@') setopenAtList(true)

    if (e.code === 'Space') {
      e.preventDefault()
      const sel = window.getSelection() as SelectionClone

      if (sel) {
        //clone the getSelection() obj and assign it to the prev selelection ref
        //because if not cloned first, it will always get re assigned to the parent element
        const clone: SelectionClone = {}
        for (let prop in sel) {
          clone[prop] = sel[prop] as SelectionClone
        }
        prevSelection.current = clone
      }

      // console.log(sel)
      insertEmptyTagOnSpacebar()
      setisSpacebarPressed(true)
    } else if (key !== '@' && e.code !== 'Space' && key !== 'Shift') {
      setisSpacebarPressed(false)
    }

    // Handle the "@" character
    if (key === '@' && isSpacebarPressed) {
      setopenAtList(true)
    }
  }

  const onClickAtItem = (item: TagObj) => {
    const text = editableRef?.current?.innerText

    //if its the first text and user wants to add a hashtag
    if (!text) {
      // this is because previous selection for some reason is always the @ List tray when user clicks on it
      insertAtEmptyTextField(item, editableRef?.current as HTMLDivElement)
    } else {
      const sel = document.getSelection() as SelectionClone
      if (sel) {
        sel.setPosition(editableRef?.current, 0)
      }
      insertTextAtCaretPosition(item, prevSelection.current as Selection)
      // settags((prev) => [...prev, { name: item, id: uuidv4() }])
    }

    addTags()
    setopenAtList(false)
  }

  const handleRemoveTag = (tag: string) => {
    const tags: NodeListOf<HTMLSpanElement> =
      document.querySelectorAll(`.${tag.replace(/[\s-]+/g, '')}`) || []

    tags.forEach((t) => {
      t.remove()
    })
    addTags()
  }

  const addTags = () => {
    const inputArea: HTMLElement | null = document.getElementById('inputArea')
    const tags: NodeListOf<HTMLSpanElement> | never[] =
      inputArea?.querySelectorAll('.atTags') || []

    const tagObjects: TagObj[] = []

    tags.forEach(function (t) {
      const userId = t.getAttribute('data-user-id')
      const userType = t.getAttribute('data-user-type')
      const tagObj = {
        name: t.innerText.replace('@', ''),
        id: Number(userId),
        userType,
      }

      // Check if an object with the same "name" property already exists in the array
      const exists = tagObjects.some(
        (existingObj) => existingObj.name.trim() === tagObj.name.trim()
      )
      // console.log(tagObjects)

      if (!exists) {
        tagObjects.push(tagObj as TagObj)
      }
    })

    settags(tagObjects)
  }

  const handleChange = (e: any) => {
    addTags()
  }

  const onFileSelelcted = (file: File, fileUrl: string) => {
    if (file.type === 'video/mp4') {
      createVideo(file, fileUrl)
    } else {
      createImg(file, fileUrl)
    }
  }
  const photoUpload = (
    <Button className='border' startIcon={<FaCamera />} size='xs' color='white'>
      Photo
    </Button>
  )
  const videoUpload = (
    <Button className='border' startIcon={<FaVideo />} size='xs' color='white'>
      Video
    </Button>
  )

  //create post

  const { mutateAsync: createPost, isLoading: createPostLoading } =
    useCreatePost({
      closeModal: () => toggleModal(),
      filesToUpload,
    })

  // create post media
  const { mutateAsync: createPostMedia, isLoading: isCreateMediaLoading } =
    useCreatePostMedia()

  //delete post
  const { mutateAsync: deletePost } = useDeletePost()

  const handdleCreatePost = async () => {
    const { error, response } = await withAsync(() => {
      return createPost({
        content: editableRef?.current?.innerHTML as string,
        mentioned_users: tags
          .filter((tag) => tag.userType === 'user')
          .map((users) => users.id) as number[],
        mentioned_startups: tags
          .filter((tag) => tag.userType === 'startup')
          .map((users) => users.id) as number[],
      })
    })

    if (error) {
      return errorHandler(error)
    }
    if (response) {
      if (filesToUpload?.length) {
        // isUploading = true
        const files = filesToUpload.map((f) => {
          const formData = new FormData()
          formData.append('file', f.file)
          formData.append('post', String(response.data.data.id))
          return createPostMedia(formData)
        })
        const { error: uploadErr, response: uploadResponse } = await withAsync(
          () => {
            return Promise.all(files)
          }
        )
        if (uploadErr) {
          deletePost({ id: response.data.data.id })
          errorHandler(error)
          return
        }

        if (uploadResponse?.length) {
          ToastNotify('success', {
            message: 'Success',
          })
          queryClient.invalidateQueries([POSTS])
          toggleModal()
        }
      } else {
        ToastNotify('success', {
          message: 'Success',
        })
        queryClient.invalidateQueries([POSTS])
        toggleModal()
      }
    }
  }

  return (
    <div className='flex flex-col align-start w-full p-[24px] gap-2 '>
      <Typography heading='sm'>Post on Timeline</Typography>

      <div className='flex w-full flex-col align-start gap-2 rounded-md border border-gray-200 p-2 h-[40vh] overflow-y-auto'>
        <div className='relative'>
          <div
            className='h-auto w-full focus:outline-transparent'
            ref={editableRef}
            onInput={handleChange}
            contentEditable={openAtList ? 'false' : 'true'}
            onKeyDown={handleKeyDown}
            id='inputArea'
            data-placeholder='Start typing...'
          />

          {openAtList && (
            <AtList
              onClose={() => {
                setopenAtList(false)
              }}
              onClickItem={(item: TagObj) => onClickAtItem(item)}
            />
          )}
        </div>

        <div
          id='gridParent'
          className='grid grid-cols-2 gap-1 sm:grid-cols-2 lg:grid-cols-3'
        >
          {imgs.map((img, i) => (
            <React.Fragment key={uuidv4()}>{img}</React.Fragment>
          ))}
        </div>
      </div>

      <div className='w-full flex flex-col justify-between items-end lg:flex-row lg:items-center'>
        <div className='mt-2 flex flex-row gap-2 items-center flex-wrap'>
          <FileUpload
            btnElement={photoUpload}
            onFileSelelcted={onFileSelelcted}
          />
          <FileUpload
            btnElement={videoUpload}
            onFileSelelcted={onFileSelelcted}
            type='video'
          />

          {tagsArray.slice(0, 2).map((tag) => (
            <Chip key={tag.name} className='rounded-md bg-gray-200'>
              {tag.name}
              <FaTimes
                className='bg-[white] rounded cursor-pointer'
                onClick={() => handleRemoveTag(tag.name)}
              />
            </Chip>
          ))}

          {tags.length > 2 ? (
            <Chip className='rounded-md font-bold'>
              <FaPlus className='' />
              {tags.length - 2} more{' '}
              {tags.length - 2 === 1 ? 'person' : 'people'}
            </Chip>
          ) : null}
        </div>
        <Button
          className='w-[110px] px-5 font-bold'
          size='xs'
          onClick={handdleCreatePost}
          disabled={createPostLoading}
          loading={createPostLoading || isCreateMediaLoading}
        >
          Post
        </Button>
      </div>
    </div>
  )
}

export default ModalContainer
