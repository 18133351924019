export const POST_MENTIONS = 'POST_MENTIONS'
export const POSTS = 'POSTS'
export const POST = 'POST'
export const COMMENTS = 'COMMENTS'
export const USERS = 'USERS'
export const USER_POSTS = 'USER_POSTS'
export const RECOMMENDED_INVESTORS = 'RECOMMENDED_INVESTORS'
export const TRENDING_STARTUPS = 'TRENDING_STARTUPS'
export const TIMELINE_SEARCH = 'TIMELINE_SEARCH'
export const CONNECTIONS = 'CONNECTIONS'
export const GET_TIMELINE_ACTIVITIES = 'GET_TIMELINE_ACTIVITIES'
export const PENDING_CONNECTIONS = 'PENDING_CONNECTIONS'
export const STARTUP = 'STARTUP'
export const STARTUP_HIGHLIGHTS = 'STARTUP_HIGHLIGHTS'
export const STARTUPS = 'STARTUPS'
export const INVESTORS = 'INVESTORS'
export const INVESTOR = 'INVESTOR'
export const USER = 'USER'
export const TEAM_MEMBERS = 'TEAM_MEMBERS'
