import { imagesUrl } from '@/assets/images/imageUrls'
import Typography from '@/common/Typography'
import React, { useMemo } from 'react'
import { ReactComponent as FolderIcon } from '@/assets/icons/utils/folder.svg'
import Button from '@/common/button/Button'
import { truncateText } from '@/helpers/ellipsizeTextBox'
import { Pages } from '@/routes/route.constant'
import { useNavigate } from 'react-router'
import { GetDataRoomResDTO } from '@/apis/dataRoomApis'

const text = ` Fly Hands Free|Palm Takeoff | Intelligent Flight Paths | Triple
Stabilization | Enclosed Frame | VIO Fly Hands Free|Palm Takeoff |
Intelligent Flight Paths | Triple Stabilization | Enclosed Frame | VIO Fly Hands Free|Palm Takeoff |
Intelligent Flight Paths | Triple`

function RoomCard({
  id,
  name,
  about_us,
  logo,
  num_folders,
}: GetDataRoomResDTO) {
  const navigate = useNavigate()

  return (
    <div className='w-368px h-[354px] rounded-[8px] border bg-white p-5 gap-4 flex flex-col  justify-between'>
      <div className=' gap-4 flex flex-col items-start justify-start'>
        <img
          src={logo || imagesUrl.blanckCompImg}
          alt='start_up_logo'
          className='h-[135px] w-full object-center object-cover rounded-[8px]'
        />

        <div className='flex justify-between w-full items-center'>
          <Typography label='lg'>{name}</Typography>
          <div className='flex items-center gap-2'>
            <FolderIcon />
            <Typography button='sm'>
              {num_folders} folder{num_folders > 1 ? 's' : null}
            </Typography>
          </div>
        </div>
        <Typography paragraph='sm'>{truncateText(about_us, 24)}</Typography>
      </div>

      <Button
        color='white'
        size='xs'
        className='w-full'
        onClick={() =>
          navigate(`/app/${Pages.DataRoom}/${name}`, {
            state: { startupId: id },
          })
        }
      >
        View Details
      </Button>
    </div>
  )
}

export default RoomCard
