import React from 'react'

import AccesControlItem from './AccessControlItem'
import { GetAccess } from '@/apis/dataRoomApis'

interface ResultsStructure {
  id: number
  user: {
    id: number
    name: string
    about: string
    avatar: string
    user_type: string
    num_connections: number
    num_followers: number
    connected: string
  }
  created: Date
}

interface PostsStructure {
  results: ResultsStructure[]
  count?: number
  next?: string
  prev?: string
}
interface ReturnedData {
  data: { data: PostsStructure }
}

function AccessControlList({ data }: any) {
  return (
    <div className='flex flex-col gap-3 items-start w-full'>
      {data.results.map((item: GetAccess) => (
        <AccesControlItem key={item.id} {...item} />
      ))}
    </div>
  )
}

export default AccessControlList
