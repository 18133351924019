import React from 'react'

import { useLocation, useParams } from 'react-router'

import DropdownButton from '@/common/DropdownButton'
import Post from './components/Post'

import SingleInvestorDesc from './components/SingleInvestorDesc'
import AboutInvestor from './components/AboutInvestor'
import Typography from '@/common/Typography'

import { InfiniteScroll } from './components/utils/types'

import LoadingState from './components/LoadingState'
import PostSkeletonLoader from './components/PostSkeletonLoader'
import { useGetTimelineActivities, useGetUser } from '@/store/networkStore'
import Title from '@/common/Title'
import { useBreadCrumbs } from '@/layouts/app/components/header'

import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'

export interface InvestorsStructure {
  id: number
  email: string
  first_name: string
  last_name: string
  phone_number: string
  user_type: string
  avatar: string
  about: string
  last_login: Date
  date_joined: Date
  email_verified: boolean
  num_connections: number
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
}
type InvestorGroupType = {
  id: number
  name: string
  logo: string
  category: string
} | null
interface ResultsStructure {
  content: string
  created: Date
  id: number
  updated: string
  media: []
  mentioned_startups: []
  mentioned_users: []
  num_comments: number
  num_likes: number
  like: boolean
  user: {
    avatar: string
    name: string
    about: string
    id: number
    connection?:
      | null
      | undefined
      | {
          id: number
          status: 'Pending' | 'Accepted' | 'Declined'
          is_sender: boolean
        }
  }
  investor_group: InvestorGroupType
}
interface ActivitiesResultsStructure {
  action: 'comment' | 'like' | 'post'
  content: ResultsStructure
}

const dropdownItems = [
  {
    name: 'All',
    value: 0,
  },
  {
    name: 'Older Than 6months',
    value: 6,
  },
  {
    name: 'Older Than 3months',
    value: 1,
  },
  {
    name: 'Older Than 1month',
    value: 1,
  },
]

const from = {
  investors: {
    name: 'Investors',
    path: '/app/networks/all-people',
  },
  startups: {
    name: 'Businessess',
    path: '/app/networks/startups',
  },
} as Record<string, any>
function SingleUser() {
  const { username } = useParams()
  const { state } = useLocation()

  const BreadCrumbMap = new Map()
  BreadCrumbMap.set(
    from[state?.from || 'startups']?.path,
    state?.from === 'startups' ? 'Businessess' : state?.from || 'Businessess'
  )
  BreadCrumbMap.set(`/app/networks/user/${state?.id || 0}`, `${username || ''}`)

  BreadCrumbMap.set('excludeCount', 2)

  useBreadCrumbs(BreadCrumbMap)

  const [filter, setfilter] = React.useState<number>(0)

  const onFilter = (value: number | any) => {
    setfilter(value)
  }

  //get user
  const {
    data: investor,
    isLoading: investorLoading,
    isError: isInvestorError,
  } = useGetUser({ id: +state?.id })

  //get activities
  const {
    data: userPosts,
    isLoading: postsLoading,
    isError: isPostError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetTimelineActivities({
    user_id: +state?.id,
    activity_type: 'post',
    period: filter,
  })

  const posts = (userPosts as unknown as InfiniteScroll)?.pages
  const investorData = investor as unknown as InvestorsStructure
  const isPostEmpty = posts?.[0].data?.data?.results?.length < 1

  return (
    <div className=' p-6 w-full'>
      <Title>{`User - ${username}`}</Title>
      <div className='flex flex-col w-full gap-5 sm:w-[70%]'>
        <SingleInvestorDesc {...investorData} />
        <AboutInvestor about={investorData?.about} />

        <div className=' rounded bg-[white] p-4'>
          <div className='flex items-center justify-between w-full'>
            <Typography heading='xs'>Activity</Typography>

            <DropdownButton
              title='All'
              items={dropdownItems}
              btnClass='py-2 border text-xs font-light !min-w-[150px]'
              onClick={onFilter}
              changeTitleOnSelect
            />
          </div>

          {
            //if first load

            <LoadingState
              skeletonLoader={<PostSkeletonLoader />}
              condition={
                postsLoading
                  ? 'isLoading'
                  : isPostEmpty
                  ? 'isEmpty'
                  : isPostError
                  ? 'isError'
                  : ''
              }
              isEmptyDisplay={
                <>
                  <Typography heading='3xs'>No post on timeline</Typography>
                </>
              }
            />
          }
          <InfiniteScrollContainer
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            containerClass='min-h-[2vh]'
          >
            <div className='flex flex-col min-h-[50vh] gap-2 w-full'>
              {posts?.map((page) =>
                page?.data.data?.results?.map(
                  ({ content: post }: ActivitiesResultsStructure) => (
                    <Post
                      key={post.id}
                      id={post.id}
                      name={
                        post.investor_group
                          ? post.investor_group.name
                          : post?.user?.name
                      }
                      title={
                        post.investor_group
                          ? post.investor_group.category
                          : post?.user?.about
                      }
                      userId={
                        post.investor_group
                          ? post.investor_group.id
                          : post?.user?.id
                      }
                      src={
                        post.investor_group
                          ? post.investor_group.logo
                          : post?.user?.avatar
                      }
                      content={post.content}
                      postImgs={post.media}
                      date={post.created}
                      like={post.like}
                      connection={post?.user?.connection}
                      num_likes={post.num_likes}
                      num_comments={post.num_comments}
                      containerClass=' border-b-1 border-t-0 border-l-0 border-r-0 rounded-none m-0  pb-5'
                    />
                  )
                )
              )}
            </div>
          </InfiniteScrollContainer>
        </div>
      </div>
    </div>
  )
}

export default SingleUser
