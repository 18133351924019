export enum Pages {
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  File = 'file',
  Folder = 'folder',
  ChangePassword = 'change-password',
  VerifyAccount = 'verify-account',
  Setup = '',
  JoinTeam = 'join-team',
  TeamPage = 'team',
  Event = 'events',
  StartupProfile = 'startup-profile',
  Assessment = 'assessment',
  AllActivities = 'all-activities',
  Notification = 'notifications',
  Message = 'messages',
  Network = 'networks',
  MyNetwork = 'myNetwork',
  AllPeople = 'all-people',
  Startups = 'startups',
  User = 'user',
  SearchStartups = 'search-startups',
  SearchPeople = 'search-people',
  TeamInfo = 'teaminfo',
  DataRoom = 'data-room',
  Group = 'group',
}
