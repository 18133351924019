import React from 'react'

import { FaCircle, FaHeart, FaRegHeart } from 'react-icons/fa'
import moment from 'moment'
import Button from '@/common/button/Button'
import classNames from 'classnames'

import { COMMENTS, POST, POSTS } from '@/constants/querryKeys'

import Typography from '@/common/Typography'

import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import CircularProgress from '@/common/spinners/CircularProgress'
import Modal, { RefType } from '@/common/Modal'
import { useQueryClient } from 'react-query'
import TextInput from '@/common/TextInput'
import { toast } from 'react-toastify'
import { ReactComponent as EmptyDisplay } from '@/assets/network/Component 3.svg'
import { ReactComponent as ShareIcon } from '@/assets/network/share.svg'
import { ReactComponent as ShareIconHighlighted } from '@/assets/network/share-highlighted.svg'
import { ReactComponent as CommentIcon } from '@/assets/network/green comment.svg'
import { ReactComponent as PrevIcon } from '@/assets/network/prev.svg'
import { ReactComponent as NextIcon } from '@/assets/network/next.svg'
import { numberFormatter } from '@/helpers/numberFormatter'

import {
  useGetComments,
  useGetPost,
  usePostComment,
  useToggleLike,
} from '@/store/networkStore'
import {
  CREATE_DOCUMENT,
  GET_UPDATES,
  GET_UPDATES_COMMENTS,
  SIFUSE_FRONTEND_URL,
} from '@/apis/endpoints'
import LoadingState from '@/pages/theNetwork/components/LoadingState'
import { InfiniteScroll, Ref } from '@/pages/theNetwork/components/utils/types'
import PosterDetails from '@/pages/theNetwork/components/PosterDetails'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import {
  useGetUpdate,
  useGetUpdatesComments,
  usePostUpdatesComments,
  useToggleUpdatesLike,
} from '@/store/dataRoomStore'
import { GetUpdatesResDTO } from '@/apis/dataRoomApis'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'

interface PostStructure {
  id: number
  user: {
    id: number
    name: string
    about: string
    avatar: string
    num_connections: number
  }
  content: string
  mentioned_users: {
    id: number
    name: string
    about: string
    avatar: string
    num_connections: number
  }[]
  mentioned_startups: {
    id: number
    name: string
    logo: string
  }[]
  like: boolean
  num_likes: number
  num_comments: number
  media: {
    id: number
    post: number
    file: string
    file_type: string
  }[]
  created: Date
  updated: Date
}
interface CommentsStructure {
  id: number
  user: {
    id: number
    name: string
    about: string
    avatar: string
    num_connections: number
  }
  content: string
  num_likes: number
  created: Date
  updated: Date
}

interface MediaProps {
  clickedIndx: number
  created: Date
  media: {
    id: number
    update: number
    file: string
    file_type: string
  }[]
}

interface Prop {
  id: number | null | undefined
}

const Carousel = ({ media = [], created, clickedIndx }: MediaProps) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(clickedIndx)

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === media.length - 1 ? 0 : prevIndex + 1
    )
    // setCurrentIndex((prevIndex) => (prevIndex + 1) % media?.length)
  }

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? media.length - 1 : prevIndex - 1
    )
    // setCurrentIndex(
    //   (prevIndex) => (prevIndex - 1 + media?.length) % media?.length
    // )
  }

  return (
    <div className='p-4 flex relative flex-col items-start gap-2 justify-start w-full h-full'>
      <span
        onClick={prevImage}
        className='z-10 cursor-pointer absolute top-[40%] left-10'
      >
        <PrevIcon />
      </span>

      <div
        className='w-full h-full flex relative'
        style={{
          overflow: 'hidden',
        }}
      >
        {media.map((medi, idx) =>
          medi?.file_type === 'video' ? (
            <video
              key={medi.id}
              controls
              controlsList='nodownload nofullscreen noremoteplayback'
              muted
              style={{
                animationFillMode: 'forwards',
              }}
              className={classNames(
                'object-center object-cover w-full h-[95%] rounded-md absolute opacity-0',

                {
                  'animate-fadeIn': idx === currentIndex,
                }
              )}
              src={medi?.file}
            />
          ) : (
            <img
              key={medi.id}
              src={medi?.file}
              alt={`post_img_${medi?.id + 1}`}
              style={{
                animationFillMode: 'forwards',
              }}
              className={classNames(
                'w-full h-[95%] rounded-md absolute opacity-0',
                {
                  'animate-fadeIn': idx === currentIndex,
                }
              )}
            />
          )
        )}
      </div>

      {/* {media?.[currentIndex]?.file_type === 'video' ? (
        <video
          controls
          controlsList='nodownload nofullscreen noremoteplayback'
          muted
          className={`object-center object-cover w-full h-[95%] rounded-md `}
          src={media?.[currentIndex]?.file}
        />
      ) : (
        <img
          src={media?.[currentIndex]?.file}
          alt={`post_img_${currentIndex + 1}`}
          className={`w-full h-[95%] rounded-md `}
        />
      )} */}
      <span
        onClick={nextImage}
        className='cursor-pointer absolute top-[40%] right-10'
      >
        <NextIcon />
      </span>

      <span className='opacity-50 flex gap-2 items-center'>
        <FaCircle fontSize={6} />
        <p> {moment(created).format('hh:mm A MMMM Do, YYYY')}</p>
      </span>
    </div>
  )
}

function SinglePost({ id }: Prop) {
  const bottomRef = React.useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()
  const modalRef1 = React.useRef<RefType>(null)
  const isInBottom = useIntersectionObserver(bottomRef as Ref)
  const [comment, setcomment] = React.useState<string>('')

  const [clickedMedia, setclickedMedia] = React.useState<number>(0)
  const [isTextCopied, setisTextCopied] = React.useState<boolean>(false)

  const toggleModal1 = (clickedIndx: number): void => {
    setclickedMedia(clickedIndx)
    modalRef1?.current?.handleToggle()
  }

  //comment on post
  const { mutateAsync: postComment, isLoading: commentLoading } =
    usePostUpdatesComments()

  //toggle like
  const { mutateAsync: likePost, isLoading: likeLoading } =
    useToggleUpdatesLike()

  //get post with id
  const {
    data: post,
    isLoading: postLoading,
    isError: isPostError,
  } = useGetUpdate({
    id: id as number,
  })

  //get comments
  const {
    data: comments,
    isLoading: commentsLoading,
    isError: isCommentsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetUpdatesComments({ id: id as number })

  const handlePostComment = async () => {
    const { response, error } = await withAsync(() =>
      postComment({ update: id as number, content: comment })
    )

    if (error) return errorHandler(error)
    if (response) {
      setcomment('')
      queryClient.invalidateQueries([CREATE_DOCUMENT + id])
      queryClient.invalidateQueries([GET_UPDATES])
      queryClient.invalidateQueries([
        GET_UPDATES_COMMENTS,
        {
          update: id,
        },
      ])
      ToastNotify('success', {
        message: 'Success',
      })
    }
  }
  const handleToggleLike = async () => {
    const { response, error } = await withAsync(() =>
      likePost({ id: id as number })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([CREATE_DOCUMENT + id])
      queryClient.invalidateQueries([GET_UPDATES])
      ToastNotify('success', {
        message: 'Success',
      })
    }
  }

  const commentsData = comments?.pages

  const iscommentsEmpty = !comments?.pages[0].results?.length
  const commentsCount = comments?.pages[0]?.count || 0

  const postData = post as GetUpdatesResDTO

  return (
    <>
      {
        //if first load
        postLoading || isPostError ? (
          <LoadingState
            condition={postLoading ? 'isLoading' : isPostError ? 'isError' : ''}
          />
        ) : (
          <div
            className={classNames(
              'bg-[white] flex gap-2 flex-col rounded-md px-3 py-3 w-full'
            )}
          >
            <Typography heading='xs'>Post an Update</Typography>
            <div className='flex items-center justify-between'>
              <PosterDetails
                src={post?.user.avatar || ''}
                name={`${post?.user.first_name} ${post?.user.last_name}`}
                userId={post?.user.id as number}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: postData?.content }} />

            {/* img or video grid goes here */}
            <div className='grid grid-cols-1  gap-2 sm:grid-cols-2 '>
              {postData.media.map((media, idx) => (
                <React.Fragment key={media.id}>
                  {media.file_type === 'video' ? (
                    <video
                      controls
                      controlsList='nodownload nofullscreen noremoteplayback'
                      muted
                      className='object-center object-cover w-[374px] h-[200px] cursor-pointer'
                      src={media.file}
                      onClick={() => toggleModal1(idx)}
                    />
                  ) : (
                    <img
                      src={media.file}
                      alt='post_img'
                      className='object-center object-cover w-[374px] h-[200px] cursor-pointer'
                      onClick={() => toggleModal1(idx)}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>

            <span className='opacity-50 flex gap-2 items-center'>
              <FaCircle fontSize={6} />
              <p> {moment(postData.created).format('hh:mm A MMMM Do, YYYY')}</p>
            </span>

            <div className='flex justify-between w-full items-center'>
              <div className='flex items-center gap-2'>
                <Button
                  className='rounded-md px-2 sm:px-6 border-none'
                  startIcon={
                    postData.like ? (
                      <FaHeart
                        className={postData.like ? 'text-red-500' : ''}
                      />
                    ) : (
                      <FaRegHeart />
                    )
                  }
                  size='sm'
                  color='plain'
                  onClick={handleToggleLike}
                  loading={likeLoading}
                >
                  <span
                    className={
                      postData.like
                        ? 'text-red-500 flex items-center justify-center gap-1'
                        : 'flex items-center justify-center gap-1'
                    }
                  >
                    {numberFormatter(postData.num_likes)}
                    <span className='hidden sm:block'>
                      {postData.num_likes > 1 ? 'Likes' : 'Like'}
                    </span>
                  </span>
                </Button>
                <div className='flex items-center gap-1 text-green-600 text-sm font-medium'>
                  <CommentIcon />
                  {numberFormatter(postData.num_comments)}
                  <span className='hidden sm:block'>
                    {postData.num_comments > 1 ? 'Comments' : 'Comment'}
                  </span>
                </div>
              </div>
            </div>
            <Typography heading='2xs' className='mt-2'>
              Comments {`(${numberFormatter(commentsCount)})`}
            </Typography>

            <div className='flex flex-col gap-2 items-start w-full'>
              <TextInput
                onChange={(e) => setcomment(e.target.value)}
                value={comment}
                fieldType='textarea'
                rows='3'
                placeholder='Start typing...'
                className='w-full border-gray-300 rounded-lg bg-black-white-shades-1'
              />
              <Button
                onClick={handlePostComment}
                loading={commentLoading}
                className='self-end'
                size='sm'
              >
                Post comment
              </Button>
            </div>

            {
              //if first load

              <LoadingState
                condition={
                  commentsLoading
                    ? 'isLoading'
                    : iscommentsEmpty
                    ? 'isEmpty'
                    : isCommentsError
                    ? 'isError'
                    : ''
                }
                customEmptyDisplay={
                  <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white]  rounded-md'>
                    <EmptyDisplay />
                    <Typography heading='3xs'>No Comments</Typography>
                  </div>
                }
              />
            }

            <InfiniteScrollContainer
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              containerClass=' !min-h-[20vh]'
            >
              <div className='flex flex-col gap-2'>
                {commentsData?.map((page) =>
                  page?.results?.map((comment: GetUpdatesResDTO) => (
                    <div key={comment.id}>
                      <PosterDetails
                        src={comment.user.avatar}
                        name={`${comment.user.first_name} ${comment.user.last_name}`}
                        userId={comment.user.id}
                      />
                      <p
                        dangerouslySetInnerHTML={{ __html: comment?.content }}
                      />
                      <span className='opacity-50 flex gap-2 items-center'>
                        <FaCircle fontSize={6} />
                        <p>
                          {' '}
                          {moment(comment.created).format(
                            'hh:mm A MMMM Do, YYYY'
                          )}
                        </p>
                      </span>
                    </div>
                  ))
                )}
              </div>
            </InfiniteScrollContainer>
          </div>
        )
      }
      <Modal ref={modalRef1} modalClass='w-[90vw] h-[636px] sm:w-[800px]  pt-2'>
        <Carousel
          media={postData?.media}
          created={postData?.created}
          clickedIndx={clickedMedia}
        />
      </Modal>

      <div ref={bottomRef} />
    </>
  )
}

export default SinglePost
