import useGetCssValue from '@/hooks/useGetCssValue'
import clsx from 'clsx'
import React, { AllHTMLAttributes, forwardRef } from 'react'

interface IProps extends AllHTMLAttributes<HTMLInputElement> {
  error?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  left?: React.ReactNode
  right?: React.ReactNode
  style?: React.CSSProperties
  height?: string
  className?: string
  name?: string
  label?: string
  type?: string
  value?: any
  inputMode?: any
  disabled?: boolean
  readOnly?: boolean
  autocimplete?: string
}
const InputWithAdornment = forwardRef<HTMLInputElement, IProps>(
  (
    {
      left,
      right,
      placeholder,
      className,
      height,
      name,
      error,
      style,
      label,

      value: passedValue,
      ...rest
    },
    ref
  ) => {
    const [leftContainer, leftWidth] = useGetCssValue('width', '16px', left)
    const [rightContainer, rightWidth] = useGetCssValue('width', '16px', right)

    const extraWidth =
      (left ? parseFloat(leftWidth) : 0) + (right ? parseFloat(rightWidth) : 0)
    return (
      <div className='flex-1 flex flex-col gap-1 z-0 w-full h-full'>
        <div
          className={clsx(
            'w-full [&:has(input:disabled)]:bg-sifuse-shades-600 bg-sifuse-shades-75 h-[52px] border px-4 transition-[height,_border-color] duration-[500,_200] align-middle flex items-center relative py-2 gap-[10px] rounded-lg border-sifuse-shades-200 focus-within:border-sifuse-shades-400 ',
            error ? ' !border-[var(--mainred)] border-solid  ' : ''
          )}
          style={{
            ...(style ? style : {}),
          }}
        >
          {left && (
            <span
              ref={leftContainer}
              className='p-0 text-center flex items-center'
            >
              {left}
            </span>
          )}
          <input
            placeholder={placeholder}
            ref={ref}
            name={name}
            style={{
              minWidth: `calc(100% - ${extraWidth + 16}px)`,
            }}
            value={passedValue}
            {...rest}
            className={clsx(
              'w-full border-none focus:!shadow-none focus:!ring-none focus:ring-0 focus:!bg-transparent active:!bg-transparent  focus:border-none border-transparent font-normal  text-base  outline-0 flex-1 bg-transparent [&:disabled_~_legend]:text-sifuse-shades-200 text-sifuse-shades-800 placeholder-sifuse-shades-600',
              className && className
            )}
          />
          {right && (
            <span
              className='p-0 text-center flex items-center'
              ref={rightContainer}
            >
              {right}
            </span>
          )}
        </div>
        <small className='text-[var(--mainred)] z-10'>{error}</small>
      </div>
    )
  }
)
InputWithAdornment.displayName = 'InputWithAdornment'
export default InputWithAdornment
