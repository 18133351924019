import api from './api'
import { ServerRes } from './api.types'
import {
  ACCEPT_INVITE,
  CREATE_STARTUP,
  FORGOT_PASSWORD,
  LOGOUT,
  REFRESH,
  REGISTER_DEVICE,
  RESEND_VERIFICATION_LINK,
  RESET_PASSWORD,
  SIGNIN,
  SIGNUP,
  VERIFY_ACCOUNT,
} from './endpoints'

export type PersonalInfoReqDTO = {
  email: string
  password?: string
  first_name: string
  last_name: string
  phone_number: string
  about?: string
  user_type?: 'Startup'
  gender?: 'Male' | 'Female'
}

export type StartupInfoReqDTO = {
  name: string
  country: string
  industry: string
  is_registered: boolean
  about_us: string
  website: string
}

export type AuthUser = PersonalInfoReqDTO & {
  email_verified: boolean
  date_joined: string
  last_login: string
  startup: number
  access?: string
  id?: number
  avatar?: string
  num_connections?: number
  is_founder: boolean
}

export type VerifyReqDTO = {
  token: string
  uid: string
}

export type ResetPasswordReqDTO = {
  email: string
  password: string
  token: string
  uid: string
}
export type SigninReqDTO = {
  email: string
  password: string
  user_type?: 'Startup'
}

export type RefreshReqDTO = {
  access: string
  refresh_token: string
}

export type Tokens = {
  access: string
  refresh_token?: string
}
export type Device = {
  registration_id: string
  p256dh: string
  browser?: string
}
export const AcceptInvite = async (
  data: PersonalInfoReqDTO & { token: string }
) => {
  return await api.post<ServerRes<AuthUser>>(ACCEPT_INVITE, data)
}

export const CreateAccount = async (data: PersonalInfoReqDTO) => {
  return await api.post<ServerRes<AuthUser>>(SIGNUP, {
    ...data,
    user_type: 'Startup',
  })
}

export const CreateStartup = async (
  data: StartupInfoReqDTO & { access: string }
) => {
  const { access, ...rest } = data
  return await api.post<ServerRes<AuthUser>>(CREATE_STARTUP, rest, {
    headers: { Authorization: 'Bearer ' + access },
  })
}

export const VerifyAccount = async (data: VerifyReqDTO) => {
  return await api.post<ServerRes<AuthUser>>(VERIFY_ACCOUNT, data)
}

export const ResendVerificationLink = async (
  data: Pick<VerifyReqDTO, 'uid'>
) => {
  return await api.post<ServerRes<AuthUser>>(RESEND_VERIFICATION_LINK, data)
}

export const Signin = async (data: SigninReqDTO) => {
  return await api.post<ServerRes<AuthUser>>(SIGNIN, {
    ...data,
    user_type: 'Startup',
  })
}

export const ForgotPassword = async (data: { email: string }) => {
  return await api.post<ServerRes<{ email: string }>>(FORGOT_PASSWORD, data)
}

export const ResetPassword = async (payload: ResetPasswordReqDTO) => {
  return await api.post<ServerRes<ResetPasswordReqDTO>>(RESET_PASSWORD, payload)
}

export const Logout = async () => {
  return await api.post<ServerRes<PersonalInfoReqDTO>>(LOGOUT, {})
}
export const getAccessToken = async () => {
  return await api.get<ServerRes<Tokens>>(REFRESH, {
    withCredentials: true,
  })
}

export const registerDevice = async (sub: Device) => {
  return await api.post<ServerRes<any>>(REGISTER_DEVICE, sub)
}
