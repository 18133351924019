export const currencyFormatter = (number: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  })
  return formatter.format(number)
}
export const numberFormatter = (number: number, approx: number = 3) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumSignificantDigits: approx,
  })
  return formatter.format(number)
}

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max)
}

export const formatFileSize = (bytes: number, decimals = 2) => {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024
  }

  return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}
