import React from 'react'
import { ReactComponent as EmptyDisplay } from '@/assets/dataRoom/accessEmptyDisplay.svg'
import Typography from '@/common/Typography'

function NoAccessGranted({ isSearch }: { isSearch: boolean }) {
  return (
    <div className='flex flex-col gap-3 items-center justify-center w-full  min-h-[55vh] rounded-md'>
      <EmptyDisplay />
      {!isSearch ? (
        <>
          <Typography heading='xl'>No access granted yet</Typography>
          <Typography
            button='md'
            className='w-[350px] sm:w-[400px] text-center !text-black-white-shades-4'
          >
            Lorem ipsum dolor sit amet consectetur. Sed duis dictum arcu nec
            venenatis sodales convallis.
          </Typography>
        </>
      ) : (
        <Typography heading='xs'>No result found for this query</Typography>
      )}
    </div>
  )
}

export default NoAccessGranted
