import classNames from 'classnames'
import React, { useEffect, useState, useMemo } from 'react'
interface Prop {
  children: JSX.Element
  open: boolean
  endAnimationClass: string
  startAnimationClass: string
  containerClass: string
}
function AnimateUnmounting({
  children,
  open,
  endAnimationClass,
  startAnimationClass,
  containerClass,
}: Prop) {
  const [shouldRender, setshouldRender] = useState(open)

  useEffect(() => {
    if (open) setshouldRender(true)
  }, [open])

  let view: null | JSX.Element = null

  const handleAnimationEnd = () => {
    if (!open) setshouldRender(false)
  }

  if (shouldRender) {
    view = (
      <div
        style={{
          animationFillMode: 'forwards',
          zIndex: 999999!,
        }}
        className={classNames(
          containerClass,
          {
            [startAnimationClass]: open,
          },
          {
            [endAnimationClass]: !open,
          }
        )}
        onAnimationEnd={handleAnimationEnd}
      >
        {children}
      </div>
    )
  }
  return view
}

export default AnimateUnmounting
