import React from 'react'
import { ReactComponent as EmptyDisplay } from '@/assets/dataRoom/fileEmptyDisplay.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'

function FileEmptyComponent() {
  return (
    <div className='flex flex-col gap-3 items-center justify-center w-full  min-h-[55vh] rounded-md'>
      <EmptyDisplay />
      <Typography heading='xl'>No files to display</Typography>
      <Typography
        button='md'
        className='w-[350px] sm:w-[400px] text-center !text-black-white-shades-4'
      >
        Have all your files and docs properly set up in one place for your
        connected Investors to see, and carry out due diligence without hassle.
      </Typography>
    </div>
  )
}

export default FileEmptyComponent
