import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@/common/tabs'

import LoadingState from '@/pages/theNetwork/components/LoadingState'

import clsx from 'clsx'

import React, { FC, ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router'

import { Funding } from '@/apis/startupApis'
import { currencyFormat } from '@/helpers/strings'
import Progress from '@/common/Input/Progress'
const FundsConfiguration = ({ data }: { data: Funding }) => {
  return (
    <section className='w-full grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4'>
      <Configuration
        label='Number of investors on capital table'
        value={data.investors}
      />
      <Configuration
        label='Valuation'
        value={`$${currencyFormat(data.valuation || 0)
          .toString()
          .replace(/\.\d*$/, '')}`}
      />
      <Configuration
        label='Min Investment Amount'
        value={`$${currencyFormat(data.min_investment || 0)}`}
      />
      <Configuration
        label='Price/Share'
        value={`$${currencyFormat(data.share_price || 0)}`}
      />
      <Configuration label='Shares Offered' value={data.shares_offered} />
      <Configuration label='Investment Instrument' value={data.offering_type} />
    </section>
  )
}

const Configuration = (props: Partial<DataItemProps> & { label: string }) => {
  const { className, value, label } = props
  return (
    <hgroup
      className={clsx(
        'flex flex-col justify-center items-start gap-1 flex-1 py-2 px-4 rounded-lg bg-sifuse-shades-100 ',
        className
      )}
    >
      <Typography as='h6' label='xs' color='#545c5c' className='capitalize'>
        {label}
      </Typography>
      <Typography as='p' heading='3xs' color='var(--shades900)'>
        {value || '-'}
      </Typography>
    </hgroup>
  )
}
const FundsRaised = ({
  raised_amount,
  target_amount,
}: {
  raised_amount: number
  target_amount: number
}) => {
  const { state } = useLocation()

  return (
    <section className='w-full flex flex-col items-start gap-2'>
      <div className='w-full flex justify-between'>
        <DataItem
          className='flex-col-reverse'
          label='Raised Amount'
          value={
            <Typography label='lg'>
              $
              {`${currencyFormat(raised_amount || 0)
                .toString()
                .replace(/\.\d*$/, '')}`}
            </Typography>
          }
        />
        <DataItem
          className='flex-col-reverse items-end'
          label='Target Amount'
          value={
            <Typography label='lg' color='#008F5D' className='text-end'>
              $
              {`${currencyFormat(target_amount || 0)
                .toString()
                .replace(/\.\d*$/, '')}`}
            </Typography>
          }
        />
      </div>
      <Progress
        value={raised_amount || 0}
        max={target_amount || 1}
        showPercent={false}
        thumbColor='#EBC345'
        trackColor='#F3F3F8'
        className='!h-1'
      />
    </section>
  )
}
type DataItemProps = {
  label: string
  value: ReactNode
  className?: string
}
const DataItem: FC<DataItemProps> = (props) => {
  return (
    <hgroup className={clsx('flex flex-col items-start', props.className)}>
      <Typography
        as='h6'
        className='!text-sifuse-shades-500 text-sm leading-[22px] font-normal'
      >
        {props.label}
      </Typography>
      <Typography
        paragraph='md'
        color='var(--shades800)'
        className=' font-normal'
      >
        {props.value}
      </Typography>
    </hgroup>
  )
}

const TabBody = ({ data }: { data: Funding }) => {
  return (
    <section className='flex flex-col items-start gap-1'>
      <main className='flex flex-col gap-4 w-full'>
        <FundsRaised
          raised_amount={data.raised_amount}
          target_amount={data.target_amount}
        />
        <FundsConfiguration data={data} />
      </main>
    </section>
  )
}

function FundingInfo({
  data,
  isLoading,
  isError,
}: {
  data: Funding[]
  isError: boolean
  isLoading: boolean
}) {
  const [selectedTab, setSelectedTab] = React.useState<string>()

  useMemo(() => {
    if (data?.length) setSelectedTab(data[0]?.funding_round)
  }, [data])

  const isEmpty = data && !data?.length
  return (
    <div className='bg-white rounded-md p-4 flex flex-col gap-4 w-full'>
      <span className='w-full flex items-center justify-between'>
        <Typography heading='xs'>Funding Info</Typography>
      </span>

      <LoadingState
        condition={
          isEmpty
            ? 'isEmpty'
            : isLoading
            ? 'isLoading'
            : isError
            ? 'isError'
            : ''
        }
        isEmptyDisplay='No Data'
      />
      {!isEmpty && !isLoading && !isError && (
        <div>
          <Tabs
            onChange={(e: any) => {
              setSelectedTab(e.currentTarget.value)
            }}
            value={selectedTab}
            className='w-full gap-2 flex flex-col'
          >
            <TabList className='flex gap-3 p-2 items-center w-full overflow-x-auto scroller max-w-[90vw]'>
              {data.map((tab) => (
                <Tab
                  as='span'
                  key={tab.funding_round}
                  value={tab.funding_round}
                  className=''
                >
                  <Button
                    className={clsx(
                      'flex-1 text-center w-[120px] !rounded-none',
                      selectedTab === tab.funding_round
                        ? 'border-b-2 border-gray-900'
                        : 'text-sm leading-5 !font-normal !border-none !text-black-white-shades-3'
                    )}
                    size='md'
                    color={'plain'}
                  >
                    {tab.funding_round}
                  </Button>
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              {data.map((detail, idx) => (
                <TabPanel key={detail.id} value={detail.funding_round}>
                  <TabBody data={detail as Funding} />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>
      )}
    </div>
  )
}

export default FundingInfo
