import React from 'react'

import classNames from 'classnames'
const defautItems = [
  {
    name: 'Timeline',
    path: '/app/networks',
  },
  {
    name: 'Businesses',
    path: '/app/networks/startups',
  },
  {
    name: 'Investors',
    path: '/app/networks/all-people',
  },
  {
    name: 'My Network',
    path: '/app/networks/mynetwork',
  },
]

type itemsObj = {
  name: string
  path: string
}

interface Props {
  items?: itemsObj[]
  containerClass?: string
  activeColor?: string
  activeProperty?: string
  forceToBeActive?: string
  onClick: (path: string) => void
}
const clickedColor = 'bg-primary'
function ButtonTab({
  items = defautItems,
  activeColor = clickedColor,
  activeProperty,
  onClick,
  containerClass,
  forceToBeActive = '',
}: Props) {
  return (
    <div className='max-w-[100%] overflow-auto'>
      <div
        className={classNames(
          'flex gap-2 p-[8px] mt-4 mb-4 bg-[white] rounded w-fit border',
          containerClass
        )}
      >
        {items.map((item) => (
          <span
            key={item.name}
            onClick={() => onClick(item.path)}
            className={classNames(
              'px-[8px] py-[12px] w-[114px] flex items-center justify-center h-[36px] text-[14px] cursor-pointer border-l border-r border-transparent text-[0.7rem] sm:text-base hover:border-gray-200 ',
              {
                [activeColor]: activeProperty
                  ? activeProperty === item.path
                  : item.path.includes(forceToBeActive),
                'rounded-[8px] !border !border-[#000014] font-bold  hover:border-transparent':
                  activeProperty
                    ? activeProperty === item.path
                    : item.path.includes(forceToBeActive),
              }
            )}
          >
            {item.name}
          </span>
        ))}
      </div>
    </div>
  )
}

export default ButtonTab
