import React, { useRef, RefObject } from 'react'

import TextInput from '@/common/TextInput'

import Button from '@/common/button/Button'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'

import { useLocation, useNavigate } from 'react-router'

import { FaUpRightAndDownLeftFromCenter, FaX } from 'react-icons/fa6'

import { POSTS } from '@/constants/querryKeys'

import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import Typography from '@/common/Typography'

import Title from '@/common/Title'
import {
  useGetRecommendedInvestors,
  useSearchTimeline,
} from '@/store/networkStore'
import { ReactComponent as EmptyDisplay } from '@/assets/network/Component 3.svg'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { WEB_SOCKET_POST } from '@/apis/endpoints'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { useQueryClient } from 'react-query'

import useQueryParams from '@/hooks/useQueryParams'
import Modal, { RefType } from '@/common/Modal'

import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'

import NewPostNotification from '@/pages/theNetwork/components/NewPostNotification'
import LoadingState from '@/pages/theNetwork/components/LoadingState'
import PostSkeletonLoader from '@/pages/theNetwork/components/PostSkeletonLoader'
import ListView from '@/pages/theNetwork/components/ListView'

import Post from '../components/updatesTab/Post'
import UpdatesEmptyComponent from '../components/updatesTab/UpdatesEmptyState'
import { useGetAccessList, useGetUpdates } from '@/store/dataRoomStore'
import { GetAccess, GetUpdatesResDTO } from '@/apis/dataRoomApis'
import ModalContainer from '../components/updatesTab/CreateUpdate'
import AccessList from '../components/AccessList'
import { PaginatedData } from '@/apis/api.types'
//https://loremflickr.com/320/240

interface TimelineSearchData {
  startups: {
    count: number
    startups: any[]
  }
  users: { count: number; users: any[] }
  posts: { count: number; posts: any[] }
}

function UpdatesTab() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const modalRef = useRef<RefType>(null)

  const parentRef = useRef<HTMLDivElement | null>(null)

  const [updates, setupdates] = React.useState<string>('')
  const [search, setsearch] = React.useState<string>('')

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  //get posts
  const {
    data: apiPosts,
    isLoading: postsLoading,
    isError: isPostError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetUpdates({ query: updates, startup: state.startupId })

  //get access lists
  const {
    data: apiAccessList,
    isLoading: AccessListLoading,
    isError: isAccessListError,
    fetchNextPage: accessListfetchNextPage,
    hasNextPage: accessListhasNextPage,
    isFetchingNextPage: accessListIsFetchingNextPage,
  } = useGetAccessList({ query: search, startup: state.startupId })

  const posts = apiPosts?.pages
  const isPostEmpty = !apiPosts?.pages[0].results?.length

  const accessList = apiAccessList?.pages
  const isAccessListEmpty = !apiAccessList?.pages[0].results?.length
  // const isAccessListEmpty = true

  return (
    <div ref={parentRef}>
      <Title>The Timeline</Title>

      {
        <div className='relative'>
          <div className='absolute w-full top-[-58px]  sm:right-[9.5%] sm:top-[-20px] sm:w-[90%] sm:justify-between md:top-[-57px] lg:right-0 lg:top-[-85px] lg:w-auto'>
            <TextInput
              placeholder='Search updates'
              name='updates'
              value={updates}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setupdates(e.target.value)
              }
              startIcon={<SearchIcon />}
              endIcon={
                !!updates && (
                  <FaX
                    className='text-[0.7rem]'
                    onClick={() => setupdates('')}
                  />
                )
              }
              className='w-full border border-gray-200 sm:w-[364px] sm:h-[48px]'
            />
          </div>
        </div>
      }

      <div className='flex flex-col justify-start gap-2  mt-3 width-full sm:flex-row  md:mt-10 lg:mt-0'>
        {/* timeline */}

        {/* Empty state */}
        {!postsLoading && isPostEmpty && !updates && (
          <div className='flex-col flex gap-4 w-full sm:w-[70%]'>
            <UpdatesEmptyComponent startupId={state.startupId} />
          </div>
        )}
        {!postsLoading && isPostEmpty && updates && (
          <div className='flex-col flex gap-4 w-full sm:w-[70%]'>
            <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[72vh] rounded-md'>
              <EmptyDisplay />
              <Typography heading='2xs'>
                No updates found for this query
              </Typography>
            </div>
          </div>
        )}
        {postsLoading && (
          <div className='flex-col flex gap-4 w-full sm:w-[70%]'>
            <PostSkeletonLoader />
          </div>
        )}
        {isPostError && isPostEmpty && (
          <div className='flex-col flex gap-4 w-full sm:w-[70%]'>
            <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[72vh] rounded-md'>
              <EmptyDisplay />
              <Typography label='xs' className='!text-red-500'>
                Sorry, Something went wrong
              </Typography>
            </div>
          </div>
        )}

        {/* updates */}
        {!isPostEmpty && (
          <div className='flex-col flex gap-4 w-full sm:w-[70%]'>
            <div className='bg-[white] flex flex-col rounded-md gap-2 p-[24px] w-full border border-[#E6E6EB]'>
              <Typography heading='sm'> Updates</Typography>

              <Button
                onClick={toggleModal}
                endIcon={<FaUpRightAndDownLeftFromCenter />}
                startIcon={<small> Start typing...</small>}
                color='lightGrey'
                className='w-full items-start justify-start flex text-gray-400 mb-2 border border-gray-200 hover:shadow-sm'
              />
            </div>
            <div className=' flex-col flex gap-2'>
              <InfiniteScrollContainer
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                containerClass='min-h-[60vh] sm:min-h-[40vh]'
              >
                <div className='flex flex-col gap-2'>
                  {posts?.map((page) =>
                    page?.results?.map((post: GetUpdatesResDTO) => (
                      <Post
                        key={post.id}
                        id={post.id}
                        userId={post?.user?.id}
                        name={`${post?.user?.first_name} ${post?.user?.last_name}`}
                        content={post.content}
                        src={post?.user?.avatar || ''}
                        postImgs={post.media}
                        date={post.created}
                        like={post.like}
                        showConnectBtn={false}
                        num_likes={post.num_likes}
                        num_comments={post.num_comments}
                      />
                    ))
                  )}
                </div>
              </InfiniteScrollContainer>
            </div>
          </div>
        )}
        {/* Investors with access */}
        <div className='flex flex-col gap-4 w-full sm:w-[30%]'>
          <AccessList
            isFetchingNextPage={accessListIsFetchingNextPage}
            isEmpty={isAccessListEmpty}
            fetchNextPage={accessListfetchNextPage}
            hasNextPage={accessListhasNextPage as boolean}
            search={search}
            setsearch={setsearch}
            title='Investors with Access'
            onClickItem={(id: any, name: any) =>
              navigate(`/app/networks/user/${name}`, {
                state: { id, from: 'investors' },
              })
            }
            data={accessList as PaginatedData<GetAccess>[]}
          />
        </div>
      </div>

      {/* Create post modal */}
      <Modal
        ref={modalRef}
        modalClass='w-[90vw] sm:w-[800px] min-h-[50vh] pt-2'
        showCloseBtn
        enableWarning
      >
        <ModalContainer
          toggleModal={toggleModal}
          startupId={state.startupId}
          showTitle
        />
      </Modal>
    </div>
  )
}

export default UpdatesTab
