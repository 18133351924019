import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'
import React from 'react'

import { useNavigate } from 'react-router'
import { ReactComponent as RemoveIcon } from '@/assets/icons/utils/remove.svg'
import Typography from '@/common/Typography'

import Modal, { RefType } from '@/common/Modal'
import { truncateText } from '@/helpers/ellipsizeTextBox'
import { GetAccess } from '@/apis/dataRoomApis'
import { useDeleteAccessReq } from '@/store/dataRoomStore'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { useQueryClient } from 'react-query'
import { GET_ACCESS_LIST } from '@/apis/endpoints'
import clsx from 'clsx'

function AccesControlItem({ id, user, investor_group }: GetAccess) {
  const modalRef = React.useRef<RefType>(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  // delete access
  const { mutateAsync: deleteAccess, isLoading: deleteAccessLoading } =
    useDeleteAccessReq()

  // console.log(user)
  const handleDelete = async () => {
    const { response, error } = await withAsync(() =>
      deleteAccess({ id: id as number })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([GET_ACCESS_LIST])
      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal()
    }
  }
  const name = investor_group
    ? investor_group?.name
    : `${user.first_name} ${user.last_name}`
  const avatar = investor_group ? investor_group?.logo : user?.avatar

  return (
    <main className='w-full'>
      <div className='flex flex-col gap-3 w-full'>
        <div className='flex flex-col gap-2 items-start w-full sm:flex-row sm:justify-between sm:items-center'>
          <div className='flex gap-2'>
            <img
              src={avatar || imagesUrl.blanckProfileImg}
              alt='start_up_logo'
              className={clsx(
                'h-[80px] w-[80px] min-h-[80px] min-w-[80px] object-center object-cover rounded-full',
                {
                  '!rounded-md': !!investor_group,
                }
              )}
            />

            <div className='flex flex-col gap-1 px-2 sm:px-3'>
              <div
                className='flex flex-col items:start justify-center cursor-pointer'
                onClick={() =>
                  navigate(
                    `/app/networks/${
                      investor_group ? 'group' : 'user'
                    }/${name}`,
                    {
                      state: {
                        id: investor_group ? investor_group.id : user.id,
                        from: 'investors',
                      },
                    }
                  )
                }
              >
                <Typography heading='3xs' className='capitalize'>
                  {name}
                </Typography>

                <small className={`font-light`}>
                  {truncateText(
                    (investor_group ? investor_group.category : user?.about) ||
                      '',
                    10
                  )}
                </small>
              </div>
              {!investor_group && (
                <div className='flex items-center gap-2 justify-between w-full'>
                  <span className='flex gap-2'>
                    <small>
                      {' '}
                      <strong>{user.num_connections || 0}</strong>
                    </small>
                    <small>
                      Connection
                      {user?.num_connections && user?.num_connections > 1
                        ? 's'
                        : null}
                    </small>
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className='flex gap-2 mt-0 sm:gap-4'>
            <Button
              className='rounded px-4 border '
              startIcon={<RemoveIcon />}
              size='md'
              color='white'
              onClick={toggleModal}
            >
              Remove
            </Button>
          </div>
        </div>
        <hr className='bg-gray-200' />
      </div>
      <Modal
        ref={modalRef}
        enableWarning={false}
        modalClass='w-[373px] pt-0  flex flex-col items-center justify-center'
      >
        <div className='flex flex-col items-center justify-center gap-4'>
          <Typography heading='xs'>Are you sure?</Typography>
          <span className='flex gap-1'>
            <Typography label='sm' className='!font-normal'>
              Remove
            </Typography>
            <Typography
              label='sm'
              className='!font-medium'
            >{`${name}`}</Typography>
            <Typography label='sm' className='!font-normal'>
              from having access
            </Typography>
          </span>

          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              onClick={handleDelete}
              loading={deleteAccessLoading}
            >
              Yes, I’m Sure
            </Button>
            <Button color='white' className='w-[124px]' onClick={toggleModal}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default AccesControlItem
