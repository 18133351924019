import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import React from 'react'
import { FaCheck, FaEye, FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { StartUpStructure } from '../Startups'
import { imagesUrl } from '@/assets/images/imageUrls'
import { useCustomMutation } from '@/hooks/react-query/mutate/useMutateFunc'
import { toast } from 'react-toastify'

interface Props {
  id: number
  img: string
  name: string
  tags: string[]
  desc: string
  onClickFollow?: (id: string) => void
  onClickView?: (item: Props) => void
}

function StartupCard({
  id,
  country,
  name,
  members,
  logo,
  industry,
  product_type,
  is_registered,
  about_us,
  website,
  mission,
  vision,
  bio_video,
  created,
  updated,
}: Partial<StartUpStructure>) {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col gap-3 rounded-lg bg-[white] h-[410px] flex-1 justify-between'>
      <img
        src={logo || imagesUrl.blanckCompImg}
        alt='start_up_logo'
        className='w-full h-[183px] object-center object-cover rounded-t-lg'
      />
      <div className='flex flex-col gap-2 px-5 py-2'>
        <div className='flex gap-2'>
          <small
            className={`bg-blue-300 font-bold rounded p-1 uppercase text-black-1`}
          >
            {industry}
          </small>
        </div>
        <Typography heading='xs'>{name}</Typography>

        <small>{about_us}</small>
      </div>
      <div className='flex items-center justify-between w-full mt-4 px-5 pb-3 gap-2'>
        {/* <Button
          className='rounded px-6 border w-full border-primary'
          size='sm'
          color='plain'
        >
          <span className='flex gap-2 items-center justify-center'>
            <FaPlus /> Connect
          </span>
        </Button> */}
        <Button
          className='rounded px-6 border w-full'
          onClick={() => navigate(`/app/networks/startups/${id}`)}
          size='sm'
          color='white'
        >
          <span className='flex gap-2 items-center justify-center'>
            <FaEye /> View
          </span>
        </Button>
      </div>
    </div>
  )
}

export default StartupCard
