import TextInput from '@/common/TextInput'
import React, { FormEvent, MouseEvent, useState } from 'react'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'
import DropdownButton from '@/common/DropdownButton'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import RoomCard from './components/RoomCard'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import Title from '@/common/Title'
import { Pages } from '@/routes/route.constant'
import { useGetDataRoom } from '@/store/dataRoomStore'
import { GetDataRoomResDTO } from '@/apis/dataRoomApis'
import { PaginationReq } from '@/apis/api.types'
import LoadingState from '../theNetwork/components/LoadingState'
import { FaX } from 'react-icons/fa6'
import Typography from '@/common/Typography'

const filterBy = [
  {
    name: 'None',
    value: '',
  },

  {
    name: 'Investor',
    value: 'investor',
  },
  {
    name: 'Business',
    value: 'startup',
  },
]

function DataRoom() {
  const BreadCrumbMap = new Map()
  BreadCrumbMap.set(`/app/${Pages.DataRoom}`, 'Data Room')
  useBreadCrumbs(BreadCrumbMap)
  const [search, setsearch] = useState<string>('')

  const handleSearch = (): void => {}

  const {
    isLoading: isDataRoomLoading,
    data: dataRoomData,
    isError: isDataRoomError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetDataRoom({ query: search })
  const isEmpty = !(dataRoomData?.pages[0] as unknown as [])?.length

  return (
    <div className='flex w-full flex-col gap-5'>
      <Title>Data Room</Title>
      <div className='w-full flex gap-2 flex-row justify-end items-center'>
        <div>
          {' '}
          <TextInput
            placeholder='Search'
            name='search'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setsearch(e.target.value)
            }
            onSubmit={handleSearch}
            startIcon={<SearchIcon />}
            className='w-full border border-gray-200 sm:w-[300px] sm:h-[48px]'
            endIcon={
              !!search && (
                <FaX className='text-[0.7rem]' onClick={() => setsearch('')} />
              )
            }
          />
        </div>
        {/* <DropdownButton
          title='Filter by'
          items={filterBy}
          btnClass='py-1 border text-xs font-light !min-w-[110px] h-[48px]'
          onClick={onFilter}
          changeTitleOnSelect
        /> */}
      </div>

      {/* if first load */}

      <LoadingState
        condition={
          isDataRoomLoading
            ? 'isLoading'
            : isEmpty
            ? 'isEmpty'
            : isDataRoomError
            ? 'isError'
            : ''
        }
        isEmptyDisplay={
          <>
            {search ? (
              <Typography heading='xs'>No Businesses for this query</Typography>
            ) : (
              <>
                {' '}
                <Typography heading='xs'>
                  No Businesses in the Data Room
                </Typography>
                <Typography heading='3xs'>
                  You dont have any business to explore in a data room
                </Typography>
              </>
            )}
          </>
        }
      />

      <InfiniteScrollContainer
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      >
        <div className='grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3 '>
          {dataRoomData?.pages.map((page: any) =>
            page.map((data: GetDataRoomResDTO) => (
              <RoomCard key={data.id} {...data} />
            ))
          )}
        </div>
      </InfiniteScrollContainer>
    </div>
  )
}

export default DataRoom
