import { useAuthContext } from '@/contexts/AuthContextProvider'
import { useState, useCallback } from 'react'
const useDownload = () => {
  const { getAccessToken } = useAuthContext()
  const token = getAccessToken()

  const [isDownloading, setIsDownloading] = useState(false)
  const [doneDownloading, setDoneDownloading] = useState(false)
  const exportHandler = useCallback(
    async (
      endpoint: string,
      options: { file_type?: string; file_name?: string }
    ) => {
      setIsDownloading(true)
      setDoneDownloading(false)
      fetch(endpoint, {
        method: 'GET',
        headers: {
          authorization: token ? `Bearer ${token || ''}` : '',
          'Content-Type': 'application/json',
          // "accept": "text/plain"
        },
        // credentials: 'include',
      })
        .then((res) => {
          return res.blob()
        })
        .then((str) => {
          var url = window.URL || window.webkitURL
          var link = url.createObjectURL(str)
          var a = document.createElement('a')
          a.setAttribute(
            'download',
            `${options.file_name || 'downloaded at' + new Date().getTime()}${
              options.file_type ? '.' + options.file_type : ''
            }`
          )
          a.setAttribute('href', link)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(link)
          setIsDownloading(false)
          setDoneDownloading(true)
        })
        .catch((err) => {
          setIsDownloading(false)
          setDoneDownloading(false)
        })
    },
    []
  )
  return {
    exportHandler,
    isDownloading,
    doneDownloading,
  }
}
export default useDownload
