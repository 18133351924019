import React, { useRef } from 'react'

import DropdownButton from '@/common/DropdownButton'

import TextInput from '@/common/TextInput'
import { FaSearch } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import InvestorCard from './components/InvestorCard'
import ButtonTab from './components/ButtonTab'
import StartupCard from './components/StartupCard'
import { useCustomInfiniteQuery } from '@/hooks/react-query/query/useQuery'
import { POSTS, STARTUPS } from '@/constants/querryKeys'
import useIntersectionObserver from '@/hooks/useIntersectionObserver'
import { InfiniteScroll, Ref } from './components/utils/types'
import Typography from '@/common/Typography'
import LoadingState from './components/LoadingState'
import CircularProgress from '@/common/spinners/CircularProgress'
import Post from './components/Post'
import PostSkeletonLoader from './components/PostSkeletonLoader'
import { useGetPosts, useGetStartups } from '@/store/networkStore'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import Title from '@/common/Title'
import { FaX } from 'react-icons/fa6'

export interface StartUpStructure {
  id: number
  country: string
  members: {
    id: number
    connection: null | {
      id: number
      status: 'Pending' | 'Accepted' | 'Declined'
      is_sender: boolean
    }
    email: string
    first_name: string
    last_name: string
    phone_number: string
    user_type: 'Startup' | 'Investor' | 'Admin'
    email_verified: boolean
    date_joined: Date
    last_login: Date
    about: string
    avatar: string
  }[]

  name: string
  logo: string
  industry:
    | 'Technology'
    | 'Education'
    | 'Health Services'
    | 'Financial Services'
    | 'Forestry'
    | 'Mining'
  product_type: string
  is_registered: boolean
  about_us: string
  website: string
  mission: string
  vision: string
  bio_video: string
  created: Date
  updated: Date
}
interface PostStructure {
  content: string
  created: Date
  id: number
  updated: string
  media: []
  mentioned_startups: []
  mentioned_users: []
  num_comments: number
  num_likes: number
  like: boolean
  user: {
    avatar: string
    name: string
    about: string
    id: number
    connection?: null | {
      id: number
      status: 'Pending' | 'Accepted' | 'Declined'
      is_sender: boolean
    }
  }
}

const filterBy = [
  {
    name: 'None',
    value: '',
  },

  {
    name: 'Investor',
    value: 'Investor',
  },
  {
    name: 'Business',
    value: 'Startup',
  },
]

// Todo: check if search params === * before fetching startups data
function SearchStartups() {
  const { searchParams } = useParams()

  const BreadCrumbMap = new Map()
  BreadCrumbMap.set('/app/networks', 'The Network')
  BreadCrumbMap.set('/app/networks/startups', 'Businesses')
  BreadCrumbMap.set(
    `/app/networks/search-startups/${searchParams}`,
    `${searchParams}`
  )
  BreadCrumbMap.set('excludeCount', 1)
  useBreadCrumbs(BreadCrumbMap)

  const navigate = useNavigate()

  const [search, setsearch] = React.useState<string | undefined>(searchParams)
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const bottomRef1 = useRef<HTMLDivElement | null>(null)
  const isInBottom = useIntersectionObserver(bottomRef as Ref)
  const isInBottom1 = useIntersectionObserver(bottomRef1 as Ref)

  const defautItems = [
    {
      name: 'Businesses',
      path: `/app/networks/search-startups/${searchParams}`,
    },
    {
      name: 'People',
      path: `/app/networks/search-people/${searchParams}`,
    },
  ]
  const handleTabclick = (path: string) => {
    navigate(path)
  }

  //get startups
  const {
    data: startUps,
    isLoading: startUpsLoading,
    isError: isStartUpsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetStartups({ query: search as string })

  //get posts
  const {
    data: apiPosts,
    isLoading: postsLoading,
    isError: isPostError,
    fetchNextPage: fetchNextPosts,
    hasNextPage: hasNextPostsPg,
    isFetchingNextPage: isFetchingNextPosts,
  } = useGetPosts()

  //infinite scroll for startups
  React.useMemo(() => {
    if (isInBottom && hasNextPage) {
      fetchNextPage()
    }
  }, [isInBottom])

  //infinite scroll for posts
  React.useMemo(() => {
    if (isInBottom1 && hasNextPostsPg) {
      fetchNextPosts()
    }
  }, [isInBottom1])

  const startupsData = (startUps as unknown as InfiniteScroll)?.pages
  // console.log(startupsData)
  const posts = (apiPosts as unknown as InfiniteScroll)?.pages
  const isPostEmpty = posts?.[0].data?.data?.results?.length < 1
  const postCount = posts?.[0].data?.data?.count
  return (
    <div className=' items-start p-4'>
      <Title>{`Search for ${searchParams || ''} businesses`}</Title>
      <div className='flex flex-col justify-between items-start sm:items-end lg:flex-row width-full'>
        <ButtonTab
          forceToBeActive={'/app/networks/search-startups'}
          onClick={handleTabclick}
          items={defautItems}
        />

        <div className='flex items-center  mb-3  gap-1 lg:gap-4'>
          <TextInput
            placeholder='Search'
            name='search'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setsearch(e.target.value)
            }
            endIcon={
              !!search && (
                <FaX className='text-[0.7rem]' onClick={() => setsearch('')} />
              )
            }
            className='border border-gray-200 sm:w-[322px]'
          />
          {/* <DropdownButton
            title='Filter by'
            items={filterBy}
            btnClass='py-2 border text-xs font-light min-w-[100px]'
            onClick={onFilter}
            changeTitleOnSelect
          /> */}
        </div>
      </div>
      <div className='flex items-center justify-between w-full'>
        {startupsData?.length && startupsData[0].data.data.count ? (
          <div className='flex gap-1'>
            <Typography heading='3xs'>Search Results</Typography>{' '}
            <small className='font-extralight'>{`(${
              startupsData?.length && startupsData[0].data.data.count
            })`}</small>
          </div>
        ) : null}
        {!startUpsLoading &&
        !isStartUpsError &&
        startupsData?.length &&
        startupsData[0].data.data.count > 0 ? (
          <Typography
            heading='3xs'
            className='cursor-pointer'
            onClick={() => navigate('/app/networks/startups')}
          >
            See all
          </Typography>
        ) : null}
      </div>
      {
        //if first load

        <LoadingState
          condition={
            startUpsLoading
              ? 'isLoading'
              : startupsData?.length &&
                !startupsData[0].data.data.results.length
              ? 'isEmpty'
              : isStartUpsError
              ? 'isError'
              : ''
          }
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>No Business data</Typography>
            </>
          }
        />
      }

      <div className=' p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6 sm:gap-3'>
        {startupsData?.map((returnedData) =>
          returnedData?.data?.data?.results?.map(
            (startup: StartUpStructure) => (
              <StartupCard key={startup.id} {...startup} />
            )
          )
        )}
      </div>
      {isFetchingNextPage && (
        <div className='w-full flex justify-center'>
          <CircularProgress size='60px' color='black' />
        </div>
      )}
      <div ref={bottomRef} />

      {/* posts from search query */}
      <div className='min-h-[60vh] w-full flex flex-col gap-3 md:w-[70%]'>
        <div className='mt-4 flex gap-1 sm:mt-7'>
          <Typography heading='3xs'>Post</Typography>
          {<small className='font-light'>{`(${postCount || 0})`}</small>}
        </div>

        {
          //if first load

          <LoadingState
            skeletonLoader={<PostSkeletonLoader />}
            condition={
              postsLoading
                ? 'isLoading'
                : isPostEmpty
                ? 'isEmpty'
                : isPostError
                ? 'isError'
                : ''
            }
            isEmptyDisplay={
              <>
                <Typography heading='3xs'>No post on timeline</Typography>
              </>
            }
          />
        }
        {posts?.map((page) =>
          page?.data.data?.results?.map((post: PostStructure) => (
            <Post
              key={post.id}
              id={post.id}
              userId={post?.user?.id}
              name={post?.user?.name}
              title={post?.user?.about}
              content={post.content}
              src={post?.user?.avatar || ''}
              postImgs={post.media}
              date={post.created}
              like={post.like}
              connection={post?.user?.connection}
              num_likes={post.num_likes}
              num_comments={post.num_comments}
            />
          ))
        )}
        {isFetchingNextPosts && (
          <div className='w-full flex justify-center'>
            <CircularProgress size='40px' color='black' />
          </div>
        )}
        <div ref={bottomRef1} />
      </div>
    </div>
  )
}

export default SearchStartups
