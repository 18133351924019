import React from 'react'
import { ReactComponent as EmptyDisplay } from '@/assets/dataRoom/emptyDisplay.svg'
import Typography from '@/common/Typography'

import CreateUpdate from './CreateUpdate'

function UpdatesEmptyComponent({ startupId }: { startupId: number }) {
  return (
    <div className='flex flex-col gap-3 items-center justify-center w-full  min-h-[60vh] rounded-md'>
      <EmptyDisplay />
      <Typography heading='xl'>Updates</Typography>
      <Typography
        button='md'
        className='w-[350px] sm:w-[400px] text-center !text-black-white-shades-4'
      >
        Share internal updates with your connects, get feedback on company
        advancements from opinions that matter and keep your investors on the
        same page.
      </Typography>
      <div className='bg-[white] flex flex-col rounded-md gap-2 p-[24px] w-full border border-[#E6E6EB]'>
        <CreateUpdate showTitle={false} startupId={startupId} />
      </div>
    </div>
  )
}

export default UpdatesEmptyComponent
