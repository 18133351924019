import classNames from 'classnames'
import React, { ReactNode } from 'react'
interface Props {
  className?: string
  children: ReactNode

  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}
function Chip({ children, className, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex text-[0.8rem] items-center gap-2 w-fit-content px-2 py-1 rounded-full',
        className
      )}
    >
      {children}
    </div>
  )
}

export default Chip
