import { ServerRes } from '@/apis/api.types'
import {
  CreateFile,
  DeleteAccessReq,
  DeleteDocument,
  DeleteUpdate,
  DownloadDocuments,
  GetAccessList,
  GetDataRoomData,
  GetDataRoomQueryDTO,
  GetDocument,
  GetDocumentViewers,
  GetFiles,
  GetFilesQueryDTO,
  GetFolders,
  GetItemQueryDTO,
  GetPublicDocument,
  GetUpdate,
  GetUpdates,
  GetUpdatesComments,
  PostUpdates,
  PostUpdatesComment,
  PostUpdatesMedia,
  SearchDocuments,
  ToggleUpdatesLike,
  UpdateAccessReq,
  UpddateDocument,
} from '@/apis/dataRoomApis'
import {
  CREATE_DOCUMENT,
  GET_ACCESS_LIST,
  GET_DATA_ROOM,
  GET_FILES,
  GET_FOLDERS,
  GET_UPDATES,
  GET_UPDATES_COMMENTS,
  SEARCH_DOCUMENTS,
} from '@/apis/endpoints'
import { AxiosResponse } from 'axios'
import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from 'react-query'

//get data room
export const useGetDataRoom = (data: {
  query?: string
  status?: 'founder' | 'member' | ''
}) => {
  return useInfiniteQuery(
    [GET_DATA_ROOM, { query: data?.query, status: data?.status }],
    ({ pageParam = 1 }) => GetDataRoomData({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//get folders
export const useGetFolders = (data: Omit<GetDataRoomQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [GET_FOLDERS, data.startup],
    ({ pageParam = 1 }) => GetFolders({ ...data, page: pageParam }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get folders
export const useGetDocumentViewers = (data: Omit<GetItemQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [`${CREATE_DOCUMENT}${data.id}/viewers/`],
    ({ pageParam = 1 }) => GetDocumentViewers({ ...data, page: pageParam }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//get single document
export const useGetDocument = (data: GetItemQueryDTO) => {
  return useQuery([CREATE_DOCUMENT, data.id], () => GetDocument(data), {})
}
//get single public document
export const useGetPublicDocument = (data: GetItemQueryDTO) => {
  return useQuery([CREATE_DOCUMENT + data.name + '/' + data.id], () =>
    GetPublicDocument(data)
  )
}
//create folder & file
export const useCreateDocument = () => {
  return useMutation(CreateFile)
}
//update document
export const useUpdateDocument = () => {
  return useMutation(UpddateDocument)
}
//delete document
export const useDeleteDocument = () => {
  return useMutation(DeleteDocument)
}

//download documents
export const useDownloadDocuments = () => {
  return useMutation(DownloadDocuments)
}

//get files
export const useGetFiles = (data: Omit<GetFilesQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [
      GET_FILES,
      {
        sort_order: data?.sort_order,
        folder: data.folder,
        startup: data.startup,
        parent: data.parent,
      },
    ],
    ({ pageParam = 1 }) => GetFiles({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//search documents
export const useSearchDocuments = (
  data: Omit<GetFilesQueryDTO, 'page'> & { cb?: (res: any) => void }
) => {
  return useInfiniteQuery(
    [SEARCH_DOCUMENTS, { query: data?.query, folder: data.folder }],
    ({ pageParam = 1 }) => SearchDocuments({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
      enabled: !!data.query,
      onSuccess: (res: any) => data?.cb && data?.cb(res),
    }
  )
}

/***
 *
 * Updates tab
 */

//get updates
export const useGetUpdates = (data: Omit<GetFilesQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [
      GET_UPDATES,
      {
        query: data.query,
      },
    ],
    ({ pageParam = 1 }) => GetUpdates({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get updates comments
export const useGetUpdatesComments = (data: Omit<GetItemQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [
      GET_UPDATES_COMMENTS,
      {
        update: data.id,
      },
    ],
    ({ pageParam = 1 }) => GetUpdatesComments({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//post updates
export const usePostUpdates = () => {
  return useMutation(PostUpdates)
}
//post updates comments
export const usePostUpdatesComments = () => {
  return useMutation(PostUpdatesComment)
}
//delete updates
export const useDeleteUpdate = () => {
  return useMutation(DeleteUpdate)
}
//post updates media
export const usePostUpdatesMedia = () => {
  return useMutation(PostUpdatesMedia)
}
//toggle updates like
export const useToggleUpdatesLike = () => {
  return useMutation(ToggleUpdatesLike)
}

//get single update
export const useGetUpdate = (data: GetItemQueryDTO) => {
  return useQuery([CREATE_DOCUMENT + data.id], () => GetUpdate(data), {})
}

/***
 *
 * Access tab
 */

//get access list
export const useGetAccessList = (data: Omit<GetFilesQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [
      GET_ACCESS_LIST,
      {
        query: data.query,
        startup: data.startup,
        status: data.status,
      },
    ],
    ({ pageParam = 1 }) => GetAccessList({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//delete access
export const useDeleteAccessReq = () => {
  return useMutation(DeleteAccessReq)
}
// updates access
export const useUpdateAccess = () => {
  return useMutation(UpdateAccessReq)
}
