import React from 'react'
import ButtonTab from './components/ButtonTab'
import TextInput from '@/common/TextInput'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'

import { useLocation, useNavigate } from 'react-router'
import Following from './components/Following'

import SearchResults from './components/SearchResults'
import { FaX } from 'react-icons/fa6'
import {
  useCustomInfiniteQuery,
  useCustomQuery,
} from '@/hooks/react-query/query/useQuery'
import {
  CONNECTIONS,
  PENDING_CONNECTIONS,
  TIMELINE_SEARCH,
} from '@/constants/querryKeys'
import Typography from '@/common/Typography'
import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'
import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import LoadingState from './components/LoadingState'

import { InfiniteScroll } from './components/utils/types'
import Modal, { RefType } from '@/common/Modal'
import SinglePost from './components/SinglePost/SinglePost'
import {
  useChangeConnectionStatus,
  useDeleteConnection,
  useGetConnections,
  useSearchTimeline,
} from '@/store/networkStore'

import Title from '@/common/Title'
import { useBreadCrumbs } from '@/layouts/app/components/header'

import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import { checkCategory } from './components/utils/checkCategory'
import { InvestorGroupType } from '@/apis/networkApis'
import { Pages } from '@/routes/route.constant'
import clsx from 'clsx'

type Ref = {
  current: Element
}
interface ResultsStructure {
  id: number
  status: 'Pending' | 'Accepted' | 'Declined'
  is_sender: boolean
  user: {
    id: number
    name: string
    about: string
    avatar: string
    num_connections: number
    num_followers: number
    connected: string
  }
  investor_group: InvestorGroupType
  created: Date
}

interface SearchData {
  posts: { count: number; posts: any[] }
  startups: {
    count: number
    startups: any[]
  }
  users: { count: number; users: any[] }
}

const Connection = ({
  id,
  user,
  is_sender,
  investor_group,
}: ResultsStructure) => {
  const navigate = useNavigate()

  //deny Connection
  const { mutateAsync: declineConnection, isLoading: denyConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [CONNECTIONS],
    })

  //accept Connection
  const { mutateAsync: acceptConnection, isLoading: acceptConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [CONNECTIONS],
    })

  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [CONNECTIONS],
    })

  const data = {
    user,
    investor_group,
  }
  const name = investor_group ? investor_group.name : user.name
  return (
    <div key={id} className='flex justify-between align-center'>
      <div className='flex gap-2 items-center'>
        <img
          src={
            checkCategory({
              groupKey: 'logo',
              userKey: 'avatar',
              data,
            }) || imagesUrl.blanckProfileImg
          }
          alt='profile_pix'
          className={clsx('w-[32px] h-[32px] rounded-full', {
            '!rounded-sm': !!investor_group,
          })}
        />
        <Typography
          button='xs'
          className='cursor-pointer'
          onClick={() =>
            navigate(
              `/app/networks/${
                investor_group ? Pages.Group : Pages.User
              }/${name}`,
              {
                state: {
                  id: investor_group ? investor_group.id : user.id,
                },
              }
            )
          }
        >
          {name}
        </Typography>
      </div>

      {is_sender ? (
        <div className='flex gap-1 items-center'>
          {' '}
          <Button
            size='xs'
            color='green'
            className='px-3'
            onClick={() => acceptConnection({ id, status: 'Accepted' })}
            loading={acceptConnectionLoading}
          >
            Accept
          </Button>{' '}
          <Button
            size='xs'
            color='lightred'
            className='px-3'
            onClick={() => deleteConnection({ id })}
            loading={deleteConnectionLoading}
          >
            Decline
          </Button>{' '}
        </div>
      ) : (
        <Button
          size='xs'
          color='lightred'
          className='px-3'
          onClick={() => deleteConnection({ id })}
          loading={deleteConnectionLoading}
        >
          Delete
        </Button>
      )}
    </div>
  )
}

const PendingConnections = () => {
  // const count = data.data.count
  // const result = data.data.results
  const bottomRef = React.useRef<HTMLDivElement | null>(null)

  const isInBottom = useIntersectionObserver(bottomRef as Ref)

  //get connections
  const {
    data: connectionsApi,
    isLoading: connectionsLoading,
    isError: isConnectionsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetConnections({ status: 'Pending' })

  React.useMemo(() => {
    if (isInBottom && hasNextPage) {
      fetchNextPage()
    }
  }, [isInBottom])
  const pending = (connectionsApi as unknown as InfiniteScroll)?.pages
  const data = pending?.[0].data?.data
  const isEmpty = pending?.[0].data?.data?.results?.length < 1

  // console.log(pending)
  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className='flex flex-col gap-1'>
        <Typography heading='3xs'>Pending Connection Request</Typography>

        {isEmpty || connectionsLoading || isConnectionsError ? null : (
          <Typography paragraph='xs'>
            {data?.count} {data?.count === 1 ? 'person' : 'people'}
          </Typography>
        )}
      </div>
      {
        //if first load

        <LoadingState
          condition={
            connectionsLoading
              ? 'isLoading'
              : isEmpty
              ? 'isEmpty'
              : isConnectionsError
              ? 'isError'
              : ''
          }
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>
                You have no pending connections
              </Typography>
            </>
          }
        />
      }
      {pending?.map((data) =>
        data.data.data.results.map((connection: ResultsStructure) => (
          <Connection key={connection.id} {...connection} />
        ))
      )}
      <div ref={bottomRef} />
    </div>
  )
}

const BreadCrumbMap = new Map()
BreadCrumbMap.set('/app/networks', 'My Network')
function MyNetwork() {
  useBreadCrumbs(BreadCrumbMap)
  const navigate = useNavigate()
  const location = useLocation()

  const modalRef = React.useRef<RefType>(null)

  const [search, setsearch] = React.useState<string>('')

  const [postId, setpostId] = React.useState<number | null>()

  const handleTabclick = (path: string) => {
    navigate(path)
  }

  const toggleModal = (id: number): void => {
    setpostId(id)
    modalRef?.current?.handleToggle()
  }

  //search timeline
  const { data: timelineSearchData, isLoading: timelineSearchDataLoading } =
    useSearchTimeline({ search })
  const searchDataMain = timelineSearchData as unknown as SearchData

  //get connections
  const {
    data: connectionsApi,
    isLoading: connectionsLoading,
    isError: isConnectionsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetConnections({ status: 'Accepted' })

  const followings = (connectionsApi as unknown as InfiniteScroll)?.pages
  const isEmpty = followings?.[0].data?.data?.results?.length < 1

  return (
    <div className=''>
      <Title>My Network</Title>
      <div className='flex flex-col justify-between items-start md:items-center md:flex-row width-full'>
        <ButtonTab
          activeProperty={location.pathname}
          onClick={handleTabclick}
        />
        <div className='relative w-[80vw] sm:w-auto'>
          <TextInput
            placeholder='Search connections'
            name='search'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setsearch(e.target.value)
            }
            startIcon={<SearchIcon />}
            endIcon={
              !!search && (
                <FaX className='text-[0.7rem]' onClick={() => setsearch('')} />
              )
            }
            className='w-full border border-gray-200 sm:h-[48px] md:w-[200px] lg:w-[364px]'
          />
          <SearchResults
            isOpen={!!search}
            investors={searchDataMain?.users}
            startups={searchDataMain?.startups}
            posts={searchDataMain?.posts}
            toggleModal={toggleModal}
          />
        </div>
      </div>
      <div className='flex gap-3 flex-col items-start sm:flex-row '>
        <div className='bg-[white] border border-[#E6E6EB] mt-2 h-fit-content min-h-[20vh] rounded-lg  md:w-[60%] lg:w-[70%] sm:mt-6'>
          {
            //if first load

            <LoadingState
              condition={
                connectionsLoading
                  ? 'isLoading'
                  : isEmpty
                  ? 'isEmpty'
                  : isConnectionsError
                  ? 'isError'
                  : ''
              }
              isEmptyDisplay={
                <>
                  <Typography heading='3xs'>
                    You have no connections yet
                  </Typography>
                </>
              }
            />
          }
          <InfiniteScrollContainer
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            containerClass='min-h-[40vh]'
          >
            <div className='flex w-full flex-col gap-3 items-start p-4'>
              {followings?.map((follow, i) => (
                <Following key={i} data={follow.data} />
              ))}
            </div>
          </InfiniteScrollContainer>
        </div>
        <div className='flex w-full flex-col gap-3 items-start bg-[white] p-4 border border-[#E6E6EB] mt-2 rounded-lg p-4 md:w-[40%]  lg:w-[30%] sm:mt-6'>
          <PendingConnections />
        </div>
      </div>

      <Modal
        ref={modalRef}
        modalClass='w-[90vw] sm:w-[800px] min-h-[80vh] pt-2'
      >
        <SinglePost id={postId} />
      </Modal>
    </div>
  )
}

export default MyNetwork
