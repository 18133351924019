import Typography from '@/common/Typography'
import React from 'react'

interface Props {
  about?: string
}

function AboutInvestor({ about }: Props) {
  return (
    <div className='bg-[white] p-4  rounded-lg flex flex-col gap-4 items-start w-full'>
      <div className='flex flex-col gap-3 items-start'>
        <Typography heading='xs'>About Me</Typography>
        <p>{about}</p>
      </div>
    </div>
  )
}

export default AboutInvestor
