import Title from '@/common/Title'
import Button from '@/common/button/Button'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@/common/tabs'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import { Pages } from '@/routes/route.constant'
import clsx from 'clsx'
import React from 'react'
import { useLocation, useParams } from 'react-router'
import Files from './FilesTab'
import UpdatesTab from './UpdatesTab'
import AccessControl from './AccessControlTab'

function Startup() {
  const { startupName } = useParams()
  const { state } = useLocation()

  // const startupId = state.startupId

  const BreadCrumbMap = new Map()
  BreadCrumbMap.set(`/app/${Pages.DataRoom}`, 'Data Room')
  BreadCrumbMap.set(`/app/${Pages.DataRoom}/${startupName}`, startupName)

  useBreadCrumbs(BreadCrumbMap)

  const [selectedTab, setSelectedTab] = React.useState<
    'files' | 'updates' | 'accessControl'
  >('files')
  return (
    <div>
      <Title>{`${startupName}`}</Title>

      <Tabs
        onChange={(e: any) => {
          setSelectedTab(e.currentTarget.value)
        }}
        value={selectedTab}
        className='w-full gap-7 flex flex-col'
      >
        <TabList className='flex justify-between gap-1 mb-10 p-2 items-center self-stretch w-fit min-w-full rounded-lg border border-sifuse-shades-200 bg-sifuse-shades-75 sm:min-w-[360px] sm:mb-0'>
          <Tab as='span' value={'files'} className='flex-1'>
            <Button
              className={clsx(
                'flex-1 text-center w-full  border border-gray-900',
                selectedTab === 'files'
                  ? ''
                  : 'text-sm leading-5 !font-normal !border-none'
              )}
              size='sm'
              color={selectedTab === 'files' ? 'primary' : 'plain'}
            >
              Files
            </Button>
          </Tab>
          <Tab as='span' value={'updates'} className='flex-1'>
            <Button
              className={clsx(
                'flex-1 text-center w-full border border-gray-900',
                selectedTab === 'updates'
                  ? ''
                  : 'text-sm leading-5 !font-normal !border-none'
              )}
              size='sm'
              color={selectedTab === 'updates' ? 'primary' : 'plain'}
            >
              Updates
            </Button>
          </Tab>
          <Tab as='span' value={'accessControl'} className='flex-1 '>
            <Button
              className={clsx(
                'flex-1 text-center w-full border border-gray-900 !min-w-[120px]',
                selectedTab === 'accessControl'
                  ? ''
                  : 'text-sm leading-5 !font-normal !border-none'
              )}
              size='sm'
              color={selectedTab === 'accessControl' ? 'primary' : 'plain'}
            >
              Access Control{' '}
            </Button>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel value={'files'}>
            <Files />
          </TabPanel>
          <TabPanel value={'updates'}>
            <UpdatesTab />
          </TabPanel>
          <TabPanel value={'accessControl'}>
            <AccessControl />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}

export default Startup
