import React from 'react'

import DropdownButton from '@/common/DropdownButton'

import TextInput from '@/common/TextInput'

import { useLocation, useNavigate } from 'react-router-dom'
import InvestorCard from './components/InvestorCard'
import ButtonTab from './components/ButtonTab'

import LoadingState from './components/LoadingState'
import Typography from '@/common/Typography'

import { useGetInvestorGroups } from '@/store/networkStore'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import Title from '@/common/Title'
import { FaX } from 'react-icons/fa6'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
export interface InvestorStructure {
  id: number
  email: string
  first_name: string
  last_name: string
  phone_number: string
  user_type: string
  avatar: string
  about: string
  last_login: Date
  date_joined: Date
  email_verified: boolean
  num_connections: number
  num_followers: number
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
  follower: boolean
  following: boolean
}

type Category = '' | 'Group' | 'Network' | 'Individual' | 'Syndicate'
const investorsCat = [
  {
    name: 'All Investors',
    value: '',
  },

  {
    name: 'Group',
    value: 'Group',
  },
  {
    name: 'Syndicates',
    value: 'Syndicate',
  },
  {
    name: 'Network',
    value: 'Network',
  },
  {
    name: 'Individual',
    value: 'Individual',
  },
]

const BreadCrumbMap = new Map()

BreadCrumbMap.set('/app/all-people', 'Investors')

function Investors() {
  useBreadCrumbs(BreadCrumbMap)
  const navigate = useNavigate()
  const location = useLocation()

  const [search, setsearch] = React.useState<string>('')

  const [investorsCategory, setinvestorsCategory] = React.useState<Category>('')

  const handleTabclick = (path: string) => {
    navigate(path)
  }

  const onChangeCategory = (value: Category | any) => {
    setinvestorsCategory(value)
  }

  //get users
  const {
    data: investors,
    isLoading: investorsLoading,
    isError: isinvestorsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetInvestorGroups({ query: search, category: investorsCategory })

  const investorsData = investors?.pages.flatMap((page) => page.results)

  return (
    <div className=''>
      <Title>People</Title>
      <div className='flex flex-col justify-between items-start width-full md:items-center md:flex-row '>
        <ButtonTab
          activeProperty={location.pathname}
          onClick={handleTabclick}
        />

        <div className='flex items-center justify-center gap-1 lg:gap-2'>
          <TextInput
            placeholder='Search'
            name='search'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setsearch(e.target.value)
            }
            endIcon={
              !!search && (
                <FaX className='text-[0.7rem]' onClick={() => setsearch('')} />
              )
            }
            className='w-full border border-gray-200 h-[50px] sm:w-[110px] lg:w-[322px]'
          />
        </div>
      </div>
      <div>
        <DropdownButton
          title={investorsCategory || 'All Investors'}
          items={investorsCat}
          btnClass='py-1 text-sm !bg-[transparent] !border-none !min-w-[110px] h-[48px]'
          onClick={onChangeCategory}
          changeTitleOnSelect
          trayClass='!w-auto min-w-[221px] !rounded-md'
          itemClass='!border-transparent'
          showSelected={true}
        />
      </div>
      {
        //if first load
        <LoadingState
          condition={
            investorsLoading
              ? 'isLoading'
              : !investorsData?.length
              ? 'isEmpty'
              : isinvestorsError
              ? 'isError'
              : ''
          }
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>No Data</Typography>
            </>
          }
        />
      }

      {search && investorsData?.length ? (
        <div className='flex gap-1'>
          <Typography heading='3xs'>Search Results</Typography>{' '}
          <small className='font-extralight'>{`(${investorsData?.length})`}</small>
        </div>
      ) : null}
      <InfiniteScrollContainer
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      >
        <div className=' p-2 grid grid-cols-1 sm:p-4 sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-6 sm:gap-3'>
          {investorsData?.map((investor) => (
            <InvestorCard key={investor.id} {...investor} />
          ))}
        </div>
      </InfiniteScrollContainer>
    </div>
  )
}

export default Investors
