import { ReactComponent as PdfIcon } from '@/assets/icons/media/pdf.svg'
import { ReactComponent as WordIcon } from '@/assets/icons/media/docx.svg'
import { ReactComponent as ExcelIcon } from '@/assets/icons/media/excel.svg'
import { ReactComponent as SmallFolderIcon } from '@/assets/icons/utils/smallFolder.svg'
import { ReactComponent as SmallEyeIcon } from '@/assets/icons/utils/smallEye.svg'
import { ReactComponent as EditIcon } from '@/assets/icons/utils/edit.svg'
import { ReactComponent as AddIcon } from '@/assets/icons/utils/add.svg'
import { ReactComponent as NotAllowedIcon } from '@/assets/icons/utils/notallowed.svg'
import { ReactComponent as Mail } from '@/assets/icons/utils/message.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import TextInput from '@/common/TextInput'
import Modal, { RefType } from '@/common/Modal'
import React, { useState } from 'react'
import { imagesUrl } from '@/assets/images/imageUrls'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'

import { DocumentViewersResDTO, GetFoldersResDTO } from '@/apis/dataRoomApis'
import moment from 'moment'
import { ReactComponent as RedBinIcon } from '@/assets/icons/utils/redBin.svg'

import {
  useDeleteAccessReq,
  useDeleteDocument,
  useDownloadDocuments,
  useGetDocumentViewers,
  useUpdateDocument,
} from '@/store/dataRoomStore'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { useQueryClient } from 'react-query'
import {
  CREATE_DOCUMENT,
  GET_FILES,
  SIFUSE_FRONTEND_URL,
} from '@/apis/endpoints'

import useDownload from '@/hooks/useDownload'
import { truncateText } from '@/helpers/ellipsizeTextBox'
import LoadingState from '@/pages/theNetwork/components/LoadingState'
import { numberFormatter } from '@/helpers/numberFormatter'
import { baseAPIURL } from '@/apis/api'
import { useMessageRecipient } from '@/pages/message/hooks'
import {
  useChangeConnectionStatus,
  useCreateConnection,
  useDeleteConnection,
} from '@/store/networkStore'
import { ReactComponent as ShareIconHighlighted } from '@/assets/network/share-highlighted.svg'
import { ReactComponent as ShareIcon } from '@/assets/network/share.svg'
import { copyToClipBoard } from '@/pages/theNetwork/components/utils/copyToClipBoard'
import { Pages } from '@/routes/route.constant'

const Viewer = ({
  viewer,
  fileId,
}: {
  viewer: DocumentViewersResDTO
  fileId: number
}) => {
  const queryClient = useQueryClient()
  const messageUser = useMessageRecipient()

  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [`${CREATE_DOCUMENT}${fileId}/viewers/`],
    })

  // create connection
  const { mutate: createConnection, isLoading: createConnectionLoading } =
    useCreateConnection({
      invalidateKey: [`${CREATE_DOCUMENT}${fileId}/viewers/`],
    })
  //accept Connection
  const { mutateAsync: acceptConnection, isLoading: acceptConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [`${CREATE_DOCUMENT}${fileId}/viewers/`],
    })

  let btn: React.ReactElement = <></>
  switch (viewer?.connection?.status) {
    case 'Pending':
      btn = (
        <div className='flex gap-1 items-center'>
          {' '}
          <Button
            size='xs'
            color='green'
            className='px-2'
            onClick={() =>
              acceptConnection({
                id: viewer?.connection?.id as number,
                status: 'Accepted',
              })
            }
            loading={acceptConnectionLoading}
          >
            Accept
          </Button>{' '}
          <Button
            size='xs'
            color='lightred'
            className='px-3'
            onClick={() => deleteConnection({ id: viewer?.connection?.id })}
            loading={deleteConnectionLoading}
          >
            Decline
          </Button>{' '}
        </div>
      )
      break
    case 'Declined':
      btn = (
        <Button
          color='white'
          className='border !border-primary'
          onClick={() => createConnection({ id: viewer.id })}
          size='sm'
          loading={createConnectionLoading}
          startIcon={<AddIcon />}
        >
          Connect
        </Button>
      )
      break
    case 'Accepted':
      btn = (
        <Button
          color='lightgreen'
          className='rounded  border'
          onClick={() => deleteConnection({ id: viewer?.connection?.id })}
          size='sm'
          loading={deleteConnectionLoading}
        >
          Unconnect
        </Button>
      )
      break

    default:
      break
  }

  // delete access
  const { mutateAsync: deleteAccess, isLoading: deleteAccessLoading } =
    useDeleteAccessReq()

  const handleDeleteAccess = async () => {
    const { response, error } = await withAsync(() =>
      deleteAccess({ id: viewer.id })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([`${CREATE_DOCUMENT}${fileId}/viewers/`])
      ToastNotify('success', {
        message: 'Success',
      })
    }
  }
  return (
    <div className='flex w-full flex-col gap-2 justify-between align-center sm:flex-row sm:gap-0'>
      {/* items on the left */}
      <div className='flex gap-2 items-start'>
        <span className='relative'>
          <img
            src={viewer.avatar || imagesUrl.blanckProfileImg}
            alt='user_profile_img'
            className='h-[32px] w-[32px] min-h-[32px] min-w-[32px] object-center object-cover rounded-full'
          />
          {viewer?.online && (
            <div className='w-[10px] h-[10px] rounded-full bg-success top-0 right-0 absolute' />
          )}
        </span>
        <div className='flex flex-col gap-1'>
          <Typography button='sm'>{`${viewer.first_name} ${viewer.last_name}`}</Typography>

          <span className='flex items-center gap-2'>
            <Typography paragraph='xs' className='!text-black-white-shades-4'>
              {truncateText(viewer.about, 10)}
            </Typography>
            <div className='w-[3px] h-[3px] rounded-full bg-black-white-shades-4' />
            <Typography paragraph='xs' className='!text-black-white-shades-4'>
              {moment(viewer.created).format('MMMM Do YYYY, hh:mm A')}
            </Typography>
          </span>
        </div>
      </div>

      {/* items on the right */}
      <div className='flex gap-2 items-center'>
        {viewer?.connection === null || viewer?.connection === undefined ? (
          <Button
            color='white'
            className='border !border-primary'
            onClick={() => createConnection({ id: viewer?.id })}
            size='sm'
            loading={createConnectionLoading}
            startIcon={<AddIcon />}
          >
            Connect
          </Button>
        ) : viewer?.connection?.is_sender ? (
          btn
        ) : viewer?.connection?.status === 'Accepted' ? (
          <Button
            color='lightgreen'
            className='rounded  border'
            onClick={() => deleteConnection({ id: viewer?.connection?.id })}
            size='sm'
            loading={deleteConnectionLoading}
          >
            Unconnect
          </Button>
        ) : (
          <Button
            size='xs'
            color='lightred'
            className='px-3'
            onClick={() => deleteConnection({ id: viewer?.connection?.id })}
            loading={deleteConnectionLoading}
          >
            Delete
          </Button>
        )}
        <Button
          size='xs'
          color='white'
          startIcon={<Mail />}
          onClick={() => messageUser(viewer.id)}
        >
          Message
        </Button>

        <Button
          size='xs'
          color='plain'
          className='bg-error border-none !text-white hover:bg-red-700'
          startIcon={<NotAllowedIcon />}
          onClick={handleDeleteAccess}
          loading={deleteAccessLoading}
        >
          Block
        </Button>
      </div>
    </div>
  )
}

const ViewersModal = ({ fileId }: { fileId: number }) => {
  //get files
  const {
    isLoading: isViewersLoading,
    data: apiViewers,
    isError: isViewersError,
    fetchNextPage: viewersFetchNextPg,
    hasNextPage: isViewersHasNextPg,
    isFetchingNextPage: isFetchingViewersNextPg,
  } = useGetDocumentViewers({ id: fileId })

  const viewers = apiViewers?.pages
  const isViewersEmpty = !apiViewers?.pages[0].results?.length

  return (
    <>
      <LoadingState
        condition={
          isViewersLoading
            ? 'isLoading'
            : isViewersEmpty
            ? 'isEmpty'
            : isViewersError
            ? 'isError'
            : ''
        }
        isEmptyDisplay={
          <Typography label='sm'>Nobody has viewed this file yet</Typography>
        }
      />
      {!isViewersLoading && !isViewersEmpty && !isViewersError && (
        <InfiniteScrollContainer
          isFetchingNextPage={isFetchingViewersNextPg}
          fetchNextPage={viewersFetchNextPg}
          hasNextPage={isViewersHasNextPg}
          containerClass='min-h-[60vh] sm:min-h-[40vh]'
        >
          <div className='flex flex-col gap-6'>
            {viewers?.map((page) =>
              page?.results?.map((viewer: DocumentViewersResDTO) => (
                <Viewer key={viewer.id} viewer={viewer} fileId={fileId} />
              ))
            )}
          </div>
        </InfiniteScrollContainer>
      )}
    </>
  )
}

interface FileProp {
  setcopiedFile: (id: number) => void
  copiedFile: number | null
}

function File({
  file_type,
  id,
  name: fileName,
  file,
  created,
  updated,
  startup,
  parent,
  size,
  num_viewers,
  copiedFile,
  setcopiedFile,
}: GetFoldersResDTO & FileProp) {
  //separating filename and its extention

  const [newFileName, extention] = fileName.split('.')

  const queryClient = useQueryClient()

  const { exportHandler, isDownloading } = useDownload()

  const modalRef = React.useRef<RefType>(null)
  const modalRef1 = React.useRef<RefType>(null)
  const modalRef2 = React.useRef<RefType>(null)

  const [name, setname] = useState<string>(newFileName as string)
  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }
  const toggleModal1 = (): void => {
    modalRef1?.current?.handleToggle()
  }
  const toggleModal2 = (): void => {
    modalRef2?.current?.handleToggle()
  }

  let icon = <></>
  switch (file_type) {
    case 'docx':
      icon = <WordIcon />
      break
    case 'excel':
      icon = <ExcelIcon />
      break
    case 'pdf':
      icon = <PdfIcon />
      break

    default:
      break
  }

  const handleDownloadDoc = async () => {
    const url = baseAPIURL
    exportHandler(`${url}${CREATE_DOCUMENT}${id}/download/`, {
      file_name: newFileName,
      file_type,
    })
  }

  //delete doc
  const {
    isLoading: isDeleteLoading,
    mutateAsync: DeleteFile,
    isError: isDeleteError,
  } = useDeleteDocument()
  const handleDeleteDoc = async () => {
    const { response, error } = await withAsync(() =>
      DeleteFile({
        id,
      })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([GET_FILES])
      queryClient.invalidateQueries([CREATE_DOCUMENT])
      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal2()
    }
  }

  //update doc
  const {
    isLoading: isUpdateDocLoading,
    mutateAsync: UpdateDoc,
    isError: isUpdateDocError,
  } = useUpdateDocument()

  const handleUpdateDoc = async () => {
    const { response, error } = await withAsync(() =>
      UpdateDoc({
        id,
        name,
      })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([GET_FILES])
      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal()
    }
  }
  const isTextCopied = copiedFile === Number(id)

  return (
    <main>
      <div className='flex w-full flex-col gap-2 justify-between align-center bg-white rounded-md border h-auto p-2 sm:flex-row sm:h-[64px] sm:gap-0'>
        {/* items on the left */}
        <div className='flex gap-2 items-start'>
          {icon}
          <div className='flex flex-col gap-1'>
            <span className='flex items-center'>
              <Typography label='md'>{newFileName}</Typography>
              <Typography label='md' className='!text-black-white-shades-3'>
                .{extention || file_type}
              </Typography>
            </span>
            <span className='flex items-center gap-2'>
              <Typography label='sm' className='!text-black-white-shades-4'>
                Last Modified
              </Typography>
              <div className='w-[3px] h-[3px] rounded-full bg-black-white-shades-4' />
              <Typography label='md' className='!text-black-white-shades-4'>
                {moment(updated).format('Do MMMM YYYY')}
              </Typography>
            </span>
          </div>
        </div>

        {/* items on the right */}
        <div className='flex gap-2 items-center px-2'>
          <Button
            size='xs'
            color='white'
            endIcon={<SmallFolderIcon />}
            loading={isDownloading}
            onClick={handleDownloadDoc}
          >
            Download/{size}
          </Button>
          <Button
            size='xs'
            color='white'
            className='!w-[53px]'
            startIcon={<SmallEyeIcon />}
            onClick={toggleModal1}
          >
            {numberFormatter(num_viewers)}
          </Button>
          <Button
            size='xs'
            color='plain'
            className='bg-transparent border-none'
            startIcon={<EditIcon />}
            onClick={toggleModal}
          ></Button>
          <Button
            className={'border-none'}
            size='sm'
            color='white'
            startIcon={isTextCopied ? <ShareIconHighlighted /> : <ShareIcon />}
            onClick={() =>
              copyToClipBoard(
                `${SIFUSE_FRONTEND_URL}/auth/${Pages.File}/${newFileName}/${id}`,
                () => setcopiedFile(+id)
              )
            }
          >
            {!isTextCopied ? (
              <span className='font-medium'> Copy link</span>
            ) : (
              <span className='text-[#F68511] font-medium'>Link copied</span>
            )}
          </Button>
          <Button
            size='xs'
            color='lightred'
            className='!p-1'
            startIcon={<RedBinIcon />}
            onClick={toggleModal2}
          />
        </div>
      </div>
      {/* Rename file modal */}
      <Modal
        ref={modalRef}
        enableWarning
        showCloseBtn
        modalClass='w-[488px]  flex flex-col items-start justify-start h-[420px]'
        title='File Option'
        subtitle='Rename the file in the data room'
      >
        <div className='flex flex-col gap-28 w-full'>
          <div className='flex gap-2 flex-col'>
            <Typography label='sm'>File Name</Typography>

            <TextInput
              onChange={(e) => setname(e.target.value)}
              value={name}
              placeholder='Input file name'
              className='w-full bg-black-white-shades-1'
            />
          </div>
          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              className='w-[149px]'
              onClick={handleUpdateDoc}
              loading={isUpdateDocLoading}
            >
              Save
            </Button>
            <Button color='white' className='w-[149px]' onClick={toggleModal}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Viewers modal */}
      <Modal
        ref={modalRef1}
        showCloseBtn
        modalClass='!w-[640px] flex flex-col items-start justify-start h-[90vh]'
        title='Viewers'
        subtitle='List of the people that viewed the document'
      >
        <ViewersModal fileId={id} />
      </Modal>

      {/* Remove file modal */}
      <Modal
        ref={modalRef2}
        enableWarning
        showCloseBtn
        modalClass='w-[488px]  flex flex-col items-start justify-start h-[420px]'
        title='File Option'
        subtitle='Delete file in the data room'
      >
        <div className='flex flex-col items-center justify-center gap-4'>
          <Typography heading='xs'>Are you sure?</Typography>
          <span className='flex gap-1'>
            <Typography label='sm' className='!font-normal'>
              Delete file
            </Typography>
            <Typography
              label='sm'
              className='!font-medium'
            >{`${fileName}`}</Typography>
            <Typography label='sm' className='!font-normal'>
              permanently
            </Typography>
          </span>

          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              loading={isDeleteLoading}
              onClick={handleDeleteDoc}
            >
              Yes, I’m Sure
            </Button>
            <Button color='white' className='w-[124px]' onClick={toggleModal2}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default File
