import React from 'react'
import { FaArrowUp } from 'react-icons/fa'

type Props = {
  show: boolean
  setshow: (isShow: boolean) => void
  onClick: () => void
}

const NewPostNotification = (props: Props) => {
  const { show, setshow, onClick } = props

  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined
    if (show) timeoutId = setTimeout(() => setshow(false), 5000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [show])

  return (
    <>
      {show && (
        <div
          style={{ zIndex: 999999! }}
          onClick={onClick}
          className='flex cursor-pointer items-center w-fit gap-2 bg-primary p-1 rounded-md fixed top-[6.5%] sm:top-[7%] md:top-[14%] lg:top-[11%] left-0 right-0 m-auto text-sm animate-fadeIn'
        >
          <FaArrowUp /> New post
        </div>
      )}
    </>
  )
}

export default NewPostNotification
