import { toast } from 'react-toastify'

export const copyToClipBoard = async (text: string, cb: () => void) => {
  try {
    await navigator?.clipboard?.writeText(text)
    cb()
  } catch (err: any) {
    toast.error(err)
  }
}
