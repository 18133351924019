import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'
import TextInput from '@/common/TextInput'
import Typography from '@/common/Typography'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'

import React from 'react'
import { FaCircle } from 'react-icons/fa'

import { FaX } from 'react-icons/fa6'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import { GetAccess } from '@/apis/dataRoomApis'
import { PaginatedData } from '@/apis/api.types'
import LoadingState from '@/pages/theNetwork/components/LoadingState'
import { ReactComponent as EmptyDisplay } from '@/assets/network/Component 3.svg'

interface Props {
  onClickItem?: (id?: number, name?: string | undefined) => void
  title: string
  search?: string
  setsearch?: (e: any) => void
  isFetchingNextPage: boolean
  fetchNextPage: () => void
  hasNextPage: boolean
  isEmpty: boolean

  data: PaginatedData<GetAccess>[]
}
// data: GetAccess[]

interface ItemProps {
  item?: GetAccess
  isOnline?: boolean
  onClickItem?: (id?: number, name?: string) => void
}

const Item = ({ onClickItem, item, isOnline = false }: ItemProps) => {
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-1'>
        <span
          style={{
            background: `url(${
              item?.user?.avatar || imagesUrl.blanckProfileImg
            }) no-repeat center center/cover`,
            zIndex: 0,
          }}
          className='relative rounded-full h-[32px] w-[32px] '
        >
          {
            // item?.user.online
            isOnline ? (
              <FaCircle
                fontSize={8}
                className='text-green-600 absolute top-0.5 right-0'
              />
            ) : null
          }
        </span>
        <p>
          {item?.user?.first_name} {item?.user?.last_name}
        </p>
      </div>
      <Button
        className='px-5 rounded border border-gray-100'
        size='sm'
        color='white'
        onClick={() =>
          onClickItem &&
          onClickItem(
            item?.id,
            `${item?.user?.first_name} ${item?.user?.last_name}`
          )
        }
      >
        View
      </Button>
    </div>
  )
}

function AccessList({
  onClickItem = () => {},
  title,
  search,
  setsearch,
  data,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  isEmpty,
}: Props) {
  return (
    <div className='bg-[white] rounded-lg p-[24px]  flex flex-col gap-4 border border-[#E6E6EB] max-h-[70vh] overflow-auto'>
      <Typography heading='3xs'>{title}</Typography>

      <TextInput
        placeholder='Search'
        name='search'
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setsearch && setsearch(e.target.value)
        }
        startIcon={<SearchIcon />}
        endIcon={
          !!search && (
            <FaX
              className='text-[0.7rem]'
              onClick={() => setsearch && setsearch('')}
            />
          )
        }
        className='w-full border border-gray-200'
      />
      <LoadingState
        condition={isEmpty ? 'isEmpty' : ''}
        customEmptyDisplay={
          <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[20vh] rounded-md'>
            <EmptyDisplay />
            <Typography heading='3xs'>No access list</Typography>
          </div>
        }
      />

      <InfiniteScrollContainer
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        containerClass='min-h-[20vh]'
      >
        <div className='flex flex-col gap-2'>
          {data?.map((pg) =>
            pg.results.map((item: GetAccess) => (
              <Item
                key={item.id}
                onClickItem={onClickItem}
                item={{ ...item }}
                isOnline={false}
              />
            ))
          )}
          {/* {data?.map((pg) =>
            pg.map((item: GetAccess) => (
              <Item
                key={item.id}
                onClickItem={onClickItem}
                item={{ ...item }}
                isOnline={false}
              />
            ))
          )} */}
        </div>
      </InfiniteScrollContainer>
    </div>
  )
}

export default AccessList
