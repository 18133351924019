import Typography from '@/common/Typography'

import LoadingState from '@/pages/theNetwork/components/LoadingState'

import { useParams } from 'react-router'

import { useGetPublicDocument } from '@/store/dataRoomStore'
import { GetFoldersResDTO } from '@/apis/dataRoomApis'

import FileComponent from './components/File.component'
import FolderComponent from './components/Folder.component'

function PublicFolder() {
  const { folderName, id } = useParams()
  const folderId = Number(id)

  //get folder
  const {
    isLoading: isFolderLoading,
    data: folder,
    isError: isFolderError,
  } = useGetPublicDocument({ id: folderId, name: folderName })

  const isFilesEmpty = !folder?.files?.length

  return (
    <main>
      {
        //if first load
        <LoadingState
          condition={
            isFolderLoading ? 'isLoading' : isFolderError ? 'isError' : ''
          }
        />
      }

      {!isFolderLoading && !isFolderError && (
        <div className='flex flex-col gap-4 '>
          <FolderComponent
            name={folder?.name || folderName}
            id={folderId}
            color={folder?.color}
            file_count={folder?.file_count}
          />

          <div>
            <Typography heading='sm'>Files</Typography>
            {
              <LoadingState
                condition={isFilesEmpty ? 'isEmpty' : ''}
                isEmptyDisplay='No files inside this folder'
              />
            }

            <div className='mt-6 flex flex-col gap-3'>
              {folder?.files?.map((result: GetFoldersResDTO) => (
                <FileComponent
                  key={result.id}
                  file={result}
                  fileName={result.name}
                  id={result.id}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </main>
  )
}

export default PublicFolder
