import React from 'react'

import TextInput from '@/common/TextInput'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'

import { useLocation, useNavigate } from 'react-router'

import { FaX } from 'react-icons/fa6'

import { CONNECTIONS } from '@/constants/querryKeys'
import Typography from '@/common/Typography'
import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'
import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import Modal, { RefType } from '@/common/Modal'

import {
  useChangeConnectionStatus,
  useDeleteConnection,
  useGetConnections,
  useSearchTimeline,
} from '@/store/networkStore'

import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import AccessControlList from '../components/AccessControlList'
import { InfiniteScroll } from '@/pages/theNetwork/components/utils/types'
import LoadingState from '@/pages/theNetwork/components/LoadingState'
import NoAccessGranted from '../components/NoAccessGranted'
import {
  useDeleteAccessReq,
  useGetAccessList,
  useUpdateAccess,
} from '@/store/dataRoomStore'
import { GetAccess } from '@/apis/dataRoomApis'
import { ToastNotify } from '@/common/toastManager'
import { useQueryClient } from 'react-query'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { GET_ACCESS_LIST } from '@/apis/endpoints'
import clsx from 'clsx'

const Request = ({ id, user, investor_group }: GetAccess) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  //accept Connection
  const { mutateAsync: acceptRequest, isLoading: acceptRequestLoading } =
    useUpdateAccess()

  // delete access
  const { mutateAsync: deleteAccess, isLoading: deleteAccessLoading } =
    useDeleteAccessReq()

  // console.log(user)
  const handleDelete = async () => {
    const { response, error } = await withAsync(() =>
      deleteAccess({ id: id as number })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([GET_ACCESS_LIST])
      ToastNotify('success', {
        message: 'Success',
      })
    }
  }
  const handleGrant = async () => {
    const { response, error } = await withAsync(() =>
      acceptRequest({ id: id as number, status: 'Accepted' })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([GET_ACCESS_LIST])
      ToastNotify('success', {
        message: 'Success',
      })
    }
  }
  const name = investor_group
    ? investor_group?.name
    : `${user.first_name} ${user.last_name}`
  const avatar = investor_group ? investor_group?.logo : user?.avatar
  return (
    <div key={id} className='flex justify-between align-center w-full'>
      <div className='flex gap-2 items-center'>
        <img
          src={avatar || imagesUrl.blanckProfileImg}
          alt='profile_pix'
          className={clsx('w-[32px] h-[32px] rounded-full', {
            '!rounded-sm': !!investor_group,
          })}
        />
        <Typography
          button='xs'
          className='cursor-pointer'
          onClick={() =>
            navigate(
              `/app/networks/${investor_group ? 'group' : 'user'}/${name}`,
              {
                state: {
                  id: investor_group ? investor_group.id : user.id,
                  from: 'investors',
                },
              }
            )
          }
        >
          {name}
        </Typography>
      </div>

      <div className='flex gap-1 items-center'>
        {' '}
        <Button
          size='xs'
          color='green'
          className='px-3'
          onClick={handleGrant}
          loading={acceptRequestLoading}
        >
          Grant
        </Button>{' '}
        <Button
          size='xs'
          color='lightred'
          className='px-3'
          onClick={handleDelete}
          loading={deleteAccessLoading}
        >
          Reject
        </Button>{' '}
      </div>
    </div>
  )
}

const PendingRequests = () => {
  const { state } = useLocation()
  // const count = data.data.count
  // const result = data.data.results

  //get connections
  const {
    data: requestApi,
    isLoading: controlListLoading,
    isError: isControlListError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAccessList({
    startup: state.startupId,
    status: 'Pending',
  })

  const pending = requestApi?.pages
  const isEmpty = !requestApi?.pages[0].results?.length
  const count = requestApi?.pages[0]?.count

  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className='flex flex-col gap-1'>
        <Typography heading='xs'>Access Request</Typography>

        {isEmpty || controlListLoading || isControlListError ? null : (
          <Typography paragraph='xs' className='!text-black-white-shades-3'>
            {count} {count === 1 ? 'person' : 'people'}
          </Typography>
        )}
      </div>
      {
        //if first load

        <LoadingState
          condition={
            controlListLoading
              ? 'isLoading'
              : isEmpty
              ? 'isEmpty'
              : isControlListError
              ? 'isError'
              : ''
          }
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>
                You have no pending access requests
              </Typography>
            </>
          }
        />
      }
      <InfiniteScrollContainer
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        containerClass='min-h-[4vh] w-full sm:min-h-[3vh]'
      >
        <div className='flex flex-col gap-2 w-full'>
          {pending?.map((list) =>
            list.results.map((request: GetAccess) => (
              <Request key={request.id} {...request} />
            ))
          )}
        </div>
      </InfiniteScrollContainer>
    </div>
  )
}

function AccessControl() {
  const { state } = useLocation()

  const [search, setsearch] = React.useState<string>('')

  //get access lists
  const {
    data: apiAccessList,
    isLoading: AccessListLoading,
    isError: isAccessListError,
    fetchNextPage: accessListfetchNextPage,
    hasNextPage: accessListhasNextPage,
    isFetchingNextPage: accessListIsFetchingNextPage,
  } = useGetAccessList({
    query: search,
    startup: state.startupId,
    status: 'Accepted',
  })

  const accessList = apiAccessList?.pages
  const isAccessListEmpty = !apiAccessList?.pages[0].results?.length

  return (
    <div className=''>
      <div className='relative'>
        <div className='absolute w-full top-[-58px]  sm:right-[9.5%] sm:top-[-20px] sm:w-[90%] sm:justify-between  md:right-0 md:top-[-77px] md:w-auto lg:top-[-75px]'>
          <TextInput
            placeholder='Search'
            name='search'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setsearch(e.target.value)
            }
            startIcon={<SearchIcon />}
            endIcon={
              !!search && (
                <FaX className='text-[0.7rem]' onClick={() => setsearch('')} />
              )
            }
            className='w-full border border-gray-200 lg:w-[364px] lg:h-[48px]'
          />
        </div>
      </div>

      <div className='flex gap-3 flex-col items-start sm:flex-row '>
        <div className='flex flex-col gap-3 items-start bg-[white] p-[24px] border border-[#E6E6EB] mt-2 h-fit-content min-h-[70vh] rounded-lg p-4 sm:w-[60%] lg:w-[70%] sm:mt-6'>
          {
            //if first load

            <LoadingState
              condition={
                AccessListLoading
                  ? 'isLoading'
                  : isAccessListEmpty
                  ? 'isEmpty'
                  : isAccessListError
                  ? 'isError'
                  : ''
              }
              customEmptyDisplay={<NoAccessGranted isSearch={!!search} />}
            />
          }
          <InfiniteScrollContainer
            isFetchingNextPage={accessListIsFetchingNextPage}
            fetchNextPage={accessListfetchNextPage}
            hasNextPage={accessListhasNextPage}
            containerClass='min-h-[50vh] sm:min-h-[40vh] w-full'
          >
            <div className='flex flex-col gap-2 w-full'>
              {accessList?.map((pg, i) => (
                <AccessControlList key={i} data={pg} />
              ))}
            </div>
          </InfiniteScrollContainer>
        </div>
        <div className='flex w-full flex-col gap-3 items-start bg-[white] p-[24px] border border-[#E6E6EB] mt-2 rounded-lg p-4  sm:w-[40%]  sm:mt-6 lg:w-[30%]'>
          <PendingRequests />
        </div>
      </div>
    </div>
  )
}

export default AccessControl
