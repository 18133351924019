import React, { ReactNode, useMemo, useRef, useState } from 'react'
import Button from './button/Button'
import { FaAngleDoubleRight, FaCaretDown } from 'react-icons/fa'
import classNames from 'classnames'
import Typography from './Typography'

type itemsObj = {
  name: string | React.ReactElement
  value: string | number | null
  submenu?: itemsObj[]
  otherInfo?: any
}

interface Props {
  title?: string
  items?: itemsObj[]
  btnClass?: string
  ClickComponent?: React.ReactElement
  changeTitleOnSelect?: boolean
  onClick?: (value: string | number | null | undefined, item?: itemsObj) => void
  trayClass?: string
  itemClass?: string
  isLoading?: boolean
  disabled?: boolean
  showSelected?: boolean
}
interface TrayProps {
  items: itemsObj[] | undefined
  handleClickItem: (value: itemsObj) => void
  trayClass?: string
  itemClass?: string
  selected?: string
  open: boolean
  showSelected?: boolean
}

const Tray = ({
  itemClass,
  handleClickItem,
  open,
  items,
  trayClass,
  showSelected,
  selected,
}: TrayProps) => {
  const handleClickSubItem = (item: itemsObj) => {
    handleClickItem({ ...item })
  }
  return (
    <div
      className={classNames(
        'flex flex-col gap-0 bg-[white] shadow-md z-10 rounded-b-md absolute left-0 w-full top-[107%]',
        open && trayClass
      )}
    >
      {open &&
        items?.map((item, i, arr) =>
          item?.submenu ? (
            <DropdownButton
              key={i}
              items={item.submenu}
              trayClass=' !left-[100%] shadow !border lg:!left-[-105%]'
              onClick={(val: any, subitem: any) =>
                handleClickSubItem({
                  ...item,
                  submenu: [subitem],
                })
              }
              ClickComponent={
                <small
                  className={classNames(
                    `border border-transparent flex items-center gap-2 !text-[0.75rem] ${
                      //if last item on the list, remove border bottom color, else add it
                      arr.length === i + 1 ? '' : 'border-b-gray-100'
                    }  cursor-pointer px-3 py-1.5 hover:font-medium hover:!text-[0.74rem]`
                  )}
                >
                  {item.name}
                  <FaAngleDoubleRight size={7} />
                </small>
              }
            />
          ) : (
            <small
              className={classNames(
                `border border-transparent cursor-pointer px-3 py-1.5 hover:font-medium`,
                {
                  'border-b-gray-100': arr.length !== i + 1, //if last item on the list, remove border bottom color, else add it
                  'w-full flex items-center justify-between':
                    showSelected && selected && selected === item.name,
                },
                itemClass
              )}
              key={item.value}
              onClick={() => handleClickItem(item)}
            >
              {item.name}{' '}
              {showSelected && selected && selected === item.name && (
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM11.0227 5.72725C11.2424 5.94692 11.2424 6.30308 11.0227 6.52275L7.27275 10.2727C7.05308 10.4924 6.69692 10.4924 6.47725 10.2727L4.97725 8.77275C4.75758 8.55308 4.75758 8.19692 4.97725 7.97725C5.19692 7.75758 5.55308 7.75758 5.77275 7.97725L6.875 9.0795L8.55113 7.40338L10.2273 5.72725C10.4469 5.50758 10.8031 5.50758 11.0227 5.72725Z'
                    fill='#007BFF'
                  />
                </svg>
              )}
            </small>
          )
        )}
    </div>
  )
}

function DropdownButton({
  title = '',
  items,
  onClick,
  btnClass,
  ClickComponent,
  changeTitleOnSelect = false,
  trayClass = '',
  itemClass = '',
  isLoading = false,
  disabled = false,
  showSelected = false,
}: Props) {
  const dropdownMenu = useRef<HTMLDivElement | null>(null)
  const [btnTitle, setbtnTitle] = useState<string>(title)
  const [open, setopen] = useState<boolean>(false)

  useMemo(() => {
    /**
     * Invoke Function onClick outside of element
     */
    const closeOpenMenus = (e: MouseEvent) => {
      if (
        dropdownMenu.current &&
        open &&
        !dropdownMenu?.current?.contains(e?.target as Node)
      ) {
        setopen(false)
      }
    }
    // Bind
    document.addEventListener('mousedown', closeOpenMenus)
    return () => {
      // dispose
      document.removeEventListener('mousedown', closeOpenMenus)
    }
  }, [open])

  const handleOpenTray = () => {
    setopen((prev) => !prev)
  }
  const handleClickItem = (item: itemsObj) => {
    onClick && onClick(item.value, item)
    typeof item.name === 'string' &&
      changeTitleOnSelect &&
      setbtnTitle(item.name === 'None' ? title : item.name)
    setopen((prev) => !prev)
  }
  return (
    <div className='relative' ref={dropdownMenu}>
      {ClickComponent ? (
        React.cloneElement(ClickComponent, {
          onClick: handleOpenTray,
          style: {
            cursor: 'pointer',
          },
        })
      ) : (
        <Button
          className={classNames('rounded', btnClass)}
          color='white'
          endIcon={<FaCaretDown />}
          onClick={handleOpenTray}
          loading={isLoading}
          disabled={disabled}
        >
          {btnTitle}
        </Button>
      )}
      <Tray
        handleClickItem={handleClickItem}
        items={items}
        open={open}
        trayClass={trayClass}
        itemClass={itemClass}
        selected={btnTitle}
        showSelected={showSelected}
      />
    </div>
  )
}

export default DropdownButton
