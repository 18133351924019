import React, { useRef } from 'react'
interface Props {
  onFileSelelcted: (file: File, imgUrl: string) => void
  btnElement: React.ReactElement
  type?: 'image' | 'video'
  accept?: string
}
function FileUploadBtn({
  onFileSelelcted,
  btnElement,
  type = 'image',
  accept = '.doc,.docx,.xml,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const fileUrl = URL.createObjectURL(file)
      onFileSelelcted(file, fileUrl)
    }
  }
  return (
    <>
      {React.cloneElement(btnElement, {
        onClick: handleButtonClick,
      })}

      <input
        type={'file'}
        accept={accept}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  )
}

export default FileUploadBtn
