import Button from '@/common/button/Button'
import React from 'react'
import { FaCheck, FaEye, FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { InvestorStructure } from '../People'
import { imagesUrl } from '@/assets/images/imageUrls'
import Typography from '@/common/Typography'
import { useCustomMutation } from '@/hooks/react-query/mutate/useMutateFunc'
import { toast } from 'react-toastify'
import { FaX } from 'react-icons/fa6'
import { CONNECTIONS, INVESTORS } from '@/constants/querryKeys'
import { useQueryClient } from 'react-query'
import {
  useChangeConnectionStatus,
  useCreateConnection,
  useDeleteConnection,
  useDeleteUserMembership,
  useJoinUserGroup,
} from '@/store/networkStore'
import clsx from 'clsx'
import { GetInvestorGroupsResDTO } from '@/apis/networkApis'
import { GET_INVESTORS_GROUP } from '@/apis/endpoints'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { Pages } from '@/routes/route.constant'

function InvestorCard({
  id,
  name,
  investor_type,
  img,
  num_connections,
  connection,
  member,
}: GetInvestorGroupsResDTO) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [GET_INVESTORS_GROUP],
      // invalidateKey: [CONNECTIONS],
    })

  // create connection
  const { mutate: createConnection, isLoading: createConnectionLoading } =
    useCreateConnection({
      invalidateKey: [GET_INVESTORS_GROUP],
    })

  //accept Connection
  const { mutateAsync: acceptConnection, isLoading: acceptConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [GET_INVESTORS_GROUP],
    })

  let btn: React.ReactElement = <></>
  switch (connection?.status) {
    case 'Pending':
      btn = (
        <>
          {connection.is_sender ? (
            <div className='flex gap-1 items-center'>
              {' '}
              <Button
                size='xs'
                color='green'
                className='px-3'
                onClick={() =>
                  acceptConnection({ id: connection.id, status: 'Accepted' })
                }
                loading={acceptConnectionLoading}
              >
                Accept
              </Button>{' '}
              <Button
                size='xs'
                color='lightred'
                className='px-3'
                onClick={() => deleteConnection({ id: connection.id })}
                loading={deleteConnectionLoading}
              >
                Decline
              </Button>{' '}
            </div>
          ) : (
            <Button
              size='sm'
              color='lightred'
              className='px-4'
              onClick={() => deleteConnection({ id: connection?.id })}
              loading={deleteConnectionLoading}
            >
              Delete
            </Button>
          )}
        </>
      )
      break
    case 'Declined':
      btn = (
        <Button
          color='plain'
          className='rounded  border border-primary'
          onClick={() =>
            createConnection({
              id,
              type: investor_type === 'Individual' ? 'user' : 'group',
            })
          }
          size='sm'
          loading={createConnectionLoading}
        >
          Connect
        </Button>
      )
      break
    case 'Accepted':
      btn = (
        <Button
          color='lightgreen'
          className='rounded  border'
          onClick={() => deleteConnection({ id: connection.id })}
          size='sm'
          loading={deleteConnectionLoading}
        >
          Unconnect
        </Button>
      )
      break

    default:
      btn = (
        <Button
          color='plain'
          className='rounded  border border-primary'
          startIcon={
            <svg
              width='9'
              height='9'
              viewBox='0 0 9 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.5 0.0175781C4.71217 0.0175781 4.91566 0.101864 5.06569 0.251893C5.21571 0.401922 5.3 0.605405 5.3 0.817578V3.21758H7.7C7.91217 3.21758 8.11566 3.30186 8.26569 3.45189C8.41571 3.60192 8.5 3.8054 8.5 4.01758C8.5 4.22975 8.41571 4.43323 8.26569 4.58326C8.11566 4.73329 7.91217 4.81758 7.7 4.81758H5.3V7.21758C5.3 7.42975 5.21571 7.63323 5.06569 7.78326C4.91566 7.93329 4.71217 8.01758 4.5 8.01758C4.28783 8.01758 4.08434 7.93329 3.93431 7.78326C3.78429 7.63323 3.7 7.42975 3.7 7.21758V4.81758H1.3C1.08783 4.81758 0.884344 4.73329 0.734315 4.58326C0.584286 4.43323 0.5 4.22975 0.5 4.01758C0.5 3.8054 0.584286 3.60192 0.734315 3.45189C0.884344 3.30186 1.08783 3.21758 1.3 3.21758H3.7V0.817578C3.7 0.605405 3.78429 0.401922 3.93431 0.251893C4.08434 0.101864 4.28783 0.0175781 4.5 0.0175781Z'
                fill='#272343'
              />
            </svg>
          }
          onClick={() =>
            createConnection({
              id,
              type: investor_type === 'Individual' ? 'user' : 'group',
            })
          }
          size='sm'
          loading={createConnectionLoading}
        >
          Connect
        </Button>
      )

      break
  }

  return (
    <div className='flex flex-row gap-1 py-4 rounded-lg border bg-[white] sm:flex-col sm:gap-3 sm:items-center'>
      {investor_type === 'Individual' ? (
        <img
          src={img || imagesUrl.blanckProfileImg}
          alt='start_up_logo'
          className='h-[87px] w-[87px] object-center object-cover rounded-full sm:h-[130px] sm:w-[130px] lg:h-[154px] lg:w-[154px]'
        />
      ) : img ? (
        <img
          src={img || imagesUrl.blanckProfileImg}
          alt='start_up_logo'
          className='h-[87px] w-[87px] object-center object-cover rounded-md sm:h-[130px] sm:w-[130px] lg:h-[154px] lg:w-[154px]'
        />
      ) : (
        <div className='h-[87px] w-[87px] rounded-md bg-black-white-shades-6 flex items-center justify-center sm:h-[130px] sm:w-[130px] lg:h-[154px] lg:w-[154px]'>
          <Typography heading='md' className='uppercase'>
            {`${name.charAt(0)}${name?.charAt(1)}`}
          </Typography>{' '}
        </div>
      )}
      <div className='flex flex-col gap-2 px-2 py-0 sm:px-3 sm:gap-3'>
        <div className='flex flex-col gap-0 items:start sm:items-center justify-center'>
          <Typography heading='xs' className='capitalize'>
            {`${name}`}
          </Typography>
          <small
            className={clsx('font-medium', {
              'text-[#EA3829]': investor_type === 'Individual',
              'text-[#FAA005]': investor_type === 'Syndicate',
              'text-success': investor_type === 'Network',
              'text-[#7B62FF]': investor_type === 'Group',
            })}
          >
            {investor_type}
          </small>
        </div>
        <div className='flex items-start justify-start sm:items-center sm:justify-center w-full'>
          <span className='flex gap-2'>
            <small>
              {' '}
              <strong>{num_connections}</strong>
            </small>
            <small>Connection{num_connections > 1 ? 's' : ''}</small>
          </span>
        </div>

        <div
          className={clsx(
            'flex items-center justify-between w-full mt-1 gap-2',
            {
              'md:flex-col lg:flex-row': investor_type !== 'Individual',
            }
          )}
        >
          {btn}
          <Button
            color='white'
            size='sm'
            className='border rounded px-4 w-full'
            startIcon={<FaEye />}
            onClick={() =>
              navigate(
                `/app/networks/${
                  investor_type === 'Individual' ? Pages.User : Pages.Group
                }/${name}`,
                {
                  state: {
                    from: 'investors',
                    id,
                  },
                }
              )
            }
          >
            View
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InvestorCard
