import React from 'react'

import {
  FaCheck,
  FaCircle,
  FaEllipsisH,
  FaHeart,
  FaPauseCircle,
  FaPlayCircle,
  FaPlus,
  FaRegHeart,
  FaShareAlt,
} from 'react-icons/fa'
import Button from '@/common/button/Button'
import classNames from 'classnames'
import { imagesUrl } from '@/assets/images/imageUrls'
import moment from 'moment'

import { ReactComponent as ShareIcon } from '@/assets/network/share.svg'
import { ReactComponent as CommentIcon } from '@/assets/network/green comment.svg'
import Typography from '@/common/Typography'
import TextInput from '@/common/TextInput'
import { useQueryClient } from 'react-query'
import { COMMENTS, POSTS } from '@/constants/querryKeys'
import { toast } from 'react-toastify'
import { numberFormatter } from '@/helpers/numberFormatter'
import { ReactComponent as ShareIconHighlighted } from '@/assets/network/share-highlighted.svg'

import {
  useCreateConnection,
  useDeleteConnection,
  usePostComment,
  useToggleLike,
} from '@/store/networkStore'

import { GET_UPDATES, SIFUSE_FRONTEND_URL } from '@/apis/endpoints'
import Modal1, { RefType } from '@/common/Modal'
import PosterDetails from '@/pages/theNetwork/components/PosterDetails'
import SinglePost from './SinglePost'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import {
  usePostUpdatesComments,
  useToggleUpdatesLike,
} from '@/store/dataRoomStore'

interface Props {
  src: string
  name: string

  date?: Date
  like: boolean
  userId: number
  showConnectBtn?: boolean
  postImgs?: Array<{
    file: string
    file_type: string
    id: number

    update: number
    video_cover?: string
  }>
  content: string
  id: number
  connection?: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
  containerClass?: string
  num_comments: number
  num_likes: number
  hideBtns?: boolean
}
type VideoThumbnailProps = {
  videoUrl: string
  thumbnailUrl: string
  className: string
}

const twoImgClass = 'container m-auto grid grid-cols-2 gap-1'
const threeImgClass = 'container m-auto grid grid-cols-3 gap-1'

function VideoThumbnail({
  videoUrl,
  thumbnailUrl,
  className,
}: VideoThumbnailProps) {
  const videoRef = React.useRef<HTMLVideoElement | null>(null)

  return (
    <span className={classNames(className, 'relative')}>
      <video
        ref={videoRef}
        src={videoUrl}
        className={className}
        poster={thumbnailUrl || imagesUrl.blanckCompImg}
      />
      <FaPlayCircle
        className='absolute left-0 top-0 right-0 bottom-0 m-auto  cursor-pointer text-gray-300'
        size={'50px'}
      />
    </span>
  )
}

function Post({
  name,
  src,
  date,
  postImgs = [],
  content = '',
  id,
  like,
  connection,
  containerClass,
  userId,
  showConnectBtn = false,
  num_likes,
  num_comments,
  hideBtns = false,
}: Props) {
  const modalRef = React.useRef<RefType>(null)
  const modalRef1 = React.useRef<RefType>(null)

  const queryClient = useQueryClient()

  const [comment, setcomment] = React.useState<string>('')

  const toggleModal1 = (): void => {
    modalRef1?.current?.handleToggle()
  }

  //comment on post
  const { mutateAsync: postComment, isLoading: commentLoading } =
    usePostUpdatesComments()

  //toggle like
  const { mutateAsync: likePost, isLoading: likeLoading } =
    useToggleUpdatesLike()

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  const handlePostComment = async () => {
    const { response, error } = await withAsync(() =>
      postComment({ update: id as number, content: comment })
    )

    if (error) return errorHandler(error)
    if (response) {
      setcomment('')

      queryClient.invalidateQueries([GET_UPDATES])

      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal1()
    }
  }

  const handleToggleLike = async () => {
    const { response, error } = await withAsync(() =>
      likePost({ id: id as number })
    )

    if (error) return errorHandler(error)
    if (response) {
      queryClient.invalidateQueries([GET_UPDATES])
      ToastNotify('success', {
        message: 'Success',
      })
    }
  }

  return (
    <>
      <div
        className={classNames(
          'bg-[white] flex gap-2 flex-col rounded-md p-[24px] w-full border border-[#E6E6EB]',
          containerClass
        )}
      >
        <div className='flex items-center justify-between'>
          <PosterDetails src={src} name={name} userId={userId} />
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: content }}
          className='cursor-pointer'
          onClick={() => toggleModal()}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />

        <div
          className={classNames(
            'cursor-pointer',
            { [twoImgClass]: postImgs.length === 2 },
            { [threeImgClass]: postImgs.length >= 3 }
          )}
          onClick={() => toggleModal()}
        >
          {postImgs?.map((img, i, arr) =>
            i <= 2 ? (
              // img tag doesnt support pseudo element, so for the dark overlay, we use a div
              arr.length >= 3 && i === 2 ? (
                <div
                  style={{
                    background:
                      img.file_type === 'image'
                        ? `url(${img.file}) no-repeat center center/cover`
                        : '',
                  }}
                  className={classNames(
                    //class for 3rd img when img length is 3
                    'bg-gray-200 rounded-br-lg h-[150px] w-[150px] col-span-2 col-start-5  sm:w-[200px] lg:w-[400px]',

                    //class for 3rd img when img length is > 3
                    {
                      [`relative text-[white] flex justify-center items-center`]:
                        arr.length > 3,
                    }
                  )}
                  key={img.id}
                >
                  {arr.length > 3 ? (
                    <span className='absolute left-0 w-full h-full rounded-br-lg text-[white] bg-gray-900/60 z-30 flex items-center justify-center'>
                      <span className='flex items-center gap-1 z-30 text-[25px] '>
                        <FaPlus />
                        {arr.length - 3}
                      </span>
                    </span>
                  ) : null}

                  {img.file_type === 'video' && (
                    <VideoThumbnail
                      key={img.id}
                      videoUrl={img.file}
                      thumbnailUrl={img?.video_cover || ''}
                      className='object-center object-cover w-full h-full  rounded-br-lg'
                    />
                  )}
                </div>
              ) : img.file_type === 'video' ? (
                <VideoThumbnail
                  key={img.id}
                  videoUrl={img.file}
                  thumbnailUrl={img?.video_cover || ''}
                  className={classNames(
                    ' object-center object-cover',
                    //class for when img length is 1
                    { 'rounded h-[250px] w-[100%]': arr.length === 1 },

                    //class for 1st img when img length is 2
                    {
                      'rounded-l-lg h-[300px] w-full':
                        arr.length === 2 && i === 0,
                    },
                    //class for 2nd img when img length is 2
                    {
                      'rounded-r-lg h-[300px] w-full':
                        arr.length === 2 && i === 1,
                    },
                    //class for 1st img when img length is 3
                    {
                      'rounded-l-lg h-[303px] w-[300px] col-span-4 row-start-1 row-end-3 sm:w-[500px]':
                        arr.length >= 3 && i === 0,
                    },
                    //class for 2nd img when img length is 3
                    {
                      'rounded-tr-lg h-[150px] w-[150px] col-span-2 col-start-5 sm:w-[200px] lg:w-[400px]':
                        arr.length >= 3 && i === 1,
                    }
                  )}
                />
              ) : (
                <img
                  key={img.id}
                  alt='post-img'
                  src={img.file}
                  style={{
                    display: 'block',
                    backgroundImage: `url(${img.file})`,
                  }}
                  className={classNames(
                    ' object-center object-cover',
                    //class for when img length is 1
                    { 'rounded h-[344.95px] w-full': arr.length === 1 },

                    //class for 1st img when img length is 2
                    {
                      'rounded-l-lg h-[300px] w-full':
                        arr.length === 2 && i === 0,
                    },
                    //class for 2nd img when img length is 2
                    {
                      'rounded-r-lg h-[300px] w-full':
                        arr.length === 2 && i === 1,
                    },
                    //class for 1st img when img length is 3
                    {
                      'rounded-l-lg h-[303px] w-[300px] col-span-4 row-start-1 row-end-3 sm:w-[500px]':
                        arr.length >= 3 && i === 0,
                    },
                    //class for 2nd img when img length is 3
                    {
                      'rounded-tr-lg h-[150px] w-[150px] col-span-2 col-start-5 sm:w-[200px] lg:w-[400px]':
                        arr.length >= 3 && i === 1,
                    }
                  )}
                />
              )
            ) : null
          )}
        </div>
        <span className='opacity-50 flex gap-2 items-center'>
          <FaCircle fontSize={6} />
          <p> {moment(date).format('hh:mm A MMMM Do, YYYY')}</p>
          {/* <p>7:10 AM, Oct 20, 2022</p> */}
        </span>
        {!hideBtns ? (
          <div className='flex justify-between w-full items-center'>
            <div className='flex items-center gap-2'>
              <Button
                className='rounded-md px-2 sm:px-6 border'
                startIcon={
                  like ? (
                    <FaHeart className={like ? 'text-red-500' : ''} />
                  ) : (
                    <FaRegHeart />
                  )
                }
                size='sm'
                color='white'
                onClick={handleToggleLike}
                loading={likeLoading}
              >
                <span
                  className={
                    like
                      ? 'text-red-500 flex items-center justify-center gap-1'
                      : 'flex items-center justify-center gap-1'
                  }
                >
                  {numberFormatter(num_likes)}
                  <span className='hidden sm:block'>
                    {num_likes > 1 ? 'Likes' : 'Like'}
                  </span>
                </span>
              </Button>
              <Button
                className='rounded-md px-2 sm:px-6 border'
                size='sm'
                color='white'
                startIcon={<CommentIcon />}
                onClick={toggleModal1}
              >
                <span className='text-green-600 flex items-center justify-center gap-1'>
                  {' '}
                  {numberFormatter(num_comments)}
                  <span className='hidden sm:block'>
                    {num_comments > 1 ? 'Comments' : 'Comment'}
                  </span>
                </span>
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      <Modal1
        ref={modalRef}
        modalClass='w-[90vw] sm:w-[800px] min-h-[80vh] pt-2'
      >
        <SinglePost id={id} />
      </Modal1>
      <Modal1 ref={modalRef1} modalClass='' enableWarning showCloseBtn>
        <div className='flex flex-col gap-2 items-start w-full p-5'>
          <Typography heading='3xs'>Comment on this update</Typography>
          <TextInput
            onChange={(e) => setcomment(e.target.value)}
            value={comment}
            fieldType='textarea'
            rows='5'
            placeholder='Write comment...'
            className='w-full'
          />
          <Button
            onClick={handlePostComment}
            loading={commentLoading}
            className='self-end'
            size='sm'
          >
            Post comment
          </Button>
        </div>
      </Modal1>
    </>
  )
}

export default Post
