import useIntersectionObserver from '@/hooks/useIntersectionObserver'
import React, { RefObject, useMemo } from 'react'
import CircularProgress from './spinners/CircularProgress'
import classNames from 'classnames'
interface Props {
  children: JSX.Element
  isFetchingNextPage: boolean
  hasNextPage: boolean | undefined
  containerClass?: string
  fetchNextPage: () => void
}
function InfiniteScrollContainer({
  children,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  containerClass = '',
}: Props) {
  const bottomRef = React.useRef<HTMLDivElement>(null)

  const isInBottom = useIntersectionObserver(
    bottomRef as RefObject<HTMLDivElement>
  )

  useMemo(() => {
    if (isInBottom && hasNextPage) {
      fetchNextPage()
    }
  }, [isInBottom, fetchNextPage, hasNextPage])

  return (
    <div className={classNames('min-h-[100vh]', containerClass)}>
      {children}

      {isFetchingNextPage && (
        <div className='w-full flex justify-center'>
          <CircularProgress size='50px' color='black' />
        </div>
      )}
      <div ref={bottomRef} />
    </div>
  )
}

export default InfiniteScrollContainer
