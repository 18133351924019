import React from 'react'
import { ReactComponent as EmptyDisplay } from '@/assets/dataRoom/emptyDisplay.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { ReactComponent as FolderIcon } from '@/assets/icons/utils/blackFolder.svg'
import FileUploadBtn from '@/common/FileUploadBtn'

interface Prop {
  handleFileSelect: (file: File) => void
  toggleModal: () => void
}
function FileEmptyComponent({ handleFileSelect, toggleModal }: Prop) {
  return (
    <div className='flex flex-col gap-3 items-center justify-center w-full  min-h-[60vh] rounded-md'>
      <EmptyDisplay />
      <Typography heading='xl'>Data Room</Typography>
      <Typography
        button='md'
        className='w-[350px] sm:w-[400px] text-center !text-black-white-shades-4'
      >
        Have all your files and docs properly set up in one place for your
        connected Investors to see, and carry out due diligence without hassle.
      </Typography>
      <div className='flex items-center gap-2'>
        <Button color='primary' onClick={toggleModal}>
          Create Folder
        </Button>
        <FileUploadBtn
          btnElement={
            <Button
              color='lightblue'
              className='!text-black'
              startIcon={<FolderIcon />}
            >
              Upload First File
            </Button>
          }
          onFileSelelcted={handleFileSelect}
        />
      </div>
    </div>
  )
}

export default FileEmptyComponent
