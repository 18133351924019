import React from 'react'

function PostSkeletonLoader() {
  return (
    <div className='w-full  flex flex-col gap-5 items-start justify-start'>
      {/* first post with three imgs */}
      <div className='w-full p-[24px] bg-white rounded-[8px] border flex flex-col gap-2 items-start justify-start'>
        {/* profile pix with name */}
        <div className='flex items-center gap-2'>
          <div className='w-[48px] h-[48px] rounded-[6px] animate-gradientWave' />
          <div className='w-[200px] h-[20px] animate-gradientWave rounded-md' />
        </div>

        {/* Post Content */}
        <div className='w-full rounded-[6px] h-[9px] animate-gradientWave rounded-md' />
        <div className='w-full rounded-[6px] h-[9px] animate-gradientWave rounded-md' />
        <div className='w-full rounded-[6px] h-[9px] animate-gradientWave rounded-md' />

        {/* images */}
        <div className='container m-auto grid grid-cols-3 gap-1'>
          {/* first img */}
          <div className='rounded-l-lg h-[303px] w-[300px] col-span-4 row-start-1 row-end-3 animate-gradientWave sm:w-[100%] sm:h-[219px]' />
          {/* second img */}
          <div className='rounded-tr-lg h-[150px] w-[150px] col-span-2 col-start-5 animate-gradientWave sm:w-[200px] lg:w-[344px] sm:h-[101.5px]' />
          {/* third img */}
          <div className='rounded-br-lg h-[150px] w-[150px] col-span-2 col-start-5 animate-gradientWave  sm:w-[200px] lg:w-[344px] sm:h-[110.5px]' />
        </div>

        {/* date */}
        <div className='w-[195px] h-[9px] rounded-md animate-gradientWave' />

        {/* buttons */}
        <div className='flex align-center gap-1'>
          <div className='w-[109px] h-[36px] rounded-[6px] animate-gradientWave' />
          <div className='w-[109px] h-[36px] rounded-[6px] animate-gradientWave' />
          <div className='w-[109px] h-[36px] rounded-[6px] animate-gradientWave' />
        </div>
      </div>

      {/* second post without img */}
      <div className='w-full p-[24px] bg-white rounded-[8px] border flex flex-col gap-2 items-start justify-start'>
        {/* profile pix with name */}
        <div className='flex items-center gap-2'>
          <div className='w-[48px] h-[48px] rounded-[6px] animate-gradientWave' />
          <div className='w-[200px] h-[20px] animate-gradientWave rounded-md' />
        </div>

        {/* Post Content */}
        <div className='w-full rounded-[6px] h-[9px] animate-gradientWave rounded-md' />
        <div className='w-full rounded-[6px] h-[9px] animate-gradientWave rounded-md' />
        <div className='w-full rounded-[6px] h-[9px] animate-gradientWave rounded-md' />

        {/* date */}
        <div className='w-[195px] h-[9px] rounded-md animate-gradientWave' />

        {/* buttons */}
        <div className='flex align-center gap-1'>
          <div className='w-[109px] h-[36px] rounded-[6px] animate-gradientWave' />
          <div className='w-[109px] h-[36px] rounded-[6px] animate-gradientWave' />
          <div className='w-[109px] h-[36px] rounded-[6px] animate-gradientWave' />
        </div>
      </div>
    </div>
  )
}

export default PostSkeletonLoader
