import { ToastNotify } from '@/common/toastManager'
import { urlBase64ToUint8Array } from './strings'
import { registerDevice } from '@/apis/authApis'
import { withAsync } from './withAsync'

export const askForNotificationSupport = async (onAccept?: () => void) => {
  if (!hasNotificationSupport()) {
    ToastNotify('info', { message: 'Browser does not support notification.' })
  }
  const permission = await Notification.requestPermission()
  if (permission === 'granted') {
    onAccept?.()
  }
}

export const hasNotificationSupport = () => {
  if ('Notification' in window) {
    return true
  }
  return false
}

export const hasNotificationPermission = () => {
  if (!hasNotificationSupport()) return false
  return Notification.permission === 'granted'
}

export const displayNotification = (
  notification: NotificationOptions & { title: string }
) => {
  const { title, tag, ...rest } = notification
  return navigator.serviceWorker.ready.then((swReg) => {
    swReg.showNotification(title, {
      icon: '/logo.png',
      ...rest,
      tag,
      renotify: !!tag,
      vibrate: tag
        ? [
            100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30, 100,
            30, 100,
          ]
        : undefined,
    })
  })
}

export const configurePushSub = async () => {
  const swReg = await navigator.serviceWorker.ready
  const subscription = await swReg.pushManager.getSubscription()
  if (subscription == null) {
    const publicKey =
      'BIknwvbMebuif2XTTfztVnDuzo5Bx4f-5S9-B0iv6qVuyioCvWYkQa2WqB_e53RYI2qxvtv-vOo8uoMRZhclRx8'
    const subscription = await swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicKey),
    })

    const { error, response } = await withAsync(() =>
      registerDevice({
        registration_id: subscription.endpoint,
        p256dh: btoa(
          String.fromCharCode.apply(
            null,
            new Uint8Array(subscription.getKey('p256dh') as any) as any
          )
        ),
      })
    )
    if (response) {
      return displayNotification({
        title: 'Successfully subscribed.',
        body: 'You have successfully subscribed to the notification',
      })
    }
    if (error) {
    }
  } else {
    // Use already existing sub
    console.log('Subscription already exist')
    return displayNotification({
      title: 'Successfully subscribed.',
      body: 'You have successfully subscribed to the notification',
    })
  }
}

export const startVibrate = () => {
  navigator.vibrate([
    100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30, 100, 30, 100,
  ])
}

export const stopVibrate = () => {
  navigator.vibrate(0)
}
