import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'
import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { useNavigate } from 'react-router'

import Typography from '@/common/Typography'
import { ReactComponent as ConnectedIcon } from '@/assets/network/connected.svg'
import { useDeleteConnection } from '@/store/networkStore'
import { CONNECTIONS } from '@/constants/querryKeys'
import { useMessageRecipient } from '@/pages/message/hooks'
import { truncateText } from '@/helpers/ellipsizeTextBox'
import { InvestorGroupType } from '@/apis/networkApis'
import { checkCategory } from './utils/checkCategory'
import { Pages } from '@/routes/route.constant'
import clsx from 'clsx'

interface Props {
  id: number
  user: {
    id: number
    name: string
    about: string
    avatar: string
    user_type: string
    num_connections: number
    num_followers: number
    connected: string
  }
  investor_group: InvestorGroupType
  created: Date
}

function FollowingItem({ id, user, investor_group }: Props) {
  const navigate = useNavigate()
  const messageUser = useMessageRecipient()

  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [CONNECTIONS],
    })

  // console.log(user)
  const data = {
    user,
    investor_group,
  }
  const name = investor_group ? investor_group.name : user.name
  return (
    <div className='flex flex-col gap-3 w-full'>
      <div className='flex flex-col gap-2 items-start w-full sm:flex-row sm:justify-between sm:items-center'>
        <div className='flex gap-2'>
          <img
            src={
              checkCategory({
                groupKey: 'logo',
                userKey: 'avatar',
                data,
              }) || imagesUrl.blanckProfileImg
            }
            alt='start_up_logo'
            className={clsx(
              'h-[80px] w-[80px] min-h-[80px] min-w-[80px] object-center object-cover rounded-full',
              {
                '!rounded-md': !!investor_group,
              }
            )}
          />
          <div className='flex flex-col gap-1 px-2 sm:px-3'>
            <div
              className='flex flex-col items:start justify-center cursor-pointer'
              onClick={() =>
                navigate(
                  `/app/networks/${
                    investor_group ? Pages.Group : Pages.User
                  }/${name}`,
                  {
                    state: {
                      id: investor_group ? investor_group.id : user.id,
                    },
                  }
                )
              }
            >
              <Typography heading='3xs'>{name}</Typography>

              <small className={`font-light`}>
                {' '}
                {truncateText(
                  checkCategory({
                    groupKey: 'category',
                    userKey: 'about',
                    data,
                  }),
                  10
                )}
              </small>
            </div>
            <Typography paragraph='xs' className='!font-medium'>
              {checkCategory({
                groupKey: 'category',
                userKey: 'user_type',
                data,
              })}
            </Typography>
            {!investor_group && (
              <div className='flex items-center gap-2 justify-between w-full'>
                <span className='flex gap-2'>
                  <small>
                    {' '}
                    <strong>{user.num_connections}</strong>
                  </small>
                  <small>
                    Connection{user.num_connections > 1 ? 's' : null}
                  </small>
                </span>
              </div>
            )}
          </div>
        </div>

        <div className='flex gap-2 mt-0 sm:gap-4'>
          <Button
            color='lightgreen'
            className='!border !border-gray-200 rounded px-4'
            onClick={() => deleteConnection({ id })}
            startIcon={<ConnectedIcon />}
            size='sm'
            loading={deleteConnectionLoading}
          >
            Unconnect
          </Button>

          <Button
            className='rounded px-4 border '
            startIcon={<FaEnvelope />}
            size='md'
            color='white'
            onClick={() =>
              messageUser(
                investor_group ? investor_group.id : user.id,
                investor_group ? 'group' : 'user'
              )
            }
          >
            message
          </Button>
        </div>
      </div>
      <hr className='bg-gray-200' />
    </div>
  )
}

export default FollowingItem
