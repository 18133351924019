import iconComponents from '@/assets/icons/iconComponents'
import { MenuItem } from './dashboard.types'
import { Pages } from '@/routes/route.constant'
import Chats from './components/header/Chats'

export const MenuLists: MenuItem[] = [
  {
    name: 'The Network',
    icon: iconComponents.navigation.NetworkIcon,
    url: `/app/${Pages.Network}`,
  },
  {
    name: 'Events',
    icon: iconComponents.navigation.EventIcon,
    url: '/app/events',
  },

  {
    name: 'Data Room',
    icon: iconComponents.navigation.FileIcon,
    url: `/app/${Pages.DataRoom}`,
  },
  {
    name: 'Message',
    icon: Chats,
    url: `/app/${Pages.Message}`,
  },
  {
    name: 'Team',
    icon: iconComponents.navigation.TeamIcon,
    url: `/app/${Pages.TeamPage}`,
  },
  {
    name: 'Profile',
    icon: iconComponents.navigation.ProfileIcon,
    url: `/app/${Pages.StartupProfile}`,
  },
]
