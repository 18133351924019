import { GetFoldersResDTO } from '@/apis/dataRoomApis'

import LoadingState from '../theNetwork/components/LoadingState'
import { useGetPublicDocument } from '@/store/dataRoomStore'
import { useParams } from 'react-router'
import FileComponent from './components/File.component'
import Typography from '@/common/Typography'

function File() {
  const { fileName, id } = useParams()
  const fileId = Number(id)

  //get file
  const {
    isLoading: isFileLoading,
    data,
    isError: isFileError,
  } = useGetPublicDocument({ id: fileId, name: fileName })
  const file = data as GetFoldersResDTO

  return (
    <main className='flex flex-col gap-3'>
      <Typography heading='sm'>File</Typography>
      <LoadingState
        condition={
          isFileLoading
            ? 'isLoading'
            : false
            ? 'isEmpty'
            : isFileError
            ? 'isError'
            : ''
        }
      />
      {!isFileLoading && !isFileError && (
        <FileComponent file={file} fileName={fileName} id={fileId} />
      )}
    </main>
  )
}

export default File
