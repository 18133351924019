import React, { ReactNode } from 'react'
import { ReactComponent as EmptyDisplay } from '@/assets/network/Component 3.svg'
import CircularProgress from '@/common/spinners/CircularProgress'
import Typography from '@/common/Typography'

interface Props {
  isEmptyDisplay?: ReactNode
  customEmptyDisplay?: ReactNode
  condition: 'isEmpty' | 'isLoading' | 'isError' | ''
  loaderSize?: string
  skeletonLoader?: ReactNode
}

function LoadingState({
  isEmptyDisplay,
  condition,
  loaderSize = '50px',
  skeletonLoader,
  customEmptyDisplay,
}: Props) {
  let show = <></>

  switch (condition) {
    case 'isEmpty':
      show = (
        <>
          {customEmptyDisplay ? (
            customEmptyDisplay
          ) : (
            <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[50vh] rounded-md'>
              <EmptyDisplay />
              {isEmptyDisplay}
            </div>
          )}
        </>
      )
      break
    case 'isLoading':
      show = (
        <div className='flex  items-center justify-center w-full mt-5 min-h-[50vh]'>
          {skeletonLoader ? (
            skeletonLoader
          ) : (
            <CircularProgress size={loaderSize} color='black' />
          )}
        </div>
      )
      break
    case 'isError':
      show = (
        <div className='flex flex-col gap-2 items-center justify-center w-full bg-[white] min-h-[50vh] rounded-md'>
          <EmptyDisplay />
          <Typography label='xs' className='text-red-500'>
            Sorry, Something went wrong
          </Typography>
        </div>
      )
      break

    default:
      break
  }

  return <>{show}</>
}

export default LoadingState
