import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Button from '@/common/button/Button'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { IOpenable } from '@/common/modal/modal.type'
import { useForgotPassword } from '@/store/authStore'
import { FormikHelpers, useFormik } from 'formik'
import { forgotPasswordSchema } from '../validators/forgotpassword.validator'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'

const ForgotPasswordPopup = (props: IOpenable) => {
  const {
    mutateAsync: forgotPasswordAction,
    isLoading: isSendingPasswordLink,
  } = useForgotPassword()
  const INITIAL_VALUES = {
    email: '',
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
    validationSchema: forgotPasswordSchema,
  })
  async function submitHandler(
    values: { email: string },
    { setSubmitting, resetForm }: FormikHelpers<{ email: string }>
  ) {
    const { error, response } = await withAsync(() => {
      return forgotPasswordAction(values)
    })

    if (error) {
      errorHandler(error)
      return setSubmitting(false)
    }
    if (response) {
      setSubmitting(false)
      resetForm()
      ToastNotify('info', {
        message: 'Reset password link has been sent.',
      })
      props.onClose()
    }
  }
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ModalHeader
        onClose={props.onClose}
        title='Forgot your password ?'
        subText="Don't worry, we'll send yout reset instructions"
      />

      <form
        onSubmit={handleSubmit}
        className='p-6  pt-3 gap-6 flex flex-col w-full'
      >
        <FormGroup id='email' label='Email address'>
          <InputWithAdornment
            placeholder='Enter email address'
            autoComplete='username'
            onChange={handleChange}
            onBlur={handleBlur}
            name='email'
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <footer className='w-full flex items-center gap-4'>
          <Button
            loading={isSubmitting || isSendingPasswordLink}
            disabled={!isValid || isSubmitting || isSendingPasswordLink}
            type='submit'
            size='sm'
            color='primary'
            className='min-w-[150px]'
          >
            Reset password
          </Button>
          <Button
            size='sm'
            color='white'
            className='border border-sifuse-shades-200 min-w-[150px]'
            onClick={props.onClose}
          >
            Cancel
          </Button>
        </footer>
      </form>
    </Modal>
  )
}

export default ForgotPasswordPopup
