import { getCountries, getIndustries, getProductTypes } from '@/apis/dataApis'
import { COUNTRIES, INDUSTRIES, PRODUCT_TYPES } from '@/apis/endpoints'
import { useQuery } from 'react-query'

export const useGetCountries = () => {
  return useQuery(COUNTRIES, getCountries)
}

export const useGetIndustries = () => {
  return useQuery(INDUSTRIES, getIndustries)
}

export const useGetProductTypes = () => {
  return useQuery(PRODUCT_TYPES, getProductTypes)
}
