import classNames from 'classnames'
import React, {
  ChangeEvent,
  ReactNode,
  InputHTMLAttributes,
  FormEvent,
} from 'react'
import CircularProgress from './spinners/CircularProgress'
import LazySpinner from './spinners/LazySpinner'

interface Props
  extends InputHTMLAttributes<
    HTMLInputElement | HTMLFormElement | HTMLSpanElement
  > {
  startIcon?: ReactNode
  endIcon?: ReactNode
  type?: string
  className?: string
  fieldType?: 'input' | 'textarea'
  rows?: string
  isLoading?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSubmit?: (
    event:
      | FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void
}

function TextInput({
  onChange,
  startIcon,
  endIcon,
  type = 'text',
  fieldType = 'input',
  className = '',
  onSubmit,
  rows = '',
  isLoading = false,
  ...rest
}: Props) {
  const handleSubmit = (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault()
    onSubmit && onSubmit(e)
  }
  return (
    <form className='relative w-full' onSubmit={(e) => handleSubmit(e)}>
      {isLoading && (
        <span className='absolute left-3 text-gray-400 top-1/2 -translate-y-1/2'>
          <LazySpinner show={isLoading} delay={0}>
            <CircularProgress size='18px' color={'black'} />
          </LazySpinner>
        </span>
      )}
      {!isLoading && startIcon && (
        <span className='absolute left-3 text-gray-400 top-1/2 -translate-y-1/2'>
          {startIcon}
        </span>
      )}
      {fieldType === 'textarea' ? (
        <textarea
          className={classNames(
            `${
              className ? className : ''
            }  rounded border-gray-100 focus:outline-none focus:border-gray-100 placeholder-gray-400 `,
            { 'pl-9': !!startIcon }
          )}
          onChange={onChange}
          rows={rows}
          {...(rest as any)}
        />
      ) : (
        <input
          type={type}
          className={classNames(
            `${
              className ? className : ''
            }  rounded border-gray-100 focus:outline-none focus:border-gray-100 placeholder-gray-400 `,
            { 'pl-9': !!startIcon }
          )}
          onChange={onChange}
          {...rest}
        />
      )}
      {endIcon && (
        <span className='absolute right-3 text-gray-400 top-1/2 -translate-y-1/2 cursor-pointer'>
          {endIcon}
        </span>
      )}
    </form>
  )
}

export default TextInput
